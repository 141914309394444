import { InputUnstyled, InputUnstyledProps } from '@mui/base';
import { HelperText } from 'components/atoms/texts/HelperText';
import { Label } from 'components/atoms/texts/Label';
import { forwardRef, InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';
import {
  InputAdornment,
  StyledError,
  StyledInputRoot,
  StyledInputText,
  StyledInputTextArea,
  StyledWrapperInputText,
  WrapperIcon,
} from './Styles';

export type Props = InputUnstyledProps & {
  Icon?: ReactElement;
  control: Control<any, any>;
  helperText?: string;
  fullWidth?: boolean;
  name: string;
  isEdited?: boolean;
  defaultValue?: string;
  label?: ReactNode;
  variant?: 'primary' | 'search';
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
};

export const InputText = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      defaultValue,
      label,
      isEdited = true,
      error,
      required,
      fullWidth,
      control,
      helperText,
      Icon,
      inputProps,
      ...rest
    },
    ref,
  ) => (
    <StyledWrapperInputText fullWidth={fullWidth}>
      {label && <Label required={required} text={label} htmlFor={name} />}

      <WrapperIcon>{Icon}</WrapperIcon>
      <Controller
        control={control}
        rules={{}}
        name={name}
        defaultValue={defaultValue}
        render={({ field, fieldState, formState }) => {
          if (!isEdited) return field.value;

          return (
            <InputUnstyled
              error={error}
              slots={{ root: StyledInputRoot, input: StyledInputText, textarea: StyledInputTextArea }}
              slotProps={{ input: { ...inputProps, ...field } }}
              endAdornment={<InputAdornment>{rest.endAdornment}</InputAdornment>}
              {...rest}
            />
          );
        }}
      />
      {error && (
        <StyledError>
          <HelperText text={helperText} isError={error} isWarning={false} />
        </StyledError>
      )}
    </StyledWrapperInputText>
  ),
);

InputText.displayName = 'InputText';
