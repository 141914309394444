import { Icon } from '../icons';
import { StyledButton, StyledImage, StyledWrapper } from './Styles';

interface Props {
  url: string;
  onDelete?: () => void;
}

export const ImageOption = ({ url, onDelete }: Props) => {
  return (
    <StyledWrapper>
      {onDelete && <StyledButton onClick={() => onDelete && onDelete()} Icon={<Icon name="delete" />} />}
      <StyledImage src={url} alt={''} />
    </StyledWrapper>
  );
};
