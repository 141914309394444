import { AutoLayout } from 'components/atoms/AutoLayout';
import { DeckInfoElements } from 'components/molecules/DeckInfoElements';
import { Language } from 'shared-types-wordkito';

interface Props {
  deckId: string;
  name: string;
  deckQuizCardsCount: number;
  deckContentCardsCount: number;
  deckDecisionsCardsCount: number;
  languages: Language[];
}

export const Main = ({ deckContentCardsCount, deckQuizCardsCount, deckDecisionsCardsCount, languages }: Props) => {
  return (
    <AutoLayout gap={6}>
      <DeckInfoElements
        deckContentCardsCount={deckContentCardsCount}
        deckDecisionsCardsCount={deckDecisionsCardsCount}
        deckQuizCardsCount={deckQuizCardsCount}
      />
    </AutoLayout>
  );
};
