import { theme } from 'modules/global/theme/theme';
import styled from 'styled-components';

export const Wrapper = styled('button')(({}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  backgroundColor: 'transparent',
  border: 'none',
  fontWeight: theme.fonts.weights.semiBold600,
}));

export const StyledWrapperNames = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  textAlign: 'left',
  '> :first-child': {
    fontSize: '16px',
    color: theme.colors.accentColor.default,
    ':hover': {
      cursor: 'pointer',
      color: theme.colors.accentColor.hover,
    },
  },
}));
