import { PopperUnstyled } from '@mui/base';
import { theme } from 'modules/global/theme/theme';
import styled, { keyframes } from 'styled-components';

const appearMenuPage = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const StyledListbox = styled('ul')(({}) => ({
  boxSizing: 'border-box',
  padding: '6px',
  margin: '12px 0',
  minWidth: '200px',
  borderRadius: theme.borders.small,
  overflow: 'hidden',
  border: '1px solid #e0e0e0',
  outline: '0px',
  backgroundColor: 'white',
}));

export const Popper = styled(PopperUnstyled)`
  z-index: 1000;
`;

export const StyledWrapperMenuPageWithHeader = styled.div`
  animation: 0.3s ${appearMenuPage} ease-out;
  width: 100%;
`;
export const BackButton = styled.button`
  display: flex;
  gap: 15px;
  font-size: 14px;
  font-weight: ${theme.fonts.weights.bold700};
  align-items: center;
  padding: 8px 4px;
  width: 100%;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid grey;
`;
