import { AutoLayout } from 'components/atoms/AutoLayout';
import { Button } from 'components/atoms/buttons/Button';
import { Icon } from 'components/atoms/icons';
import SwitchToggle from 'components/atoms/inputs/SwitchToggle';
import { Select } from 'components/atoms/inputs/selects/Select';
import { InputText } from 'components/atoms/inputs/texts/InputText';
import { ModalBottomPanel } from 'components/molecules/Modal/components/ModalBottomPanel';
import { ModalFormHeader } from 'components/molecules/Modal/components/ModalFormHeader';
import { ModalMain } from 'components/molecules/Modal/components/ModalMain';
import { ModalMainTemplate } from 'components/molecules/Modal/components/ModalMainTemplate';
import { useCreatePath, useUpdatePath } from 'modules/deck/interactions/mutations/path.mutations';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DiffcultyLevelType, IPath } from 'shared-types-wordkito';

interface Props {
  path?: IPath;
  initialDifficultyLevel: DiffcultyLevelType;
  initialName?: string;
  handleClose: () => void;
}

export const FormPath = ({ path, initialDifficultyLevel, initialName, handleClose }: Props) => {
  const updatePath = useUpdatePath();
  const createPath = useCreatePath();
  const navigate = useNavigate();
  const defaultValues = {
    name: initialName || path?.name || '',
    contextToGenerate: '',
    isPublic: path?.isPublic ?? false,
    difficultyLevel: initialDifficultyLevel || ('beginner' as DiffcultyLevelType),
  };

  const onSubmit = async (data: typeof defaultValues) => {
    if (!path?.id) {
      await createPath.mutateAsync(
        {
          level: data.difficultyLevel,
          name: data.name,
          isPublic: data.isPublic,
          contextToGenerate: '',
        },
        {
          onSuccess: (data) => {
            navigate(`/paths/${data.id}`);
            handleClose();
          },
        },
      );

      return;
    }
    await updatePath.mutateAsync(
      { pathId: path?.id, body: data },
      {
        onSuccess: () => {
          handleClose();
        },
      },
    );
  };

  const form = useForm<typeof defaultValues>({ defaultValues });

  return (
    <ModalMainTemplate>
      <ModalFormHeader
        iconName="path"
        textHeader={path ? 'Zaktualizuj scieżkę' : 'Utwórz nową scieżkę'}
        textSubHeader={path?.name}
      />
      <AutoLayout as="form" column gap={12} onSubmit={form.handleSubmit(onSubmit)}>
        <ModalMain>
          <AutoLayout column gap={12}>
            <InputText
              defaultValue={form.getValues('name')}
              label="Nazwa ścieżki"
              placeholder="Jakie opowieści ma zwierać ścieżka ?"
              name="name"
              error={!!form.formState.errors?.name}
              helperText={
                form.formState.errors?.name?.message || 'Im dokładniejsza nazwa tym lepsze generowanie treści.'
              }
              control={form.control}
            />
            <InputText
              defaultValue={form.getValues('contextToGenerate')}
              control={form.control}
              multiline
              minRows={3}
              label="Context do wygenerowania treści"
              placeholder="Wrzuć co masz, a my zrobimy resztę"
              name="contextToGenerate"
              error={!!form.formState.errors?.contextToGenerate}
              helperText={
                form.formState.errors?.contextToGenerate?.message ||
                'Im dokładniejsza nazwa tym lepsze generowanie treści.'
              }
            />
            <Select
              variant="compact"
              defaultValue={'begginer'}
              control={form.control}
              name="difficultyLevel"
              label="Poziom trudności"
              options={[
                { text: 'Trudny', Icon: <Icon name="levelAdvanced" />, value: 'advanced' },
                { text: 'Średni', Icon: <Icon name="levelMedium" />, value: 'medium' },
                { text: 'Łatwy', Icon: <Icon name="levelBegginer" />, value: 'begginer' },
              ]}
            />
            <SwitchToggle control={form.control} name="isPublic" label="Pokaż publicznie" />
          </AutoLayout>
        </ModalMain>
        <ModalBottomPanel>
          <Button
            type="submit"
            text={path ? 'Zaktualizuj' : 'Utwórz'}
            isLoading={updatePath.isLoading || createPath.isLoading}
            disabled={updatePath.isLoading || createPath.isLoading || !form.watch('name').length}
          />
        </ModalBottomPanel>
      </AutoLayout>
    </ModalMainTemplate>
  );
};
