import { ModalUnstyled } from '@mui/base';
import styled from 'styled-components';

export const StyledModal = styled(ModalUnstyled)(({}) => ({
  position: 'fixed',
  zIndex: '1301',
  right: '0',
  bottom: '0',
  top: '0',
  left: '0',
  display: 'flex',

  alignItems: 'center',
  justifyContent: 'center',
}));
