import { Avatar } from 'components/atoms/Avatar';
import React from 'react';
import { StyledWrapperNames, Wrapper } from './Styles';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  displayName: string;
  currentSpaceName?: string;
  avatarProperties: {
    color: string;
  };
}

export const MenuAvatarButton: React.FC<Props> = ({
  displayName,
  currentSpaceName,
  avatarProperties,
  ...rest
}: Props) => (
  <div>
    <Wrapper type="button" {...rest}>
      <Avatar size="30" avatarProperties={avatarProperties} />
      <StyledWrapperNames>
        <p>{displayName}</p>
        <p>{currentSpaceName}</p>
      </StyledWrapperNames>
    </Wrapper>
  </div>
);
