import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IResourceScopesIDs } from 'modules/authorization/models/scope';
import { IMember } from 'shared-types-wordkito';
import { createMemberResource } from '../methods/memberResource';

export const useCreateMemberResource = (args: IResourceScopesIDs) => {
  const queryClient = useQueryClient();

  return useMutation(createMemberResource, {
    onSuccess: (data) => {
      queryClient.setQueryData<IMember[]>(['members', args], (prev) => [...(prev || []), data]);
    },
  });
};
