import { signInWithEmailLink } from '@firebase/auth';
import { Button } from 'components/atoms/buttons/Button';
import { InputText } from 'components/atoms/inputs/texts/InputText';
import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { firebaseAuth } from 'modules/authentication/config';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { StyledWrapper, WrapperInputs } from './Styles';

interface defaultValues {
  email: string;
}

const ConfirmEmailPage = () => {
  const emailForSignIn = localStorage.getItem('emailForSignIn');
  const defaultValues = { email: emailForSignIn || '' };
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<defaultValues>({ defaultValues });

  const handleConfirm = () => {
    setIsLoading(true);
    signInWithEmailLink(firebaseAuth, form.getValues('email'), window.location.href)
      .then((res) => {
        localStorage.removeItem('emailForSignIn'), setIsLoading(false);
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <StyledWrapper>
      <Typography variant={TypographyTypes.titleLargeSemiBold}>
        Wpisz swój email aby dokończyć logowanie
      </Typography>

      <WrapperInputs>
        <InputText
          defaultValue={emailForSignIn || ''}
          label="Email"
          placeholder="name@domain.com"
          error={!!form.formState.errors.email}
          helperText={form.formState.errors.email?.message || ' '}
          name="email"
          type={'email'}
          required
          control={form.control}
        />
        <Button
          fullWidth
          disabled={isLoading}
          text={'Dokończ logowanie'}
          onClick={() => handleConfirm()}
          isLoading={isLoading}
          type="button"
          css="margin-top:32px;"
        />
      </WrapperInputs>
    </StyledWrapper>
  );
};

export default ConfirmEmailPage;
