import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DeckQueryKeys } from 'modules/deck/constants/queryKeys';

import {
  CreateDeckCardDto,
  CreateDeckCardResponseDto,
  IDeck,
  IDeckCard,
  UpdateDeckCardPositionDto,
} from 'shared-types-wordkito';
import { createDeckCard, MenuPagesTypes } from '../methods/deck';

import { deleteDeckCard, getDeckCards, updateDeckCard, updateDeckCardPosition } from '../methods/deckCard';

export const useCreateDeckCardHandler = (deckId?: string, spaceId?: string) => {
  const queryClient = useQueryClient();

  const handleCreateDeckCardHandler = (res: CreateDeckCardResponseDto, dto: CreateDeckCardDto) => {
    queryClient.setQueriesData<IDeck[]>(DeckQueryKeys.currentUserDecks(), (prev) => {
      return prev?.length
        ? prev?.map((deck) => {
            if (deck.id === res.createdDeckCards[0].deckId) {
              const deckCardsCount = deck?.deckCardsCount || 0;
              return {
                ...deck,
                deckCardsCount: deckCardsCount + 1,
              };
            }

            return deck;
          })
        : [];
    });
    queryClient.setQueryData<IDeck>(DeckQueryKeys.deck(deckId), (deck) => {
      if (deck?.deckCardsCount) {
        const deckCardsCount = deck?.deckCardsCount || 0;
        return {
          ...deck,
          deckCardsCount: deckCardsCount + 1,
        };
      }

      return deck;
    });

    queryClient.setQueryData<IDeckCard[]>(DeckQueryKeys.deckCards(deckId), (prev) => {
      // jezeli jest decyzja to edytuj decyzje

      const editedDeckCards = prev?.map((item) => {
        const updatedDeckCard = res.updatedDeckCards.find((updatedDeckCard) => updatedDeckCard.id === item.id);
        if (updatedDeckCard) {
          return {
            ...item,
            ...updatedDeckCard,
          };
        }
        return item;
      });
      return [...(editedDeckCards || []), ...res.createdDeckCards];
    });
  };

  return handleCreateDeckCardHandler;
};

export const useCreateDeckCard = (deckId?: string, spaceId?: string) => {
  const handleCreateDeckCardHandler = useCreateDeckCardHandler(deckId, spaceId);

  return useMutation((data: CreateDeckCardDto) => createDeckCard({ deckId, body: data }), {
    onSuccess: (res, dto) => {
      handleCreateDeckCardHandler(res, dto);
    },
  });
};

export const useGetDeckCardsQuery = (deckId: string) => {
  return useQuery(DeckQueryKeys.deckCards(deckId), () => getDeckCards(deckId), {
    enabled: !!deckId,
  });
};

export const useUpdateDeckCard = (deckId?: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    DeckQueryKeys.deckCards(deckId),
    ({ baseCardId, data }: { baseCardId: string; data: CreateDeckCardDto }) => updateDeckCard(baseCardId, data),
    {
      onSuccess: (res) => {
        queryClient.setQueryData<IDeckCard[]>(DeckQueryKeys.deckCards(deckId), (prev) => {
          const updatedDeckCardsIDs = res.updatedDeckCards.map((item) => item.id);
          return [
            ...(prev?.map((item) => {
              if (updatedDeckCardsIDs.includes(item.id)) {
                const updatedDeckCard = res.updatedDeckCards.find(
                  (updatedDeckCard) => item.id === updatedDeckCard.id,
                );
                return { ...item, ...updatedDeckCard };
              }

              return item;
            }) || []),
            ...res.createdDeckCards,
          ];
        });
      },
    },
  );
};

export const useUpdateDeckCardPosition = (deckId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    DeckQueryKeys.deckCards(deckId),
    ({ baseCardId, data }: { baseCardId: string; data: UpdateDeckCardPositionDto }) =>
      updateDeckCardPosition(baseCardId, data),
    {
      onSuccess: (res) => {
        // queryClient.setQueryData<IDeckCard[]>(BaseQueryKeys.deckCards(deckId), (prev) => {
        //   const newMap = prev?.map((item) => {
        //     const foundDeckCard = res.find((deckCard) => deckCard?.id === item.id);
        //     if (!!foundDeckCard) {
        //       const newCard = { ...item, sourceDeckCardId: foundDeckCard.sourceDeckCardId };
        //       return newCard;
        //     }
        //     return item;
        //   });
        //   return newMap;
        // });
      },
    },
  );
};

export const useDeleteDeckCard = (deckId: string) => {
  const queryClient = useQueryClient();

  return useMutation(deleteDeckCard, {
    onSuccess: (res) => {
      queryClient.setQueryData<IDeckCard[]>(DeckQueryKeys.deckCards(deckId), (prev) => {
        const deletedDeckCardsIDs = res.deletedDeckCards.map((item) => item.id);
        const newDeckCards: IDeckCard[] = [];

        prev?.forEach((deckCard) => {
          if (deletedDeckCardsIDs.includes(deckCard.id)) return;

          const existedDeckCard = res.updatedDeckCards.find(
            (updatedDeckCard) => deckCard.id === updatedDeckCard.id,
          );

          if (existedDeckCard) {
            newDeckCards.push({
              character: existedDeckCard.character,
              deckId: existedDeckCard.deckId,
              id: existedDeckCard.id,
              isCorrect: existedDeckCard.isCorrect,
              mainContentId: existedDeckCard.mainContentId,
              type: existedDeckCard.type,
              targetDeckCardId: existedDeckCard.targetDeckCardId,
              deck: deckCard.deck,
              mainContent: deckCard.mainContent,
              sourceDeckCard: deckCard.sourceDeckCard,
            });
            return;
          }

          newDeckCards.push(deckCard);
        });

        return newDeckCards;
      });

      const deleteHandler = (prev: IDeck[] | undefined) => {
        return prev?.length
          ? prev?.map((deck) => {
              if (deck.id === deckId) {
                const currentDeckCardsCount = deck?.deckCardsCount || 0;

                return {
                  ...deck,
                  deckCardsCount: currentDeckCardsCount - res.deletedDeckCards.length,
                };
              }

              return deck;
            })
          : [];
      };
      queryClient.setQueryData<IDeck[]>(DeckQueryKeys.currentUserDecks(MenuPagesTypes.MY_DECKS), deleteHandler);
      queryClient.setQueryData<IDeck[]>(
        DeckQueryKeys.currentUserDecks(MenuPagesTypes.SHARED_DECKS),
        deleteHandler,
      );
      queryClient.setQueryData<IDeck[]>(
        DeckQueryKeys.currentUserDecks(MenuPagesTypes.MARKET_DECKS),
        deleteHandler,
      );
    },
  });
};
