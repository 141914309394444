import styled, { CSSProperties } from 'styled-components';
import { baseTypographyProps, TypographyProps } from '../Typography';

export interface IBaseAutoLayoutProps {
  column?: boolean;
  wrap?: boolean;
  borderRadius?: string;
  boxShadow?: string[];
  gap?: number;
  border?: string;
  justifyHorizontal?: 'space-between';
  alignVerticalInside?: 'center' | 'bottom';
  maxWidth?: string;
  fullWidth?: boolean;
  padding?: string;
  background?: string;
  align?: 'horizontal-center' | 'vertical-center';
  typographyProps?: TypographyProps;
  css?: CSSProperties;
  position?: 'absolute' | 'relative';
}

export type IAutoLayoutProps = IBaseAutoLayoutProps & {
  hover?: IBaseAutoLayoutProps;
};

export const baseLayout = (props: IBaseAutoLayoutProps, css?: CSSProperties): CSSProperties => ({
  display: 'flex',
  ...(props.column && {
    flexDirection: props.column ? 'column' : 'row',
  }),
  ...(props.background && {
    background: props.background,
  }),
  ...(props.position && {
    position: props.position,
  }),

  ...(props.justifyHorizontal === 'space-between' && {
    justifyContent: 'space-between',
  }),

  ...(props.alignVerticalInside === 'bottom' && {
    alignItems: 'flex-end',
  }),
  ...(props.alignVerticalInside === 'center' && {
    alignItems: 'center',
  }),

  ...(props.fullWidth && {
    width: '100%',
  }),
  ...(props.gap && {
    gap: props.gap,
  }),
  ...(props.padding && {
    padding: props.padding,
  }),
  ...(props.border && {
    border: props.border,
  }),
  ...(props.borderRadius && {
    borderRadius: props.borderRadius,
  }),

  ...(props.boxShadow && {
    boxShadow: props.boxShadow?.join(','),
  }),
  ...(props.maxWidth && {
    maxWidth: props.maxWidth,
  }),
  ...(props.wrap && {
    flexWrap: 'wrap',
  }),

  ...(props.align === 'horizontal-center' && {
    margin: '0 auto',
  }),

  ...css,
});

export const AutoLayout = styled('div')<IAutoLayoutProps>((props) => ({
  ...baseLayout(props, props.css),
  ...(props?.typographyProps ? baseTypographyProps(props.typographyProps) : {}),
  ['&:hover']: props.hover && { ...baseLayout(props.hover, props.hover.css) },
  // [':hover']: {},
}));
