import { switchUnstyledClasses } from '@mui/base';
import styled from 'styled-components';

export const StyledWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '14px',
  color: '#676767',
}));

export const Root = styled('span')<{ uncheckColor?: string; checkColor?: string }>(() => ({
  position: 'relative',
  display: 'inline-block',
  width: '33px',
  height: '18px',
  cursor: 'pointer',

  [`&.${switchUnstyledClasses.disabled}`]: {
    opacity: '0.4',
    cursor: 'not-allowed',
  },

  [`&.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb}`]: {
    boxShadow: '0 0 1px 8px rgba(0, 0, 0, 0.25)',
  },
}));

export const Thumb = styled('span')<{
  uncheckColor: string;
  checkColor: string;
  checked: boolean;
  checkIcon?: (color: string) => string;
  unCheckIcon?: (color: string) => string;
}>(({ checkColor, uncheckColor, checked, unCheckIcon, checkIcon }) => ({
  display: 'block',
  width: '14px',
  height: '14px',
  top: '2px',
  left: '2px',
  borderRadius: '2px',
  position: 'relative',
  transitionProperty: 'all',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '120ms',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '90%',
  backgroundColor: '#fff',
  ...(unCheckIcon && {
    backgroundImage: `url('data:image/svg+xml;utf8,${unCheckIcon(uncheckColor)}')`,
  }),
  ...(checked && {
    left: '16px',
    backgroundColor: '#fff',
    ...(checkIcon && {
      backgroundImage: `url('data:image/svg+xml;utf8,${checkIcon(checkColor)}')`,
    }),
  }),
}));

export const Track = styled('span')<{ uncheckColor?: string; checkColor?: string; checked: boolean }>(
  ({ checkColor, uncheckColor, checked }) => ({
    background: uncheckColor,
    borderRadius: '4px',
    display: 'block',
    height: '100%',
    width: '100%',
    position: 'absolute',
    ...(checked && {
      background: checkColor,
    }),
  }),
);

export const Input = styled('input')(({}) => ({
  cursor: 'inherit',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: '0px',
  left: '0px',
  opacity: '0',
  zIndex: '1',
  margin: '0',
}));
