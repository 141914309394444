import { CircularLoader } from 'components/atoms/CircularLoader';
import { Tooltip } from 'components/atoms/Tooltip';
import { ButtonHTMLAttributes, ReactElement, ReactNode, cloneElement } from 'react';
import { CSSProp } from 'styled-components';
import { StyledButton } from './Styles';

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: ReactNode;
  variant?: 'filled' | 'outline' | 'transparent';
  css?: CSSProp;
  color?: 'accent' | 'normal';
  accentColor?: string;
  textColor?: string;
  isLoading?: boolean;
  rightIcon?: ReactElement;
  leftIcon?: ReactElement;
  fullWidth?: boolean;
  tooltipProps?: {
    content: ReactNode;
  };
}

export const Button = ({
  isLoading,
  text,
  variant = 'filled',
  type = 'button',
  accentColor,
  textColor,
  rightIcon,
  leftIcon,
  fullWidth,
  tooltipProps,
  ...rest
}: Props) => {
  const clonedLeftIcon = leftIcon && cloneElement(leftIcon, { size: '15' });
  return (
    <Tooltip title={tooltipProps?.content}>
      <div>
        <StyledButton
          variant={variant}
          accentColor={accentColor}
          textColor={textColor}
          fullWidth={fullWidth}
          type={type}
          {...rest}
        >
          <>
            {clonedLeftIcon}
            {text}
            {rightIcon}
            {isLoading && <CircularLoader size={16} />}
          </>
        </StyledButton>
      </div>
    </Tooltip>
  );
};
