import { ReactNode } from 'react';
import { StyledWrapper } from './Styled';

interface Props {
  children: ReactNode;
  isAbsolute?: boolean;
}

export const ModalBottomPanel = ({ children, isAbsolute }: Props) => (
  <StyledWrapper isAbsolute={isAbsolute}>{children}</StyledWrapper>
);
