import { CustomSvgIconProps, Icon } from '.';

export const DuplicateIcon = (props: CustomSvgIconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.19629 7.29095C5.19629 6.09992 6.16181 5.1344 7.35284 5.1344H14.465C15.656 5.1344 16.6215 6.09992 16.6215 7.29095V9.07181H18.3366C19.5276 9.07181 20.4931 10.0373 20.4931 11.2284V18.3405C20.4931 19.5315 19.5276 20.497 18.3366 20.497H11.2244C10.0334 20.497 9.06787 19.5315 9.06787 18.3405V16.5596H7.35284C6.16181 16.5596 5.19629 15.5941 5.19629 14.4031V7.29095ZM15.4452 9.07181H11.2244C10.0334 9.07181 9.06787 10.0373 9.06787 11.2284V15.3833H7.35284C6.81147 15.3833 6.37259 14.9445 6.37259 14.4031V7.29095C6.37259 6.74958 6.81147 6.3107 7.35284 6.3107H14.465C15.0064 6.3107 15.4452 6.74958 15.4452 7.29095V9.07181Z"
    />
  </Icon>
);
