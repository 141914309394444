import styled from 'styled-components';

export const StyledWrapper = styled('div')<{ withPadding?: boolean }>(({ withPadding }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  textAlign: 'left',
  gap: '8px',
  padding: withPadding ? '24px' : '0',
}));
