import { theme } from 'modules/global/theme/theme';
import styled from 'styled-components';

export const StyledImageList = styled('div')(({}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  width: '100%',
}));

export const StyledWrapperImageItem = styled('div')(({}) => ({
  width: '150px',
  height: '190px',
  img: {
    width: '150px',
    height: '150px',
  },
  [theme.media.up('tablet')]: {
    width: '200px',
    height: '240px',
    img: {
      width: '200px',
      height: '200px',
    },
  },
}));

export const StyledImageListItemBar = styled('div')(({}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',

  width: '100%',
}));

export const StyledWrapperButtons = styled('div')(({}) => ({
  display: 'flex',
  gap: '4px',
  ['* > svg']: {
    color: 'white',
  },
}));
