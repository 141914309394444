import styled from 'styled-components';

export const StyledWrapper = styled('div')(({}) => ({
  position: 'absolute',
  right: '16px',
  top: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '14px 14px 0 0',
}));
