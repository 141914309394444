import { ButtonIcon } from 'components/atoms/buttons/ButtonIcon';
import { DuplicateIcon } from 'components/atoms/icons/DuplicateIcon';
import { InputCode } from 'components/atoms/inputs/texts/InputCode';
import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { useForm } from 'react-hook-form';
import { StyledWrapper } from './Styled';

interface Props {
  deckId: string;
}

export const CodeForm = ({ deckId }: Props) => {
  const iframeCode = `<iframe 
    src="${process.env.REACT_APP_URL}/decks/${deckId}/widgets/test"
>       
</iframe>`;

  const methods = useForm<{ iframeCode: string }>({
    defaultValues: {
      iframeCode: iframeCode,
    },
  });

  const handleCopy = () => {
    navigator.clipboard.writeText(iframeCode);
  };

  return (
    <StyledWrapper>
      <InputCode
        label="Skopiuj kod do osadzenia na swoją strone"
        name="iframeCode"
        defaultValue={iframeCode}
        language="html"
        control={methods.control}
        endAdornment={<ButtonIcon Icon={<DuplicateIcon />} onClick={() => handleCopy()} />}
      />
      <Typography as="p" variant={TypographyTypes.bodyLargeMedium}>
        Zobacz przykład na{' '}
        <a target={'_blank'} href="https://codesandbox.io/s/wordkito-test-widget-example-mgeeg8" rel="noreferrer">
          code sandbox
        </a>
      </Typography>

      <Typography as="p" variant={TypographyTypes.bodyLargeMedium}>
        Zobacz biblioteke do Reacta:{' '}
        <a target={'_blank'} href="https://www.npmjs.com/package/wordkito-widgets" rel="noreferrer">
          wordkito-widgets
        </a>
      </Typography>
    </StyledWrapper>
  );
};
