import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from 'modules/authentication/states/useAuth';
import { spaceQueryKeys } from 'modules/space/constants/queryKeys';
import { ISpace } from 'shared-types-wordkito';
import { createSpace } from '../methods';

export const useCreateSpace = () => {
  const queryClient = useQueryClient();
  const { updateCurrentSpace } = useAuth();

  return useMutation(createSpace, {
    onSuccess: async (res) => {
      await queryClient.setQueriesData<ISpace[]>(spaceQueryKeys.SPACES, (prev) => {
        return [...(prev || []), res];
      });
      updateCurrentSpace(res);
    },
  });
};
