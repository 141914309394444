import { Modal } from 'components/molecules/Modal';
import { ModalFormHeader } from 'components/molecules/Modal/components/ModalFormHeader';
import { ModalMainTemplate } from 'components/molecules/Modal/components/ModalMainTemplate';
import { FormShare } from 'components/organisms/forms/FormShare';
import { useCreateMemberResource } from 'modules/authorization/interactions/mutations/memberResource.mutation';
import { useGetMembersForResource } from 'modules/authorization/interactions/queries/memberResource.query';
import { IResourceScopes, IResourceScopesIDs } from 'modules/authorization/models/scope';
import { UpdateShareModalPayload } from 'modules/global/state/useInterface/types/payloads';
import { useForm } from 'react-hook-form';
import { PermissionTemplate } from 'shared-types-wordkito';

export interface Props {
  modalShareState: UpdateShareModalPayload;
  handleClose: () => void;
}

export const ModalMemberResource = ({ handleClose, modalShareState }: Props) => {
  const resource: IResourceScopesIDs = {
    userId: modalShareState?.user?.id,
    deckId: modalShareState?.deck?.id,
    spaceId: modalShareState?.space?.id,
    pathId: modalShareState?.path?.id,
  };
  const postRoleMemberByResource = useCreateMemberResource(resource);
  // TODO Pobieranie friendsów uzytkownika i podopowiadanie w autocomplete

  const getMembers = useGetMembersForResource(modalShareState.space?.id, modalShareState.deck?.id);

  const defaultValues = {
    role: undefined,
    memberId: '',
  };
  const form = useForm<{
    permissionTemplate: PermissionTemplate | undefined;
    memberId: string;
  }>({ defaultValues });

  const handleInvite = () => {
    postRoleMemberByResource.mutate({
      assignedMemberId: form.getValues('memberId'),
      permissionTemplate: form.getValues('permissionTemplate'),
      resourceDeckId: modalShareState?.deck?.id,
      resourcePathId: modalShareState?.space?.id,
    });
  };

  const textSubHeader = (resource: IResourceScopes) => {
    if (resource.deck) {
      return 'Opowieść: ' + resource.deck.name;
    } else if (resource.space) {
      return 'Przestrzeń: ' + resource.space.name;
    } else if (resource.path) {
      return 'Ścieżke: ' + resource.path.name;
    }
  };
  return (
    <Modal isOpen={modalShareState.isOpen} onClose={() => handleClose()}>
      <ModalMainTemplate onClose={() => handleClose()}>
        <ModalFormHeader
          textHeader={'Udostępnij'}
          textSubHeader={textSubHeader({
            user: modalShareState?.user,
            deck: modalShareState?.deck,
            space: modalShareState?.space,
            path: modalShareState?.path,
          })}
        />
        <FormShare
          members={getMembers.data || []}
          form={form}
          resource={{
            deck: modalShareState?.deck,
          }}
        />
      </ModalMainTemplate>
    </Modal>
  );
};
