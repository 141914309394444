import { axiosPost, fetchPut } from 'modules/global/helpers/fetchApiMethods';
import { API_URLS } from 'modules/global/urls';
import { LoginActionPayload } from 'modules/user/states/useUser/models/payloads';
import { Language } from 'shared-types-wordkito';
import { LanguageUpdateDTO } from '../models/dto';

export const postLogin = async (data: {}) => {
  const response = await axiosPost<{}, LoginActionPayload>(API_URLS.CURRENT_USER_LOGIN, data);

  return response.data;
};

export const updateUserLanguage = async (data: LanguageUpdateDTO): Promise<Language> => {
  const response = await fetchPut(API_URLS.CURRENT_USER_LANGUAGE, data);

  return response.json();
};

export const updateUserLanguagesToLearn = async (data: LanguageUpdateDTO[]): Promise<Language[]> => {
  const response = await fetchPut(API_URLS.CURRENT_USER_LANGUAGES_TO_LEARN, data);

  return response.json();
};

export const createCheckoutSession = async (data: any) => {
  const response = await axiosPost<any, string>(API_URLS.CREATE_CHECKOUT_SESSION, data);

  window.location.href = response.data;

  return response;
};

export const createPortalSession = async (data: any) => {
  const response = await axiosPost<any, any>(API_URLS.CREATE_PORTAL_SESSION, data);

  return response.data;
};
