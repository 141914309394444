import { ButtonIcon } from 'components/atoms/buttons/ButtonIcon';
import { Select } from 'components/atoms/inputs/selects/Select';
import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { useUpdateMember } from 'modules/authorization/interactions/mutations/member.mutations';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Icon } from 'components/atoms/icons';
import { IMember, PermissionTemplate } from 'shared-types-wordkito';
import { StyledWrapper } from './Styles';

interface Props {
  member: IMember;
  handleRemoveMember: (roleMemberId: string) => void;
  currentUserId: string;
}

export const MemberItem = ({ member, handleRemoveMember, currentUserId }: Props) => {
  const form = useForm<{ permissionTemplate: PermissionTemplate }>({
    defaultValues: {
      permissionTemplate: member.permissionTemplate,
    },
  });
  const updateMember = useUpdateMember();
  const permissionTemplateOptions = [
    { value: PermissionTemplate.MEMBER, text: 'Podróżnik' },
    { value: PermissionTemplate.EDITOR, text: 'Twórca' },
    { value: PermissionTemplate.ADMIN, text: 'Dowódca' },
  ];

  const userToDisplay = currentUserId === member.assignedUserId ? member.userOwner : member.assignedUser;
  return (
    <StyledWrapper>
      <Typography variant={TypographyTypes.bodySmallMedium}>
        <Link to={`/users` + `/${userToDisplay?.id}`}>{userToDisplay?.displayName}</Link>
      </Typography>

      <Select
        name={'permissionTemplate'}
        control={form.control}
        disabled={currentUserId !== member.userOwnerId}
        variant={'ghost'}
        defaultValue={member.permissionTemplate}
        options={permissionTemplateOptions}
        onChange={(_, value) => {
          updateMember.mutate({ memberId: member.id, body: { permissionTemplate: value } });
        }}
      />
      <ButtonIcon Icon={<Icon name="delete" />} onClick={() => handleRemoveMember(member.id)} />
    </StyledWrapper>
  );
};
