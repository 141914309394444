import { LanguageAppType } from '../models/languageApp';

function Reducer(state: LanguageAppType, action: { type: 'INIT'; payload: LanguageAppType }) {
  const newState = { ...state };

  switch (action.type) {
    case 'INIT': {
      return action.payload;
    }

    default:
      throw new Error();
  }
}

export default Reducer;
