import styled from 'styled-components';

export const StyledWrapper = styled('div')<{ fullWidth?: boolean }>(({ fullWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  ...(fullWidth && {
    width: '100%',
  }),

  ['> :first-child']: {
    marginBottom: '8px',
  },
}));

export const StyledError = styled('div')(({}) => ({
  marginTop: '4px',
}));
