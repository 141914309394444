import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  margin: 0 auto;
  margin-top: 120px;
  border-radius: 14px;
  background-color: white;
  max-width: 622px;
  padding: 58px 129px;
`;

export const StyledWrapperForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const WrapperInputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;
export const WrapperLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
`;
export const WrapperSeparator = styled.div`
  margin-top: 48px;
  margin-bottom: 32px;
  width: 100%;
`;
