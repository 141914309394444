import { AutoLayout } from 'components/atoms/AutoLayout';
import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { DeckPermissions } from 'modules/deck/models/permissions';
import { IDeck } from 'shared-types-wordkito';
import Buttons from './Buttons';

interface Props {
  deck: IDeck;
  permissions: DeckPermissions;
}

const PanelTop = ({ deck, permissions }: Props) => (
  <AutoLayout justifyHorizontal="space-between">
    <Typography variant={TypographyTypes.titleSmallSemiBold}>{deck.name}</Typography>
    <Buttons permissions={permissions} deck={deck} />
  </AutoLayout>
);

export default PanelTop;
