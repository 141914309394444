import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { theme } from 'modules/global/theme/theme';
import { StyledWrapper } from './Styles';

export type Props = { text: string | undefined; isError?: boolean; isWarning?: boolean };

export const HelperText = ({ text, isError, isWarning }: Props) => {
  if (!text) return <></>;

  return (
    <StyledWrapper isError={isError} isWarning={isWarning}>
      <Typography
        variant={TypographyTypes.bodySmallRegular}
        color={isError ? theme.colors.errorColor.default : undefined}
      >
        {text}
      </Typography>
    </StyledWrapper>
  );
};
