import { AutoLayout } from 'components/atoms/AutoLayout';
import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { Icon, IconName } from 'components/atoms/icons';
import { StyledWrapper } from './Styled';

interface Props {
  textHeader?: string;
  withPadding?: boolean;
  textSubHeader?: string;
  iconName?: IconName;
}

export const ModalFormHeader = ({
  textSubHeader: testSubHeader,
  iconName,
  textHeader,
  withPadding = true,
}: Props) => {
  return (
    <StyledWrapper withPadding={withPadding}>
      <AutoLayout gap={8} alignVerticalInside={'center'}>
        {iconName && <Icon name={iconName} />}
        <Typography variant={TypographyTypes.titleLargeSemiBold}>{textHeader}</Typography>
      </AutoLayout>
      <Typography variant={TypographyTypes.bodySmallMedium}>{testSubHeader}</Typography>
    </StyledWrapper>
  );
};
