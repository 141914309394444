import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DeckQueryKeys } from 'modules/deck/constants/queryKeys';

import { CreateDeckCardResponseDto, CreateGenerateDeckCardDto, IDeck, IDeckCard } from 'shared-types-wordkito';
import { generateContent, generateQuizOption } from '../methods/deckCardGenerate';

export const useCreateDeckCardHandler = (deckId?: string, spaceId?: string) => {
  const queryClient = useQueryClient();

  const handleCreateDeckCardHandler = (res: CreateDeckCardResponseDto) => {
    queryClient.setQueriesData<IDeck[]>(DeckQueryKeys.currentUserDecks(), (prev) => {
      return prev?.length
        ? prev?.map((deck) => {
            if (deck.id === res.createdDeckCards[0].deckId) {
              const deckCardsCount = deck?.deckCardsCount || 0;
              return {
                ...deck,
                deckCardsCount: deckCardsCount + 1,
              };
            }

            return deck;
          })
        : [];
    });
    queryClient.setQueryData<IDeck>(DeckQueryKeys.deck(deckId), (deck) => {
      if (deck?.deckCardsCount) {
        const deckCardsCount = deck?.deckCardsCount || 0;
        return {
          ...deck,
          deckCardsCount: deckCardsCount + 1,
        };
      }

      return deck;
    });

    queryClient.setQueryData<IDeckCard[]>(DeckQueryKeys.deckCards(deckId), (prev) => {
      // jezeli jest decyzja to edytuj decyzje

      const editedDeckCards =
        prev?.map((item) => {
          const updatedDeckCard = res.updatedDeckCards.find((updatedDeckCard) => updatedDeckCard.id === item.id);
          if (updatedDeckCard) {
            return {
              ...item,
              ...updatedDeckCard,
            };
          }
          return item;
        }) || [];

      return [...editedDeckCards, ...res.createdDeckCards];
    });
  };

  return handleCreateDeckCardHandler;
};

export const useGenerateDeckCard = (deckId?: string) => {
  const handleCreateDeckCardHandler = useCreateDeckCardHandler(deckId);

  return useMutation((data: CreateGenerateDeckCardDto) => generateContent(data), {
    onSuccess: (res, dto) => {
      handleCreateDeckCardHandler(res);
    },
  });
};

export const useGenerateQuizOption = (deckId?: string) => {
  const handleCreateDeckCardHandler = useCreateDeckCardHandler(deckId);

  return useMutation(
    (data: { deckCardId: string; correctOptions: string[]; incorrectOptions: string[]; question: string }) =>
      generateQuizOption(data),
    {
      onSuccess: (res, dto) => {
        handleCreateDeckCardHandler(res);
      },
    },
  );
};
