import { CustomSvgIconProps, Icon } from '../icons';

export interface Props extends CustomSvgIconProps {
  avatarProperties: {
    color: string;
  };
}

export const Avatar = ({ avatarProperties, size }: Props) => (
  <Icon size={size}>
    <>
      <circle cx="12.2543" cy="12.6694" r="10.3442" fill="#F3F3F3" />
      <path
        d="M9.72529 6.88672H18.1512V16.5423C18.1512 17.0485 17.9504 17.5341 17.593 17.892C17.2357 18.25 16.751 18.4511 16.2456 18.4511H6.35547V10.2623C6.35547 9.36706 6.7105 8.50846 7.34247 7.87541C7.97443 7.24236 8.83156 6.88672 9.72529 6.88672V6.88672Z"
        fill={avatarProperties.color}
      />
      <circle cx="10.1744" cy="11.3981" r="1.27209" fill="#F3F3F3" />
      <circle cx="14.1002" cy="11.3981" r="1.27209" fill="#F3F3F3" />
    </>
  </Icon>
);
