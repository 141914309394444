import styled from 'styled-components';

export const StyledWrapper = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  gap: '24px',
  width: '100%',
}));

export const StyledWrapperForms = styled('div')(() => ({
  height: '100%',
  width: '100%',
}));

export const StyledWrapperHeader = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginBottom: '24px',
}));
