import { URL_PARAM } from 'modules/global/urls';

export const API_URLS_SPACE = (BASE_URL: string) => ({
  CREATE_SPACE: `${BASE_URL}`,
  GET_SPACE: (spaceId: string) => `${BASE_URL}` + URL_PARAM(spaceId),

  //
  GET_SPACE_ROLE_MEMBERS: (spaceId: string) => BASE_URL + `${URL_PARAM(spaceId)}/roleMembers`,
  GET_SPACE_ROLES: (spaceId: string) => BASE_URL + `${URL_PARAM(spaceId)}/roles`,
  CREATE_SPACE_ROLE_MEMBERS: (spaceId: string) => BASE_URL + `${URL_PARAM(spaceId)}/roleMembers`,
});
