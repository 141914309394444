import { useAuth } from 'modules/authentication/states/useAuth';
import { useCallback } from 'react';
import {
  checkPermission as checkWordkitoPermission,
  IDeck,
  IPath,
  ISpace,
  PermissionName,
} from 'shared-types-wordkito';
interface Props {
  gameAuthorId?: string;
  deck?: IDeck;
  path?: IPath;
  space?: ISpace;
}

export const useCheckPermission = ({ deck, space, path, gameAuthorId }: Props) => {
  const { user, permissions } = useAuth();

  const checkPermission = useCallback(
    (permissionToCheck: PermissionName) => {
      if (!user?.id) return false;
      return checkWordkitoPermission({
        currentPermissions: permissions,
        permissionToCheck,
        deck,
        space,
        currentUser: user,
        path,
      });
    },
    [user, deck, path, space, permissions],
  );

  return { checkPermission };
};
