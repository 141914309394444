import { CustomSvgIconProps, Icon } from '.';

interface Props extends CustomSvgIconProps {
  direction?: 'down' | 'up' | 'left' | 'right';
}
export const positionDeg = (direction?: 'down' | 'up' | 'left' | 'right') => {
  switch (direction) {
    case 'left': {
      return '0';
    }

    case 'right': {
      return '180';
    }

    case 'up': {
      return '90';
    }

    case 'down': {
      return '-90';
    }

    default: {
      return '0';
    }
  }
};

export const ArrowIcon = ({ direction }: Props) => {
  return (
    <Icon direction={direction}>
      <path d="M16.2433 5.48123C15.8569 5.0948 15.2339 5.0948 14.8474 5.48123L8.21505 12.1136C7.90748 12.4212 7.90748 12.918 8.21505 13.2256L14.8474 19.858C15.2339 20.2444 15.8569 20.2444 16.2433 19.858C16.6298 19.4716 16.6298 18.8485 16.2433 18.4621L10.4548 12.6736L16.2512 6.87711C16.6298 6.49068 16.6298 5.86766 16.2433 5.48123Z" />
    </Icon>
  );
};
