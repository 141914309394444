import {} from 'modules/authentication/states/useAuth';
import { AuthProviders } from 'modules/authentication/states/useAuth/context';
import { axiosDelete, axiosGet, axiosPatch } from 'modules/global/helpers/fetchApiMethods';
import { API_URLS } from 'modules/global/urls';
import { ISpace } from 'shared-types-wordkito';

export const deleteUser = async ({}) => {
  const response = await axiosDelete(API_URLS.CURRENT_USER);

  return response.data;
};

export const checkEmailExistsAction = async (email: string) => {
  const response = await axiosGet<AuthProviders | undefined | false>(API_URLS.GET_CHECK_EMAIL_EXISTS(email));

  return response.data;
};

export const changeDisplayNameAction = async (body: { displayName: string }) => {
  const response = await axiosPatch<{ displayName: string }, string>(API_URLS.CURRENT_USER_DISPLAY_NAME, body);

  return response.data;
};

export const updateCurrentSpace = async (body: { spaceId?: string }) => {
  const response = await axiosPatch<{ spaceId?: string }, ISpace>(API_URLS.PATCH_CURRENT_SPACE, body);

  return response.data;
};
