import { NavLink } from 'react-router-dom';
import { LogoIcon } from '../icons/LogoIcon';
import { StyledLogo, StyledTypography } from './Styles';

export interface Props {
  withText?: boolean;
  color?: string;
}

export const Logo = ({ withText, color }: Props) => (
  <StyledLogo>
    <NavLink to="/">
      <LogoIcon color={color} size={33} />
      {withText && <StyledTypography>wordkito</StyledTypography>}
    </NavLink>
  </StyledLogo>
);

export default Logo;
