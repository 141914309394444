import { useMediaQuery } from '@mui/material';
import { AutoLayout } from 'components/atoms/AutoLayout';
import { Logo } from 'components/atoms/Logo/Logo';
import { Button } from 'components/atoms/buttons/Button';
import { Icon } from 'components/atoms/icons';
import { SpaceIcon } from 'components/atoms/icons/SpaceIcon';
import { Navigation } from 'components/organisms/Header/components/Navigation';
import { useAuth } from 'modules/authentication/states/useAuth';
import { APP_ROUTES } from 'modules/global/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledWrapper, StyledWrapperInner } from './Styles';
import { HeaderElement } from './components/HeaderElement';
import { NavItem } from './components/NavItem';

export interface Props {}

export const Header = ({}: Props) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const matches = useMediaQuery('(min-width:600px)');

  // Zrobić przyciski
  return (
    <StyledWrapper>
      <StyledWrapperInner>
        <HeaderElement primary>
          {user?.id ? (
            <AutoLayout gap={4} alignVerticalInside={'center'}>
              <Logo withText={false} />
              <NavItem to={'/'} text={<Icon name="marketplace" />} match={['']} tooltipText="Market" />
              <NavItem
                to={APP_ROUTES.STORIES}
                text={<Icon name="path" />}
                match={[APP_ROUTES.STORIES]}
                tooltipText="Moje ścieżki"
              />
              <NavItem
                to={APP_ROUTES.MEMBERS}
                text={<SpaceIcon />}
                match={[APP_ROUTES.MEMBERS]}
                tooltipText="Podróżnicy"
              />
            </AutoLayout>
          ) : (
            <Logo withText={matches} />
          )}
        </HeaderElement>
        <HeaderElement third>
          {user?.id && !user.isAnonymous ? (
            <Navigation />
          ) : (
            <Button
              onClick={() => navigate(APP_ROUTES.LOGIN + `?redirectUrl=${encodeURIComponent(location.pathname)}`)}
              text={'Zaloguj się'}
            />
          )}
        </HeaderElement>
      </StyledWrapperInner>
    </StyledWrapper>
  );
};

export default Header;
