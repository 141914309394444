import { ButtonUnstyled } from '@mui/base';
import { theme } from 'modules/global/theme/theme';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 4px;
    font-weight: ${theme.fonts.weights.regular400};
    font-size: ${theme.fonts.sizes[14]};
  }
`;

export const StyledButton = styled(ButtonUnstyled)<{ variant: 'primary' | 'secondary'; withBackground?: boolean }>(
  ({ variant, withBackground }) => css`
    min-width: 22px;
    min-height: 22px;
    padding: 0px;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    box-shadow: none;
    color: #676767;

    ${withBackground &&
    css`
      border-radius: 3px;
      background-color: #ebebeb;
    `}

    :hover {
      svg {
        transition: fill 0.2s;
        color: ${theme.buttons.default};
      }
    }
    :active {
      transform: translateY(3%);
      transition: transition 0.2s;
    }

    ${variant === 'secondary' &&
    css`
      color: white;
      border-radius: ${theme.borders.small};
      width: 45px;
      height: 45px;
      outline: none;
      border: none;
      background-color: ${theme.buttons.default};
      -webkit-tap-highlight-color: transparent;
      :hover {
        cursor: pointer;
        background: ${theme.buttons.hover};
        svg {
          transition: fill 0.2s;
          color: white;
        }
      }
      :active {
        background-color: ${theme.buttons.clicked};
      }
      :focus {
        background-color: ${theme.buttons.clicked};
      }
      :disabled {
        background-color: ${theme.buttons.disabled};
      }
    `}
  `,
);

export const StyledBadge = styled.div<{ backgroundColor?: string }>(({ backgroundColor }) => ({
  position: 'absolute',
  right: '-10px',
  bottom: '-10px',
  minWidth: '18px',
  height: '18px',
  padding: '0 4px',
  borderRadius: theme.borders.small,
  backgroundColor: backgroundColor || theme.colors.accentColor.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
