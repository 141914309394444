import { ButtonIcon } from 'components/atoms/buttons/ButtonIcon';
import { FacebookIcon } from 'components/atoms/icons/FacebookIcon';
import { InstagramIcon } from 'components/atoms/icons/InstagramIcon';
import { InnerLink } from 'components/atoms/texts/InnerLink';
import { TypographyTypes } from 'components/atoms/Typography';
import { APP_ROUTES } from 'modules/global/routes';
import {
  StyledHeaderSection,
  StyledWrapper,
  StyledWrapperInner,
  StyledWrapperSection,
  StyledWrapperSocials,
} from './Styles';

interface Props {}
export const Footer = ({}: Props) => {
  return (
    <StyledWrapper>
      <StyledWrapperInner>
        <StyledWrapperSection>
          <StyledHeaderSection as="h5" variant={TypographyTypes.headerH5}>
            Kim jesteśmy ?
          </StyledHeaderSection>
          <InnerLink to={APP_ROUTES.ABOUT_US} text="O nas" />
          <InnerLink to={APP_ROUTES.TERMS_OF_USE} text="Regulamin" />
          <InnerLink to={APP_ROUTES.PRIVACY_POLICY} text="Polityka prywatności" />
        </StyledWrapperSection>
        <StyledWrapperSection>
          <StyledHeaderSection as="h5" variant={TypographyTypes.headerH5}>
            Pomoc
          </StyledHeaderSection>
          <InnerLink to={APP_ROUTES.FAQ} text="FAQ" />
          <InnerLink to={APP_ROUTES.CONTACT} text="Kontakt" />
        </StyledWrapperSection>
        <StyledWrapperSection>
          <StyledHeaderSection as="h5" variant={TypographyTypes.headerH5}>
            Śledz nas na
          </StyledHeaderSection>
          <StyledWrapperSocials>
            <ButtonIcon
              Icon={<FacebookIcon />}
              onClick={() => {
                window.open('https://www.facebook.com/MrWordkito', '_blank');
              }}
            />
            <ButtonIcon
              Icon={<InstagramIcon />}
              onClick={() => {
                window.open('https://www.instagram.com/mr_wordkito/', '_blank');
              }}
            />
          </StyledWrapperSocials>
        </StyledWrapperSection>
      </StyledWrapperInner>
    </StyledWrapper>
  );
};
