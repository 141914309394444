import { AutoLayout } from 'components/atoms/AutoLayout';
import { Select } from 'components/atoms/inputs/selects/Select';
import { useCheckPermission } from 'modules/authorization/hooks/useCheckPermission';
import { useUpdateDeck } from 'modules/deck/interactions/queries/deck';

import { PointsCircle } from 'components/molecules/PointsCircle';
import { DeckPermissions } from 'modules/deck/models/permissions';
import { APP_ROUTES } from 'modules/global/routes';
import { theme } from 'modules/global/theme/theme';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { IDeck, IPath, PermissionName } from 'shared-types-wordkito';
import { Main } from './components/Main';
import PanelTop from './components/PanelTop';

interface Props {
  deck: IDeck;
  paths: IPath[];
}

export const useDeckPermissions = (deck: IDeck): DeckPermissions => {
  const { checkPermission } = useCheckPermission({ deck: deck });
  return {
    canEdit: checkPermission(PermissionName.DeckEdit),
    canRead: checkPermission(PermissionName.DeckRead),
    canCreateCard: checkPermission(PermissionName.DeckCardCreate),
    canDelete: checkPermission(PermissionName.DeckDelete),
    canInvite: checkPermission(PermissionName.DeckInvite),
    canInviteLink: checkPermission(PermissionName.DeckInvite),
    canSell: checkPermission(PermissionName.DeckInvite),
    canPlay: checkPermission(PermissionName.GamePlay),
  };
};

export const Deck = ({ deck, paths }: Props) => {
  const navigate = useNavigate();
  const useUpdateDeckMutation = useUpdateDeck('');
  const form = useForm<{ path: string }>({
    defaultValues: {
      // path: deck.pathId,
    },
  });
  const permissions = useDeckPermissions(deck);

  const handlePlay = (e: any) => {
    e.stopPropagation();
    navigate(APP_ROUTES.DECKS + `/${deck.id}`);
  };

  const handleGoToBase = (e: any) => {
    e.stopPropagation();
    navigate(APP_ROUTES.DECKS + `/${deck.id}`, {
      replace: true,
    });
  };

  return (
    <AutoLayout
      column
      fullWidth
      gap={12}
      borderRadius={'4px'}
      padding={'10px'}
      boxShadow={['0px -1px 1px 0px rgba(0, 0, 0, 0.25)', '0px 1px 2px 0px rgba(0, 0, 0, 0.25)']}
      hover={{
        css: {
          cursor: 'pointer',
        },
        boxShadow: [
          `0px -1px 1px 0px ${theme.colors.accentColor.hover}`,
          `0px 1px 2px 0px ${theme.colors.accentColor.hover}`,
        ],
      }}
    >
      <AutoLayout column gap={12} onClick={handleGoToBase}>
        <PanelTop permissions={permissions} deck={deck} />
        <Main
          deckId={deck.id}
          name={deck.name}
          deckQuizCardsCount={deck.deckQuizCardsCount || 0}
          deckContentCardsCount={deck.deckContentCardsCount || 0}
          deckDecisionsCardsCount={deck.deckDecissionCardsCount || 0}
          languages={deck.languages || []}
        />
      </AutoLayout>
      <AutoLayout alignVerticalInside="bottom" justifyHorizontal="space-between" fullWidth>
        <div></div>
        <AutoLayout gap={20} alignVerticalInside="center">
          {paths.length !== 0 && (
            <Select
              control={form.control}
              name="path"
              variant="ghost"
              menuPlacement={'top-end'}
              defaultValue={deck.pathId}
              onChange={(_, value) => {
                useUpdateDeckMutation.mutate({
                  baseId: deck.id,
                  data: {
                    name: deck.name,
                    languageFrom: deck.languageFrom,
                    path: value,
                  },
                });
              }}
              options={paths.map((path) => {
                return { text: path.name, value: path.id };
              })}
            />
          )}
          {deck.maxQuizPoints ? (
            <PointsCircle gainedPoints={deck.currentUserGainedPoints || 0} maxPoints={deck.maxQuizPoints} />
          ) : undefined}
        </AutoLayout>
      </AutoLayout>
    </AutoLayout>
  );
};
