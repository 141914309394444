import { URL_PARAM } from 'modules/global/urls';

export const API_URLS_TRANSACTIONS = (BASE_URL: string) => ({
  CRAETE_TRANSACTION: () => `${BASE_URL}`,
  DELETE_TRANSACTION: (transactionId: string) => `${BASE_URL}` + URL_PARAM(transactionId),
});

export const API_URLS_MARKET = (BASE_URL: string) => ({
  SEARCH_STORIES: (pageParam: string, textParam: string) =>
    `${BASE_URL}/stories?page=${pageParam}&text=${textParam}`,
  SEARCH_PATHS: (pageParam: string, textParam: string) => `${BASE_URL}/paths?page=${pageParam}&text=${textParam}`,
});
