import { theme } from 'modules/global/theme/theme';
import styled, { css } from 'styled-components';

export const StyledWrapper = styled.div<{
  isActive?: boolean;
  isOpen: boolean;
  separator?: 'top' | 'bottom';
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  ${({ isOpen }) =>
    isOpen &&
    css`
      border-bottom: 2px solid black;
    `}
  ${({ separator }) =>
    separator &&
    css`
      border-bottom: 1px solid #c4c4c487;
    `}
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: rgba(196, 196, 196, 0.2);
      color: ${theme.colors.accentColor.default};
    `}
`;

export const StyledNavItem = styled.span<{
  isActive?: boolean;
  isSubHead?: boolean;
}>`
  display: flex;
  width: 100%;
  min-height: 40px;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  padding: 0px 20px;
  font-weight: ${theme.fonts.weights.medium500};

  :hover {
    cursor: pointer;
    /* color: ${theme.colors.accentColor.hover}; */
    background-color: #ebebeb;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      color: ${theme.colors.accentColor.default};
      font-weight: ${theme.fonts.weights.semiBold600};
    `}

  ${({ isSubHead }) =>
    isSubHead &&
    css`
      font-weight: ${theme.fonts.weights.bold700};
      font-size: 16px;
      user-select: none;

      :hover {
        background-color: transparent;
        color: black;
        cursor: default;
      }
    `}
`;

export const WrapperNestedItems = styled.span<{ isActive?: boolean }>`
  display: flex;
  flex-direction: column;
`;
