import { theme } from 'modules/global/theme/theme';
import { NodeDeckStatus } from '../types';

export const backgroundColorByStatus = (status: NodeDeckStatus) => {
  if (status === 'FINISHED') {
    return theme.colors.acceptColor.default;
  }
  if (status === 'ACTIVE') {
    return theme.colors.accentColor.default;
  }
  if (status === 'NONE') {
    return 'black';
  }
  return theme.colors.grey.default;
};
export const titleColorByStatus = (status: NodeDeckStatus) => {
  if (status === 'FINISHED') {
    return theme.colors.acceptColor.default;
  }
  if (status === 'ACTIVE') {
    return theme.colors.accentColor.default;
  }
  return theme.colors.grey.default;
};
