import { createContext } from 'react';
import {
  UpdateCardPayload,
  UpdateKitPayload,
  UpdateModalDecisionPayload,
  UpdateModalGenerateCardPayload,
  UpdateModalImagePayload,
  UpdateModalLocationPayload,
  UpdateModalMarketPayload,
  UpdateModalStoryPayload,
  UpdateResourcePayload,
  UpdateShareModalPayload,
  UpdateStatsPayload,
} from './types/payloads';

import { ResourceTypeForm } from './types/actions';
import { InterfaceStateType, PeriodStatsTypes } from './types/interfaceState';

const showStats = localStorage.getItem('showStats');
const statsPeriod = localStorage.getItem('statsPeriod');
const showKit = localStorage.getItem('showKit');
const kitType = localStorage.getItem('kitType');

export const initialState: InterfaceStateType = {
  stats: {
    period: statsPeriod ? JSON.parse(statsPeriod) : PeriodStatsTypes.NO_STATS,
  },

  modalCard: {
    isOpen: false,
  },
  modalResource: {
    isOpen: false,
    slajdType: ResourceTypeForm.NONE,
  },

  modalShare: {
    isOpen: false,
  },

  modalChangeSpaces: {
    isOpen: false,
  },
  modalImage: {
    isOpen: false,
    setImage: () => null,
  },
  modalStory: {
    isOpen: false,
  },
  modalLocation: {
    isOpen: false,
  },
  modalMarket: {
    isOpen: false,
  },
  modalDecision: {
    isOpen: false,
  },
  modalGenerateCard: {
    isOpen: false,
  },
};

export interface IInterfaceState {
  interfaceState: InterfaceStateType;
  updateResourceModal: (payload: UpdateResourcePayload) => void;
  updateCardModal: (payload: UpdateCardPayload) => void;
  updateShareModal: (payload: UpdateShareModalPayload) => void;
  updateKitPanel: (payload: UpdateKitPayload) => void;
  updateModalImage: (payload: UpdateModalImagePayload) => void;
  updateModalStory: (payload: UpdateModalStoryPayload) => void;
  updateModalLocation: (payload: UpdateModalLocationPayload) => void;
  updateModalMarket: (payload: UpdateModalMarketPayload) => void;
  updateModalDecision: (payload: UpdateModalDecisionPayload) => void;
  updateStats: (payload: UpdateStatsPayload) => void;
  updateGenerateCardModal: (payload: UpdateModalGenerateCardPayload) => void;
}

export const InterfaceContext = createContext<IInterfaceState>({
  interfaceState: initialState,
  updateResourceModal: () => null,
  updateCardModal: () => null,
  updateKitPanel: () => null,
  updateShareModal: () => null,
  updateModalImage: () => null,
  updateModalStory: () => null,
  updateModalLocation: () => null,
  updateModalMarket: () => null,
  updateModalDecision: () => null,
  updateStats: () => null,
  updateGenerateCardModal: () => null,
});
