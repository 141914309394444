import { Button } from 'components/atoms/buttons/Button';
import { ButtonIcon } from 'components/atoms/buttons/ButtonIcon';
import { Icon } from 'components/atoms/icons';
import { ModalBottomPanel } from 'components/molecules/Modal/components/ModalBottomPanel';
import { ModalMain } from 'components/molecules/Modal/components/ModalMain';
import { useCreateImage } from 'modules/file/interactions/mutations';
import { UpdateModalImagePayload } from 'modules/global/state/useInterface/types/payloads';
import { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';
import { StyledWrapper, StyledWrapperDropDown, StyledWrapperImageForm, StyledWrapperOptions } from './Styled';

interface Props {
  formImageState?: UpdateModalImagePayload;
  handleClose: () => void;
}

export const FormNewImage = ({ formImageState, handleClose }: Props) => {
  const createImageMutation = useCreateImage();

  const [localImage, setLocalImage] = useState<File | string | undefined>(formImageState?.image);
  const editorImageRef = useRef<AvatarEditor | null>(null);
  const { getRootProps, getInputProps, open, isFocused, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    maxFiles: 1,
    onDropAccepted(files) {
      setLocalImage(files[0]);
    },
  });

  const handleSaveImage = () => {
    if (editorImageRef.current) {
      const canvasScaled = editorImageRef.current.getImageScaledToCanvas();
      canvasScaled.toBlob(async (blob) => {
        const arrayBuffer = await blob?.arrayBuffer();

        const file = new File([arrayBuffer as BlobPart], 'name', { type: blob?.type });
        setLocalImage(file);
        const data = new FormData();
        data.append('file', file);
        createImageMutation.mutate(data, {
          onSuccess: (res) => {
            formImageState?.setImage && formImageState?.setImage(res);
            handleClose();
          },
        });
      });
    }
  };

  return (
    <StyledWrapper>
      <ModalMain>
        <StyledWrapperImageForm>
          {localImage ? (
            <div>
              <StyledWrapperOptions>
                <ButtonIcon Icon={<Icon name="delete" />} onClick={() => setLocalImage(undefined)} />
              </StyledWrapperOptions>
              <AvatarEditor ref={editorImageRef} width={250} height={250} image={localImage || ''} />
            </div>
          ) : (
            <StyledWrapperDropDown {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
              <input {...getInputProps()} />
              <span>
                Przeciągnij zdjęcie <br />
                lub
              </span>
              <Button variant="outline" text="wybierz z dysku" onClick={open} />
            </StyledWrapperDropDown>
          )}
        </StyledWrapperImageForm>
      </ModalMain>
      <ModalBottomPanel>
        <Button
          text={'Zapisz'}
          onClick={() => {
            handleSaveImage();
          }}
        />
      </ModalBottomPanel>
    </StyledWrapper>
  );
};
