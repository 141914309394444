import { theme } from 'modules/global/theme/theme';
import styled, { CSSProperties } from 'styled-components';

export enum TypographyTypes {
  'headerH1' = 'headerH1',
  'headerH2' = 'headerH2',
  'headerH3' = 'headerH3',
  'headerH4' = 'headerH4',
  'headerH5' = 'headerH5',
  'titleLargeBold' = 'titleLargeBold',
  'titleLargeSemiBold' = 'titleLargeSemiBold',
  'titleSmallBold' = 'titleSmallBold',
  'titleSmallSemiBold' = 'titleSmallSemiBold',
  'bodyLargeBold' = 'bodyLargeBold',
  'bodyLargeMedium' = 'bodyLargeMedium',
  'bodyLargeRegular' = 'bodyLargeRegular',
  'bodySmallBold' = 'bodySmallBold',
  'bodySmallMedium' = 'bodySmallMedium',
  'bodySmallRegular' = 'bodySmallRegular',
  'bodySmallLight' = 'bodySmallLight',
  'linksMedium' = 'linksMedium',
  'linksSemiBold' = 'linksSemiBold',
}

export interface TypographyProps {
  variant?: TypographyTypes;
  color?: string;
  whiteSpace?: 'normal' | 'nowrap';
  hover?: TypographyProps;
}

export const baseTypographyProps = ({ variant, color, whiteSpace, hover }: TypographyProps): CSSProperties => ({
  textAlign: 'left',
  whiteSpace: whiteSpace || 'normal',

  ...(variant === TypographyTypes.headerH1 && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '57px',
    lineHeight: '69px',
  }),
  ...(variant === TypographyTypes.headerH2 && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '48px',
    lineHeight: '58px',
  }),

  ...(variant === TypographyTypes.headerH3 && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '24px',
    lineHeight: '29px',

    [theme.media.up('tablet')]: {
      fontSize: '32px',
      lineHeight: '39px',
    },
    [theme.media.up('extraLarge')]: {
      fontSize: '40px',
      lineHeight: '48px',
    },
  }),
  ...(variant === TypographyTypes.headerH4 && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '32px',
    lineHeight: '39px',
  }),
  ...(variant === TypographyTypes.headerH5 && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '24px',
    lineHeight: '29px',
  }),

  ...(variant === TypographyTypes.titleLargeBold && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '24px',
  }),

  ...(variant === TypographyTypes.titleLargeSemiBold && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '24px',
  }),

  ...(variant === TypographyTypes.titleSmallBold && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '22px',
  }),

  ...(variant === TypographyTypes.titleSmallSemiBold && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '22px',
  }),

  ...(variant === TypographyTypes.bodyLargeBold && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '22px',
  }),

  ...(variant === TypographyTypes.bodyLargeMedium && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '22px',
  }),

  ...(variant === TypographyTypes.bodyLargeRegular && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '22px',
  }),

  ...(variant === TypographyTypes.bodySmallBold && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '19px',
  }),
  ...(variant === TypographyTypes.bodySmallMedium && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '19px',
  }),
  ...(variant === TypographyTypes.bodySmallRegular && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '19px',
  }),

  ...(variant === TypographyTypes.bodySmallLight && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '19px',
  }),

  ...(variant === TypographyTypes.linksSemiBold && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '17px',
    textDecorationLine: 'underline',
  }),
  ...(variant === TypographyTypes.linksMedium && {
    fontFamily: 'inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    textDecorationLine: 'underline',
  }),

  ...(color && {
    color: color,
  }),
});

export const Typography = styled('p')<TypographyProps>((props) => ({
  ...baseTypographyProps(props),
  ['&:hover']: props.hover && { ...baseTypographyProps(props.hover) },
}));
