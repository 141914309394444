import { AutoLayout } from 'components/atoms/AutoLayout';
import { APP_ROUTES } from 'modules/global/routes';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IPath } from 'shared-types-wordkito';
import { Path } from '../Path';

interface Props {
  paths: IPath[];
}

export const PathList = ({ paths }: Props) => {
  const [currentPath, setCurrentPath] = useState<IPath | undefined>(undefined);
  const navigate = useNavigate();
  const handleOpen = (path: IPath) => {
    navigate(APP_ROUTES.PATHS + `/${path.id}`);
  };

  return (
    <AutoLayout wrap gap={20}>
      {paths.map((path) => {
        return (
          <Path key={path.id} path={path} isOpen={currentPath?.id === path.id} onOpen={handleOpen} paths={paths} />
        );
      })}
    </AutoLayout>
  );
};
