import { ButtonUnstyled } from '@mui/base';
import { theme } from 'modules/global/theme/theme';
import styled, { CSSProp } from 'styled-components';

export const StyledButton = styled(ButtonUnstyled)<{
  variant: 'filled' | 'outline' | 'transparent';
  css?: CSSProp;
  fullWidth?: boolean;
  accentColor?: string;
  textColor?: string;
}>(({ variant, fullWidth, textColor, accentColor }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  padding: '6px 16px',
  fontSize: '14px',
  fontWeight: theme.fonts.weights.semiBold600,
  fontFamily: theme.fonts.names.inter,
  backgroundColor: accentColor || theme.colors.accentColor.default,
  outline: 'none',
  borderRadius: theme.borders.small,
  border: 'none',
  color: textColor || 'white',
  cursor: 'pointer',
  width: fullWidth ? '100%' : 'min-content',
  whiteSpace: 'nowrap',

  ':hover': {
    backgroundColor: accentColor || theme.colors.accentColor.hover,
  },

  ':disabled': {
    cursor: 'not-allowed',
    backgroundColor: 'grey',
    color: 'white',
  },

  ':focus': {
    backgroundColor: accentColor || theme.buttons.clicked,
    color: textColor || 'white',
  },

  ...(variant === 'outline' && {
    backgroundColor: '#EFEFFE',
    color: '#5C5EFF',
    ':hover': {
      backgroundColor: theme.colors.accentColor.hover,
      color: 'white',
    },
  }),

  ...(variant === 'transparent' && {
    backgroundColor: 'transparent',
    border: 'none',
    gap: '4px',
    color: theme.colors.accentColor.primary400,
    padding: '0px 0px',
    minHeight: 'none',
    height: 'min-content',
    ':hover': {
      backgroundColor: 'transparent',
      color: theme.colors.accentColor.hover,
    },
    ':focus': {
      backgroundColor: 'transparent',
      color: theme.colors.accentColor.hover,
    },
    ':disabled': {
      cursor: 'pointer',
      pointerEvents: 'none',
      backgroundColor: 'transparent',
      color: 'grey',
    },
  }),
}));
