import { LanguageAppType } from 'modules/language/models/languageApp';
import { Language } from 'shared-types-wordkito';

const PL: LanguageAppType = {
  languages: [
    { name: 'Polski', iso: Language.pl },
    { name: 'Angielski', iso: Language.en },
    { name: 'Hiszpański', iso: Language.es },
  ],
  aside: {
    title: 'Niezbędnik',
  },
  navigation: {
    loginButton: 'Zaloguj się',
    appLanguage: 'Język aplikacji',
    play: {
      title: 'Graj',
    },
    add: {
      title: 'Utwórz',
    },
    bases: {
      title: 'Talie',
    },
  },
};

export default PL;
