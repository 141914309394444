import { useDeleteMember } from 'modules/authorization/interactions/mutations/member.mutations';
import { IMember } from 'shared-types-wordkito';
import { MemberItem } from './MemberItem';
import { StyledWrapper } from './Styles';

interface Props {
  members: IMember[];
  currentUserId: string;
}

export const MemberList = ({ members, currentUserId }: Props) => {
  const deletetMember = useDeleteMember();

  const handleRemoveMember = (memberId: string) => {
    deletetMember.mutate(memberId);
  };

  return (
    <StyledWrapper>
      {members.map((member) => (
        <MemberItem
          key={member.id}
          currentUserId={currentUserId}
          member={member}
          handleRemoveMember={handleRemoveMember}
        />
      ))}
    </StyledWrapper>
  );
};
