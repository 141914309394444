export const trueIcon = (
  color: string,
) => `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
<path d="M3.45741 7.23842C3.53962 7.20925 3.61356 7.16018 3.67944 7.09431L8.91524 1.85851C9.04076 1.73298 9.10103 1.57107 9.10103 1.38202C9.10103 1.19328 9.04072 1.03159 8.91524 0.906107C8.78976 0.780628 8.62806 0.720312 8.43932 0.720312C8.25028 0.720312 8.08836 0.780582 7.96284 0.906107L3.21035 5.65859L1.2465 3.69474C1.12157 3.56982 0.962469 3.50895 0.777409 3.50895C0.592349 3.50895 0.433245 3.56982 0.308322 3.69474C0.183675 3.81939 0.120762 3.97982 0.115173 4.16768L0.115167 4.16792C0.109966 4.35773 0.16778 4.52083 0.294094 4.64714L2.74126 7.09431C2.80713 7.16018 2.88107 7.20925 2.96328 7.23842C3.04127 7.26646 3.12387 7.28011 3.21035 7.28011C3.29682 7.28011 3.37942 7.26647 3.45741 7.23842ZM3.45741 7.23842C3.45748 7.2384 3.45756 7.23837 3.45764 7.23834L3.42377 7.14425L3.45716 7.23851C3.45724 7.23848 3.45732 7.23845 3.45741 7.23842ZM3.21035 5.80002L3.28106 5.72931H3.13964L3.21035 5.80002Z" fill="${encodeURIComponent(
  `${color}`,
)}" stroke-width="0.2"/>
</svg>`;

export const falseIcon = (
  color: string,
) => `<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
<path d="M8.12006 0.593367C8.20248 0.627559 8.27734 0.677665 8.34037 0.740817L8.12006 0.593367ZM8.12006 0.593367C8.03761 0.559161 7.94923 0.541553 7.85996 0.541553M8.12006 0.593367L7.85996 0.541553M7.85996 0.541553C7.7707 0.541553 7.68231 0.559161 7.59986 0.593367M7.85996 0.541553L7.59986 0.593367M7.59986 0.593367C7.51744 0.627559 7.44258 0.677666 7.37955 0.74082M7.59986 0.593367L7.37955 0.74082M7.37955 0.74082C7.37952 0.740846 7.37949 0.740871 7.37947 0.740897M7.37955 0.74082L7.37947 0.740897M7.37947 0.740897L4.60857 3.50613L1.83752 0.735085L7.37947 0.740897ZM8.34037 1.69583C8.60025 1.43596 8.60026 1.00075 8.34041 0.740857L1.61713 0.587556C1.53468 0.553349 1.44629 0.535742 1.35703 0.535742C1.26776 0.535742 1.17938 0.553349 1.09693 0.587556C1.01448 0.621762 0.939585 0.671895 0.876536 0.735085L0.947327 0.805716L0.876616 0.735006C0.61092 1.0007 0.61092 1.43014 0.876616 1.69583L3.64767 4.46689L0.876616 7.23794C0.61092 7.50363 0.61092 7.93307 0.876616 8.19876C1.14231 8.46446 1.57175 8.46446 1.83744 8.19876L4.6085 5.42771L7.37955 8.19876C7.64524 8.46446 8.07468 8.46446 8.34037 8.19876C8.60607 7.93307 8.60607 7.50363 8.34037 7.23794L5.56932 4.46689L8.34037 1.69583Z" fill="${encodeURIComponent(
  `${color}`,
)}" stroke-width="0.3"/>
</svg>`;
