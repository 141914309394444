import { Tooltip } from 'components/atoms/Tooltip';
import React, { ReactNode } from 'react';
import { useMatch } from 'react-router-dom';
import { StyledNavItemOff } from './Styles';

interface Props {
  text: ReactNode;
  to: string;
  tooltipText?: string;
  off?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  match?: Array<string>;
}

export const NavItem = ({ text, to, tooltipText }: Props) => {
  const isMatch = useMatch(to);

  return (
    <Tooltip title={tooltipText || ''}>
      <StyledNavItemOff to={to} isActive={!!isMatch} role="link">
        {text}
      </StyledNavItemOff>
    </Tooltip>
  );
};
