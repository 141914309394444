export const getInitialTheme = (): 'light' | 'dark' => {
  const localStorageTheme = localStorage.getItem('theme');

  if (localStorageTheme === 'light' || localStorageTheme === 'dark') {
    return localStorageTheme;
  }

  return 'light';
};

export function useTheme() {
  const changeTheme = () => {
    const actualTheme = document.body.dataset.theme;
    const actualThemesSwitched = actualTheme === 'light' ? 'dark' : 'light';
    document.body.dataset.theme = actualThemesSwitched;
    localStorage.setItem('theme', actualThemesSwitched);
  };

  const setInitialTheme = () => {
    const localStorageTheme = localStorage.getItem('theme');

    if (localStorageTheme && (localStorageTheme === 'light' || localStorageTheme === 'dark')) {
      document.body.dataset.theme = localStorageTheme;

      return;
    }

    document.body.dataset.theme = 'light';
    localStorage.setItem('theme', 'light');
  };

  return { setInitialTheme, changeTheme };
}
