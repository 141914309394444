import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MembersQueryKeys } from 'modules/authorization/constants/queryKeys';
import { AxiosErrorExtended } from 'modules/global/interactions/queries';
import { CreateMemberDto, IMember } from 'shared-types-wordkito';
import { createMember, deleteMember, updateMember } from '../methods/member';

export const useCreateMember = () => {
  const queryClient = useQueryClient();

  return useMutation<IMember, AxiosErrorExtended, CreateMemberDto>(createMember, {
    onSuccess: (data) => {
      queryClient.setQueryData<IMember[]>(MembersQueryKeys.members(undefined), (prev) => [data, ...(prev || [])]);
    },
  });
};

export const useUpdateMember = () => {
  const queryClient = useQueryClient();

  return useMutation(updateMember, {
    onSuccess(data) {
      queryClient.setQueryData<IMember[]>(MembersQueryKeys.members(undefined), (prev) => {
        return prev?.map((item) => (item.id === data?.id ? { ...item, ...data } : item));
      });
    },
  });
};

export const useDeleteMember = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteMember, {
    onSuccess(data, variable) {
      queryClient.setQueryData<IMember[]>(MembersQueryKeys.members(undefined), (prev) =>
        prev?.filter((item) => item.id !== variable),
      );
    },
  });
};
