import { InputText } from 'components/atoms/inputs/texts/InputText';
import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { UseFormReturn } from 'react-hook-form';
import { IDeck } from 'shared-types-wordkito';
import { StyledWrapper, StyledWrapperText } from './Styled';

interface Props {
  deck: IDeck;
  form: UseFormReturn<{ price: number }, any>;
}

export const FormMarket = ({ deck, form }: Props) => {
  return (
    <StyledWrapper>
      <StyledWrapperText>
        <InputText
          control={form.control}
          // register={form.register('price', {
          //   min: { value: 0, message: 'Minimalna cena to 0 ki' },
          //   required: 'Pole wymagane',
          //   validate: (value) => {
          //     if (value && value < 0) {
          //       return 'Minimalna cena to 0 ki';
          //     }
          //     if (value && value.toString().length > 1 && value.toString().startsWith('0')) {
          //       return 'Cena nie może zaczynać się od 0';
          //     }
          //     return true;
          //   },
          // })}
          inputProps={{
            min: 0,
            step: 1,
          }}
          error={!!form.formState.errors.price}
          helperText={form.formState.errors.price?.message}
          name="price"
          label="Cena"
          defaultValue={''}
          type="number"
        />
        {/* TUTAJ ZROBIĆ TABELE Z TRANSAKCJAMI */}
        <Typography variant={TypographyTypes.bodySmallRegular}>
          Dostęp wykupiło {deck?.transactionsCount || 0} podróżników
          {/* {summedEarnings.toFixed(2)} <KiCoinIcon size="18" /> */}
        </Typography>
      </StyledWrapperText>
    </StyledWrapper>
  );
};
