import { OptionUnstyled, optionUnstyledClasses, selectUnstyledClasses } from '@mui/base';
import { theme } from 'modules/global/theme/theme';
import styled, { css } from 'styled-components';

export const StyledWrapper = styled('div')(({}) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  gap: '4px',
}));

export const StyledInputSelect = styled('button')<{
  withArrow?: boolean;
  variant: 'compact' | 'normal' | 'ghost';
}>(
  ({ variant, withArrow }) => css`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    color: #3d3d3d;
    font-family: ${theme.fonts.names.inter};
    font-size: 14px;
    text-align: left;
    height: 28px;
    min-height: 28px;
    border: none;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: ${theme.borders.small};
    outline: none;
    cursor: pointer;

    ${withArrow &&
    `
      &.${selectUnstyledClasses.expanded} {
        &::after {
          justify-self: flex-end;
          content: '▴';
        }
      }

      &::after {
        justify-self: flex-end;
        content: '▾';
      }

      svg {
        fill: #3d3d3d;
      }
    `}

    ${variant === 'ghost' &&
    css`
      background: transparent;
      height: min-content;
      border: none;
      min-height: min-content;
      margin-top: 0px;
      padding: 0px;
    `}

    ${variant === 'compact' &&
    css`
      border: 10px 10px 0px 0px;
    `}
  `,
);

export const WrapperOptions = styled('ul')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  background: '#ffffff',
  padding: '0px 10px',
  borderRadius: theme.borders.small,
  border: '1px solid #e0e0e0',
  gap: '12px',
  maxHeight: '200px',
  overflow: 'auto',
  width: '100%',
}));

export const StyledOption = styled(OptionUnstyled)(({}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  minWidth: '100%',
  minHeight: '35px',
  fontSize: '14px',
  fontWeight: theme.fonts.weights.regular400,
  color: '#3d3d3d',
  listStyle: 'none',

  svg: {
    fill: '#3d3d3d',
  },

  ':hover': {
    cursor: 'pointer',
    color: theme.colors.accentColor.hover,
    '.Icon': {
      fill: theme.colors.accentColor.hover,
    },
  },

  [`&.${optionUnstyledClasses.selected}`]: {
    color: theme.colors.accentColor.default,
    svg: {
      fill: theme.colors.accentColor.default,
    },
    ':hover': {
      color: theme.colors.accentColor.hover,
      '.Icon': {
        fill: theme.colors.accentColor.hover,
      },
    },
  },
}));
