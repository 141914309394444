import { MenuPage, MenuPages, MenuTab, MenuTabItem } from 'components/molecules/TabList';
import { UpdateModalImagePayload } from 'modules/global/state/useInterface/types/payloads';
import { useState } from 'react';
import { ImageListForm } from './ImageList';
import { FormNewImage } from './NewImage';
import { StyledWrapper } from './Styled';

interface Props {
  formImageState?: UpdateModalImagePayload;
  handleClose: () => void;
}

enum FormImagePagesTypes {
  NEW = 'NEW',
  OWN = 'OWN',
  MARKET = 'MARKET',
}

export const FormImage = ({ formImageState, handleClose }: Props) => {
  const [pageType, setPageType] = useState<FormImagePagesTypes>(FormImagePagesTypes.OWN);

  return (
    <StyledWrapper>
      <MenuTab>
        <MenuTabItem
          isActive={pageType === FormImagePagesTypes.OWN}
          text="Wybierz własne"
          onClick={() => setPageType(FormImagePagesTypes.OWN)}
        />
        <MenuTabItem
          isActive={pageType === FormImagePagesTypes.NEW}
          text="Dodaj zdjęcie"
          onClick={() => setPageType(FormImagePagesTypes.NEW)}
        />
      </MenuTab>
      <MenuPages value={pageType}>
        <MenuPage value={FormImagePagesTypes.NEW}>
          <FormNewImage formImageState={formImageState} handleClose={handleClose} />
        </MenuPage>
        <MenuPage value={FormImagePagesTypes.OWN}>
          <ImageListForm
            handleChooseImage={(image) => {
              formImageState?.setImage && formImageState?.setImage(image);
              handleClose();
            }}
          />
        </MenuPage>
      </MenuPages>
    </StyledWrapper>
  );
};
