import { theme } from 'modules/global/theme/theme';
import styled from 'styled-components';

export const StyledWrapper = styled('header')(({}) => ({
  position: 'fixed',
  top: '0px',
  left: '0px',
  width: '100%',
  backgroundColor: theme.colors.backgroundBoxColor,
  borderBottom: '1px solid #e0e0e0',
  padding: '10px 20px',
  transition: '0.3s',
  zIndex: 10,
}));

export const StyledWrapperInner = styled('div')(({}) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  maxWidth: '600px',
  width: '100%',
  margin: '0px auto',
}));
