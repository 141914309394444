import { CustomSvgIconProps, Icon } from '.';

export const MoreIcon = (props: CustomSvgIconProps) => (
  <Icon {...props}>
    <>
      <circle r="1.79014" transform="matrix(-1 0 0 1 12.2606 13.0313)" />
      <circle r="1.79014" transform="matrix(-1 0 0 1 12.2606 6.67197)" />
      <circle r="1.79014" transform="matrix(-1 0 0 1 12.2606 19.1681)" />
    </>
  </Icon>
);
