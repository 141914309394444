import styled from 'styled-components';

export const StyledWrapperMunuSubHead = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
}));

export const StyledWrapperMunuSubHeadInner = styled('div')(({}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));
