import React, { cloneElement } from 'react';
import { Wrapper } from './Styles';

export interface Props {
  text: number | string;
  Icon?: JSX.Element;
  onClick: () => void;
  primary?: boolean | undefined;
  secondary?: boolean | undefined;
  isActive?: boolean;
}

export const MenuTabItem: React.FC<Props> = ({ text, primary, onClick, Icon, isActive }) => {
  const clonedIcon = Icon
    ? cloneElement(Icon, {
        size: 20,
      })
    : undefined;
  return (
    <Wrapper onClick={onClick} isActive={isActive} primary={primary}>
      {clonedIcon}
      {text}
    </Wrapper>
  );
};

export default MenuTabItem;
