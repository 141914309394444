/* eslint-disable react/jsx-max-depth */

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from 'apps/App';
import { firebaseAuth } from 'modules/authentication/config';
import { postLogin } from 'modules/authentication/interactions/apiMethods';
import { AuthProvider } from 'modules/authentication/states/useAuth';
import { InterfaceProvider } from 'modules/global/state/useInterface';
import { LanguageProvider } from 'modules/language/useLanguage';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { IoProvider } from 'socket.io-react-hook';

const root = createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {},
    queries: {
      cacheTime: 5000,
      staleTime: 5000,
      retry: 0,
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

firebaseAuth.onAuthStateChanged(async (user) => {
  const loggedUser = user ? await postLogin({}) : undefined;

  root.render(
    <StrictMode>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <IoProvider>
            <AuthProvider initialUser={loggedUser}>
              <LanguageProvider>
                <InterfaceProvider>
                  <HelmetProvider>
                    <ReactFlowProvider>
                      <App />
                    </ReactFlowProvider>
                  </HelmetProvider>
                </InterfaceProvider>
              </LanguageProvider>
            </AuthProvider>
          </IoProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </StrictMode>,
  );
});
