import { TextareaAutosize } from '@mui/base';
import { theme } from 'modules/global/theme/theme';
import styled from 'styled-components';

export const StyledWrapperInputText = styled.div<{ fullWidth?: boolean }>(({ fullWidth }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',

  ...(fullWidth && {
    width: '100%',
  }),
}));

export const StyledInputRoot = styled('div')(({}) => ({
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: '3.5px 8px',
  width: '100%',
  fontSize: '16px',
  fontWeight: theme.fonts.weights.regular400,

  background: '#F5F5F5',
  borderRadius: theme.borders.small,
  border: '1px solid #e0e0e0',
  outline: 'none',
}));

export const StyledInputText = styled('input')<{
  isIcon?: boolean;
  isButtons?: boolean;
  variant: 'primary' | 'search';
}>(() => ({
  fontFamily: theme.fonts.names.inter,
  color: '#7F7F7F',
  border: 'none',
  outline: 'none',
  fontSize: '14px',
  borderRadius: theme.borders.small,
  width: '100%',
  backgroundImage: 'none !important',
  background: '#F5F5F5 !important',

  ':-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 1000px white inset',
    background: '#F5F5F5 !important',
  },
}));

export const StyledInputTextArea = styled(TextareaAutosize)<{
  isIcon?: boolean;
  isButtons?: boolean;
  variant: 'primary' | 'search';
}>(() => ({
  fontFamily: theme.fonts.names.inter,
  border: 'none',
  resize: 'none',
  outline: 'none',
  fontSize: '16px',
  width: '100%',
  padding: '0px 0px',
  backgroundImage: 'none !important',
  background: '#F5F5F5 !important',

  ':-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 1000px white inset',
    backgroundColor: '#F5F5F5 !important',
  },
}));

export const WrapperIcon = styled('div')(({}) => ({
  position: 'absolute',
  left: '12px',
  top: '35px',
}));

export const WrapperButtons = styled('div')(({}) => ({
  position: 'absolute',
  right: '12px',
  top: '30px',
}));

export const StyledError = styled('div')(({}) => ({
  paddingTop: '2px',
  paddingLeft: '6px',
}));

export const InputAdornment = styled('div')(({}) => ({
  display: 'flex',
  alignSelf: 'flex-end',
}));
