import { IResourceScopesIDs } from 'modules/authorization/models/scope';
import { axiosDelete, axiosGet, axiosPost } from 'modules/global/helpers/fetchApiMethods';
import { API_URLS } from 'modules/global/urls';
import { CreateMemberResourceDto, IMember } from 'shared-types-wordkito';

export const getMembersResource = async ({ deckId, pathId }: IResourceScopesIDs) => {
  let url = API_URLS.MEMBERS;

  if (deckId) {
    url = API_URLS.GET_DECK_MEMBERS(deckId);
  }

  if (pathId) {
    url = API_URLS.PATH_MEMBERS(pathId);
  }

  const response = await axiosGet<IMember[]>(url);

  return response.data;
};

export const createMemberResource = async (body: CreateMemberResourceDto) => {
  const url = API_URLS.CREATE_MEMBER;

  const response = await axiosPost<CreateMemberResourceDto, IMember>(url, body);

  return response.data;
};

export const deleteRoleMemberByResource = async ({ roleMemberId }: { roleMemberId: string }) => {
  const response = await axiosDelete<undefined>(API_URLS.MEMBER_DETAILS(roleMemberId));

  return response.data;
};
