import { tooltipClasses, TooltipProps } from '@mui/material';
import { theme } from 'modules/global/theme/theme';
import styled from 'styled-components';
import { StyledTooltip as MuiTooltip } from './Styles';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: 'white',
    fontSize: '12px',
    backgroundColor: theme.colors.grey.default,
    padding: '8px',
  },
}));
