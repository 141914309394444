import { CircularLoader } from 'components/atoms/CircularLoader';
import { Tooltip } from 'components/atoms/Tooltip';
import { AcceptIcon } from 'components/atoms/icons/AcceptIcon';

interface Props {
  gainedPoints: number;
  maxPoints: number;
}

export const PointsCircle = ({ gainedPoints, maxPoints }: Props) => {
  // zrobić że jeżeli jest 100% to ma być zielony kolor z ptaszkiem
  return (
    <Tooltip
      title={gainedPoints === maxPoints ? 'Brawo !' : `Zdobyłeś ${gainedPoints.toFixed(1)} z ${maxPoints} punktów`}
    >
      <div>
        <CircularLoader
          variant="determinate"
          value={(gainedPoints / maxPoints) * 100}
          label={
            gainedPoints !== maxPoints ? (
              `${gainedPoints.toFixed(1)}/${maxPoints.toFixed(1)}`
            ) : (
              <AcceptIcon isCorrect color="white" />
            )
          }
          isFilled={gainedPoints === 0 ? false : gainedPoints === maxPoints}
        />
      </div>
    </Tooltip>
  );
};
