import styled from 'styled-components';

export const StyledWrapper = styled('div')(({}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const StyledWrapperControls = styled('div')(({}) => ({
  // position: 'absolute',
  // right: '0px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

export const StyledWrapperTextTypeButtons = styled('div')(({}) => ({
  // position: 'absolute',
  // right: '0px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));
