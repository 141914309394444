import { SwitchUnstyled, SwitchUnstyledProps } from '@mui/base';
import { theme } from 'modules/global/theme/theme';
import { Control, Controller } from 'react-hook-form';

import { Input, Root, StyledWrapper, Thumb, Track } from './Styles';

export interface Props extends SwitchUnstyledProps {
  label?: string;
  control?: Control<any>;
  name: string;
  value?: boolean;
  checkColor?: string;
  uncheckColor?: string;
  unCheckIcon?: (color: string) => string;
  checkIcon?: (color: string) => string;
}

export const SwitchToggle = ({
  label,
  control,
  name,
  checkIcon,
  unCheckIcon,
  value,
  uncheckColor = 'grey',
  checkColor = theme.colors.accentColor.default,
  ...rest
}: Props) => {
  if (!control) {
    return (
      <StyledWrapper>
        <SwitchUnstyled
          slots={{ root: Root, input: Input, thumb: Thumb, track: Track }}
          slotProps={{
            root: () => ({
              checkColor: checkColor,
              uncheckColor: uncheckColor,
            }),
            thumb: () => ({
              checkColor: checkColor,
              uncheckColor: uncheckColor,
              checked: value,
              checkIcon,
              unCheckIcon,
            }),
            track: () => ({
              checkColor: checkColor,
              uncheckColor: uncheckColor,
              checked: value,
            }),
          }}
          {...rest}
        />
        {label && label}
      </StyledWrapper>
    );
  }

  return (
    <>
      <Controller
        control={control}
        defaultValue={false}
        name={name}
        render={({ field }) => {
          return (
            <StyledWrapper>
              <SwitchUnstyled
                slots={{ root: Root, input: Input, thumb: Thumb, track: Track }}
                slotProps={{
                  root: () => ({
                    checkColor: checkColor,
                    uncheckColor: uncheckColor,
                  }),
                  thumb: () => ({
                    checkColor: checkColor,
                    uncheckColor: uncheckColor,
                    checked: field.value,
                    checkIcon,
                    unCheckIcon,
                  }),
                  track: () => ({
                    checkColor: checkColor,
                    uncheckColor: uncheckColor,
                    checked: field.value,
                  }),
                }}
                {...field}
                {...rest}
              />
              {label && label}
            </StyledWrapper>
          );
        }}
      />
    </>
  );
};
export default SwitchToggle;
