import { useForm } from 'react-hook-form';

import { Main } from 'components/organisms/forms/FormDeck/components/Main';

import { AutoLayout } from 'components/atoms/AutoLayout';
import { ModalBottomPanel } from 'components/molecules/Modal/components/ModalBottomPanel';
import { ModalFormHeader } from 'components/molecules/Modal/components/ModalFormHeader';
import { ModalMain } from 'components/molecules/Modal/components/ModalMain';
import { ModalMainTemplate } from 'components/molecules/Modal/components/ModalMainTemplate';
import { useCreateDeck, useUpdateDeck } from 'modules/deck/interactions/queries/deck';
import { APP_ROUTES } from 'modules/global/routes';
import { useLanguage } from 'modules/language/useLanguage';
import { useNavigate } from 'react-router-dom';
import { useReactFlow } from 'reactflow';
import { CreateDeckDto, IDeck, Language } from 'shared-types-wordkito';
import { ButtonsAction } from './components/ButtonsAction';

export interface Props {
  deck?: IDeck;
  pathToAdd?: string;
  sourceDeckId?: string;
  handleBack: () => void;
  handleClose: () => void;
}

export const FormDeck = ({ handleBack, deck, sourceDeckId, pathToAdd, handleClose }: Props) => {
  const createDeck = useCreateDeck(pathToAdd || '');
  const updateDeck = useUpdateDeck(pathToAdd || '');
  const { fitView, setViewport, setNodes } = useReactFlow();
  const navigate = useNavigate();
  const { languageState } = useLanguage();

  const isEditMode = !!deck;

  const defaultValues: CreateDeckDto = {
    name: deck?.name || '',
    languageFrom: deck?.languageFrom || Language.pl,
    contextToPrompt: '',
  };

  const form = useForm<CreateDeckDto>({ defaultValues });

  const onSubmit = async (data: CreateDeckDto) => {
    if (!deck?.id) {
      createDeck.mutateAsync(
        {
          ...data,
          sourceDeckId: sourceDeckId,
          path: pathToAdd,
        },
        {
          onSuccess: (data) => {
            navigate(APP_ROUTES.PATHS + `/${pathToAdd}` + `?storyId=${data?.id}`, {});
            setNodes([
              {
                id: data.id,
                type: 'deckNode',
                data: data,
                position: { x: 0, y: 0 },
              },
            ]);
            fitView({ nodes: [{ id: data.id || '' }], duration: 1000, minZoom: 0.5 });
            handleClose();
          },
        },
      );
    } else {
      await updateDeck.mutateAsync(
        {
          baseId: deck.id,
          data: { ...data, path: pathToAdd, sourceDeckId },
        },
        {
          onSuccess: (data) => {
            navigate(APP_ROUTES.PATHS + `/${pathToAdd}` + `?story=${data?.id}`, {});
            handleClose();
          },
        },
      );
    }
  };

  return (
    <ModalMainTemplate>
      <ModalFormHeader
        textHeader={deck?.id ? 'Zaktualizuj opowieść' : 'Utwórz nową opowieść'}
        textSubHeader={deck?.name}
      />

      <AutoLayout as={'form'} fullWidth column onSubmit={form.handleSubmit(onSubmit)}>
        <ModalMain>
          <AutoLayout column gap={30}>
            <Main
              languages={languageState.languages.map((item) => ({
                iso: item.iso,
                name: item.name,
              }))}
              form={form}
              languageFrom={deck?.languageFrom || Language.pl}
            />
          </AutoLayout>
        </ModalMain>
        <ModalBottomPanel>
          <ButtonsAction
            isEditMode={isEditMode}
            isLoading={createDeck.isLoading || updateDeck.isLoading}
            isEnabled={form.formState.isDirty && !createDeck.isLoading && !updateDeck.isLoading}
            handleBack={handleBack}
          />
        </ModalBottomPanel>
      </AutoLayout>
    </ModalMainTemplate>
  );
};
