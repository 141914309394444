import { theme } from 'modules/global/theme/theme';
import styled from 'styled-components';

export const StyledLabel = styled.label<{ required?: boolean }>`
  display: flex;
  font-size: 14px;
  color: #616161;
  white-space: nowrap;
  font-family: ${theme.fonts.names.inter};
  font-weight: ${theme.fonts.weights.medium500};
`;

// ${({ required }) =>
//   required &&
//   css`
//     ::before {
//       font-weight: ${theme.fonts.weights.semiBold600};
//       margin-left: 2px;
//       content: '*';
//       color: ${theme.colors.warningColor.default};
//     }
//   `}
