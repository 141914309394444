import { ModalMain } from 'components/molecules/Modal/components/ModalMain';
import styled from 'styled-components';

export const StyledWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledModalMain = styled(ModalMain)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  gap: 24px;
`;
