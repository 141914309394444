import { Modal } from 'components/molecules/Modal';
import { ModalFormHeader } from 'components/molecules/Modal/components/ModalFormHeader';
import { ModalMainTemplate } from 'components/molecules/Modal/components/ModalMainTemplate';
import { FormImage } from 'components/organisms/forms/FormImage';
import { UpdateModalImagePayload } from 'modules/global/state/useInterface/types/payloads';

export interface Props {
  modalImageState: UpdateModalImagePayload;
  handleClose: () => void;
}

export const ModalImage = ({ handleClose, modalImageState }: Props) => {
  return (
    <Modal isOpen={modalImageState.isOpen} onClose={() => handleClose()}>
      <ModalMainTemplate>
        <ModalFormHeader textHeader={'Dodaj zdjęcie'} />
        <FormImage formImageState={modalImageState} handleClose={() => handleClose()} />
      </ModalMainTemplate>
    </Modal>
  );
};
