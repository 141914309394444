import { CircularLoader } from 'components/atoms/CircularLoader';
import { Button } from 'components/atoms/buttons/Button';
import { ButtonIcon } from 'components/atoms/buttons/ButtonIcon';
import { AcceptIcon } from 'components/atoms/icons/AcceptIcon';
import { InputText } from 'components/atoms/inputs/texts/InputText';
import { useAuth } from 'modules/authentication/states/useAuth';
import { useDeleteUser, useUpdateDisplayName } from 'modules/user/interactions/hooks';
import { useForm } from 'react-hook-form';
import { StyledWrapper } from './Styles';

const UserSettings = () => {
  const { user, updateDisplayName } = useAuth();
  const { handleLogout } = useAuth();
  const deleteUser = useDeleteUser();
  const updateDisplayNameMutation = useUpdateDisplayName();

  const defaultValues = {
    displayName: user?.displayName || '',
    language: 'pl',
    languagesToLearn: [],
  };

  const methods = useForm({
    defaultValues,
  });

  const handleDeleteUser = async () => {
    if (user) {
      await deleteUser.mutate(
        {},
        {
          onSuccess: () => {
            handleLogout();
          },
        },
      );
    }
  };

  return (
    <StyledWrapper>
      <InputText
        error={!!methods.formState.errors.displayName}
        helperText={methods.formState.errors.displayName?.message}
        name="displayName"
        control={methods.control}
        defaultValue={user?.displayName || ''}
        label="Zmień nazwę użytkownika"
        endAdornment={
          methods.formState.dirtyFields.displayName && (
            <ButtonIcon
              disabled={!methods.formState.dirtyFields.displayName}
              onClick={() => {
                if (methods.getValues('displayName') !== '') {
                  updateDisplayNameMutation.mutate(
                    {
                      displayName: methods.getValues('displayName'),
                    },
                    {
                      onError: (err) => {},
                      onSuccess: (res) => {
                        methods.resetField('displayName', { defaultValue: res });
                        updateDisplayName(res || '');
                      },
                    },
                  );
                }
              }}
              Icon={updateDisplayNameMutation.isLoading ? <CircularLoader size={20} /> : <AcceptIcon isCorrect />}
            />
          )
        }
      />
      <Button
        variant="outline"
        text="Usuń konto"
        disabled={deleteUser.isLoading}
        isLoading={deleteUser.isLoading}
        onClick={() => {
          handleDeleteUser();
        }}
      />
    </StyledWrapper>
  );
};

export default UserSettings;
