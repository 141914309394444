import { IResourceScopesIDs } from 'modules/authorization/models/scope';
import { UseFormReturn } from 'react-hook-form';
import { IMember, PermissionTemplate } from 'shared-types-wordkito';
import { StyledWrapper } from './Styled';

interface Props {
  resource: IResourceScopesIDs;
  form: UseFormReturn<{
    permissionTemplate: PermissionTemplate | undefined;
    memberId: string;
  }>;

  members: IMember[];
}

export const InviteForm = ({ resource, form, members }: Props) => {
  return (
    <StyledWrapper>
      {/* <StyledWrapperInputs>
        <InputText type={'email'} register={form.register('email', { required: true })} label="Email" />
        <Select name="role" label="Rola" defaultValue={undefined} control={form.control} options={[]} />
      </StyledWrapperInputs> */}

      {/* MemberTransfer */}

      {/* <MemberList members={members} /> */}
    </StyledWrapper>
  );
};
