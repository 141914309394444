import { Typography, TypographyTypes } from 'components/atoms/Typography';

import { Wrapper } from './Styles';

interface Props {
  text: string;
}
const Separator = ({ text }: Props) => (
  <Wrapper>
    <hr />
    <Typography whiteSpace="nowrap" variant={TypographyTypes.bodySmallRegular}>
      {text}
    </Typography>
    <hr />
  </Wrapper>
);

export default Separator;
