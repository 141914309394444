import { Select } from 'components/atoms/inputs/selects/Select';
import { ModalMain } from 'components/molecules/Modal/components/ModalMain';
import { IResourceScopesIDs } from 'modules/authorization/models/scope';
import { useForm } from 'react-hook-form';
import { StyledWrapper } from './Styled';

interface Props {
  resource: IResourceScopesIDs;
}

export const LinkForm = ({ resource }: Props) => {
  const methods = useForm();

  const link = `${process.env.REACT_APP_URL}/decks/${resource.deckId}?shareToken="dsadsadasdsa"`;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
  };

  return (
    <ModalMain>
      <StyledWrapper>
        <Select
          control={methods.control}
          name="role"
          label="Rola"
          options={[
            { text: 'Moderator', value: 'BaseModerator' },
            { text: 'Owner', value: 'BaseOwner' },
          ]}
        />

        {/* Jezeli rola jest nie wybrana to nie pokazuj linka ora gry link jest nie stworzony */}
        {/* <InputText
          label="Link do talii"
          value={link}
          multiline
          endAdornment={<ButtonIcon Icon={<DuplicateIcon />} onClick={() => handleCopyLink()} />}
          readOnly
        /> */}
      </StyledWrapper>
    </ModalMain>
  );
};
