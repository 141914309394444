import { forwardRef } from 'react';
import { StyledOverlay } from './Styled';

export const BackDrop = forwardRef<HTMLDivElement, { open?: boolean; className: string }>((props, ref) => {
  const { open, className, ...other } = props;

  return <StyledOverlay ref={ref} {...other} />;
});

BackDrop.displayName = 'BackDrop';
