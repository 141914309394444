import { ReactNode } from 'react';
import { PanelTop } from './PanelTop';
import { StyledWrapper } from './Styled';

interface Props {
  onClose?: () => void;
  children: ReactNode;
  isAbsolute?: boolean;
}

export const ModalMainTemplate = ({ onClose, children, isAbsolute }: Props) => (
  <StyledWrapper>
    {onClose && <PanelTop onClose={onClose} />}
    {children}
  </StyledWrapper>
);
