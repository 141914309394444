import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getAccessToken } from '../helpers/getAccessToken';

import { useAuth } from '../states/useAuth';
import { postLogin, updateUserLanguage, updateUserLanguagesToLearn } from './apiMethods';

export const useUpdateUserLanguage = () => {
  const result = useMutation(updateUserLanguage, {
    onSuccess: (res) => {
      // updateLanguage(res);
    },
  });

  return result;
};
export const useAccessToken = () => {
  const { user } = useAuth();
  const [accesstoken, setAccessToken] = useState<string | undefined>(undefined);

  const getToken = async () => {
    const acc = await getAccessToken();

    setAccessToken(acc);
  };

  useEffect(() => {
    getToken();
  }, [user]);

  return accesstoken;
};

export const useLogin = () => {
  const result = useMutation(postLogin);

  return result;
};

export const useUpdateUserLanguagesToLearn = () => {
  const result = useMutation(updateUserLanguagesToLearn, {
    onSuccess: (res) => {},
  });

  return result;
};
