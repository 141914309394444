import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { Button } from 'components/atoms/buttons/Button';
import { GoogleIcon } from 'components/atoms/icons/GoogleIcon';
import { InputText } from 'components/atoms/inputs/texts/InputText';
import { useAuth } from 'modules/authentication/states/useAuth';
import { AuthProviders } from 'modules/authentication/states/useAuth/context';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { StyledWrapper, StyledWrapperForm, WrapperInputs, WrapperSeparator } from './Styles';
import Separator from './components/Separator';

interface defaultValues {
  email: string;
}

const LoginPage = () => {
  const { handleLogin } = useAuth();
  const defaultValues = { email: '' };
  const [isEmailSent, setIsEmailSent] = useState(false);
  const form = useForm<defaultValues>({ defaultValues });

  const handleLoginByEmail = (data: defaultValues) => {
    if (data.email) {
      setIsEmailSent(true);
      handleLogin({ provider: AuthProviders.EMAIL, email: data.email });
    }
  };

  return (
    <StyledWrapper>
      <Typography variant={TypographyTypes.titleLargeSemiBold}>Zaloguj się lub zarejestruj</Typography>
      <StyledWrapperForm>
        <Button
          fullWidth
          variant="outline"
          text="Kontynuuj z google"
          onClick={() => handleLogin({ provider: AuthProviders.GOOGLE })}
          leftIcon={<GoogleIcon />}
        />
        <WrapperSeparator>
          <Separator text="lub za pomocą emaila" />
        </WrapperSeparator>
        <WrapperInputs>
          <InputText
            defaultValue=""
            label="Email"
            placeholder="nazwa@domena.com"
            error={!!form.formState.errors.email}
            helperText={
              !form.formState.errors.email?.message && isEmailSent
                ? 'Wysłaliśmy do Ciebie link na ten adres email'
                : 'Ten typ logowania nie wymaga hasła'
            }
            name="email"
            type={'email'}
            required
            control={form.control}
          />

          <Button
            fullWidth
            disabled={(!form.formState.isDirty && form.formState.isSubmitted) || !form.watch('email').length}
            text={'Wyślij link na podany email'}
            onClick={form.handleSubmit(handleLoginByEmail)}
            type="button"
            css="margin-top:32px;"
          />
        </WrapperInputs>
      </StyledWrapperForm>
    </StyledWrapper>
  );
};

export default LoginPage;
