import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { positionDeg } from './ArrowIcon';
import { DeleteIcon } from './DeleteIcon';
import GenerateIcon from './GenerateIcon';
import { LevelAdvancedIcon } from './LevelAdvancedIcon';
import { LevelBegginerIcon } from './LevelBeginnerIcon';
import { LevelMediumIcon } from './LevelMediumIcon';
import { MarketIcon } from './MarketIcon';
import { PathIcon } from './PathIcon';
import { StoryIcon } from './StoryIcon';

export interface CustomSvgIconProps {
  size?: string;
  color?: string;
  direction?: 'up' | 'down' | 'left' | 'right';
}
interface Props extends React.SVGAttributes<SVGSVGElement> {
  name?: IconName;
  size?: string;
  color?: string;
  direction?: 'up' | 'down' | 'left' | 'right';
  children?: ReactElement;
}
const StyledSvg = styled('svg')<{ color?: string; direction?: 'up' | 'down' | 'left' | 'right' }>(
  ({ color, direction }) => ({
    fill: color ? color : 'currentColor',
    transform: `rotate(${positionDeg(direction)}deg)`,
  }),
);

export const Icon = ({ children, name, size = '24', direction, ...rest }: Props) => (
  <StyledSvg
    width={size}
    height={size}
    direction={direction}
    viewBox={`0 0 24 24`}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {name ? icons[name] : children}
  </StyledSvg>
);

export type IconName = keyof typeof icons;
const icons = {
  delete: DeleteIcon,
  add: DeleteIcon,
  marketplace: MarketIcon,
  generate: GenerateIcon,
  levelMedium: LevelMediumIcon,
  levelBegginer: LevelBegginerIcon,
  levelAdvanced: LevelAdvancedIcon,
  story: StoryIcon,
  path: PathIcon,
};
