import styled from 'styled-components';

interface StyledWrapperProps {}

export const StyledWrapper = styled.div<StyledWrapperProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`;
