import { LoginActionPayload } from 'modules/user/states/useUser/models/payloads';
import { createContext } from 'react';
import { CurrentPermissions, ICurrentUser, ISpace } from 'shared-types-wordkito';

export enum AuthProviders {
  GOOGLE = 'google.com',
  PASSWORD = 'password',
  EMAIL = 'emailLink',
}

export enum AuthProgressStatus {
  STARTUP = 'startup',
  NONE = 'none',
  INPROGRESS = 'inprogress',
}
export const AuthContext = createContext<{
  user: ICurrentUser | undefined;
  permissions: CurrentPermissions[];
  anonymousUser?: {
    id: number;
  };
  login: (payload: LoginActionPayload) => void;
  logout: () => void;
  updateDisplayName: (payload: string) => void;
  updateCurrentSpace: (payload?: ISpace) => void;
  updateBalance: (payload: {
    up: { ki: number; memoryPoints: number };
    down: { ki: number; memoryPoints: number };
  }) => void;

  updatePermissions: (payload: { add: CurrentPermissions[]; remove: CurrentPermissions[] }) => void;
  handleLogout: () => void;
  handleLogin: ({
    provider,
    email,
    redirectUrl,
  }: {
    provider: AuthProviders;
    email?: string;
    redirectUrl?: string;
  }) => void;
}>({
  user: undefined,
  permissions: [],
  login: () => null,
  logout: () => null,
  updateDisplayName: () => null,
  updateCurrentSpace: () => null,
  updateBalance: () => null,
  updatePermissions: () => null,
  handleLogin: () => null,
  handleLogout: () => null,
});
