import { theme } from 'modules/global/theme/theme';
import styled from 'styled-components';

export const StyledWrapperIconTitle = styled('div')(({}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

export const StyledButton = styled('button')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  color: '#616161',
  gap: '8px',
  backgroundColor: 'white',
  borderRadius: '8px',
  padding: '16px',
  width: '100%',
  textAlign: 'left',
  cursor: 'pointer',
  outline: 'none',
  border: '1px solid #E0E0E0',
  ':hover': {
    border: `1px solid ${theme.colors.accentColor.default}`,
  },
}));
