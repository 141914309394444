import { InputText } from 'components/atoms/inputs/texts/InputText';
import { FormSpaceDefaultValues } from 'modules/space/models/types/formSpace.types';
import { UseFormReturn } from 'react-hook-form';
import { StyledWrapper } from './Styled';

interface Props {
  methods: UseFormReturn<FormSpaceDefaultValues>;
}

export const Main = ({ methods }: Props) => (
  <StyledWrapper>
    <InputText
      defaultValue=""
      label="Nazwa przestrzeni"
      placeholder=""
      name="name"
      error={!!methods.formState.errors?.name}
      helperText={methods.formState.errors?.name?.message || ' '}
      control={methods.control}
      // register={methods.register('name', {
      //   required: { value: true, message: 'To pole jest wymagane' },
      //   maxLength: { value: 80, message: 'Nazwa tali miec wieceje niż 80 znaków' },
      // })}
    />
  </StyledWrapper>
);
