import { axiosGet, axiosPost } from 'modules/global/helpers/fetchApiMethods';
import { API_URLS } from 'modules/global/urls';
import { ISpace } from 'shared-types-wordkito';

export const createSpace = async (body: any) => {
  const response = await axiosPost<any, ISpace>(API_URLS.CREATE_SPACE, body);

  return response.data;
};
export const getSpace = async (spaceId: string) => {
  const response = await axiosGet<ISpace>(API_URLS.GET_SPACE(spaceId));

  return response.data;
};

export const getAllUserSpaces = async () => {
  const response = await axiosGet<ISpace[]>(API_URLS.CURRENT_USER__SPACES);

  return response.data;
};

export const getSharedSpaces = async () => {
  const response = await axiosGet<ISpace[]>(API_URLS.GET_SHARED_SPACES);

  return response.data;
};
