import { Modal } from 'components/molecules/Modal';
import { ModalBottomPanel } from 'components/molecules/Modal/components/ModalBottomPanel';
import { ModalFormHeader } from 'components/molecules/Modal/components/ModalFormHeader';
import { ModalMain } from 'components/molecules/Modal/components/ModalMain';
import { ModalMainTemplate } from 'components/molecules/Modal/components/ModalMainTemplate';
import { FormCard } from 'components/organisms/forms/FormCard';
import { formCardDefaultValuesSwitcher } from 'components/organisms/forms/FormCard/helpers/formCardDefaultValuesSwitcher';
import { PanelBottom } from 'components/organisms/forms/FormCard/PanelBottom';
import { FormDeckCardDefaultValues } from 'modules/card/models/formCard';
import { useUpdateDeckCard } from 'modules/deck/interactions/queries/deckCard';
import { UpdateCardPayload } from 'modules/global/state/useInterface/types/payloads';
import { useFieldArray, useForm } from 'react-hook-form';
import { CreateDeckCardDto, DeckCardTypes } from 'shared-types-wordkito';

export interface Props {
  modalCardState: UpdateCardPayload;
  handleClose: () => void;
}

export const ModalCard = ({ modalCardState, handleClose }: Props) => {
  const updateBaseCard = useUpdateDeckCard(modalCardState.deck?.id);

  const defaultValues = formCardDefaultValuesSwitcher(modalCardState.deckCard, modalCardState.deckCardQuizOptions);

  const form = useForm<FormDeckCardDefaultValues>({ defaultValues });
  const fieldArray = useFieldArray({
    control: form.control,
    rules: { maxLength: 6 },
    name: 'options',
  });

  const handleCreateDeckCard = (data: FormDeckCardDefaultValues, withNext?: boolean) => {
    if (!data.mainContent || !modalCardState.deck?.id || !modalCardState.cardType) return;

    const dataToSend: CreateDeckCardDto = {
      id: modalCardState.deckCard?.id,
      deckId: modalCardState.deck?.id,
      targetDeckCardId: modalCardState.targetDeckCardId,
      mainContent: data.mainContent?.field,
      type: modalCardState.cardType,
      character: data.character?.name,
      isCorrect: data.mainContent?.isCorrect,
      sourceDeckCardId: undefined,
      isQuizOption: false,
      quizOptions: data.options
        ? data.options.map((option) => ({
            id: option.id,
            targetDeckCardId: modalCardState?.deckCard?.id,
            deckId: modalCardState.deck?.id || '',
            mainContent: option.field,
            isCorrect: option.isCorrect,
            isQuizOption: true,
            type: DeckCardTypes.CONTENT,
            character: data.character?.name,
          }))
        : [],
    };
    if (modalCardState?.deckCard?.id) {
      updateBaseCard.mutateAsync(
        { data: dataToSend, baseCardId: modalCardState?.deckCard?.id },
        {
          onSuccess: () => {
            handleClose();
          },
        },
      );

      return;
    }
  };

  return (
    <Modal onClose={() => handleClose()} isOpen={modalCardState.isOpen}>
      <ModalMainTemplate>
        <ModalFormHeader
          withPadding
          textHeader={'Utwórz ' + (modalCardState.cardType || '')}
          textSubHeader={'W opowieści: ' + modalCardState.deck?.name}
        />
        <ModalMain>
          {modalCardState.deck?.id && (
            <FormCard
              cardType={modalCardState.cardType || DeckCardTypes.CONTENT}
              deckId={modalCardState.deck?.id}
              deckCardId={modalCardState.deckCard?.id}
              card={modalCardState.deckCard}
              fieldArray={fieldArray}
              form={form}
            />
          )}
        </ModalMain>
        <ModalBottomPanel>
          <PanelBottom
            isLoading={updateBaseCard.isLoading}
            handleSubmit={form.handleSubmit((data) => handleCreateDeckCard(data))}
          />
        </ModalBottomPanel>
      </ModalMainTemplate>
    </Modal>
  );
};
