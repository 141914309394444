import styled from 'styled-components';

export const StyledWrapper = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '32px',
}));

export const StyledWrapperQuestion = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
}));

export const StyledWrapperQuestionActions = styled('div')(({}) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const StyledWrapperFields = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

export const StyledWrapperOption = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
}));

export const StyledWrapperTextAndImage = styled('div')(({}) => ({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '5px',
}));

export const StyledQuestionLabel = styled('div')(({}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

export const StyledQuestionActions = styled('div')(({}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));
