import { useQuery } from '@tanstack/react-query';
import { getMembers } from '../methods/member';

export const useGetMembersForResource = (pathId?: string, deckId?: string) => {
  return useQuery(
    [
      'membersResource',
      {
        pathId,
        deckId,
      },
    ],
    () => getMembers(),
  );
};
