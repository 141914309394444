import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { ReactNode } from 'react';
import { StyledNavLink } from './Styles';

interface Props {
  text: ReactNode;
  to: string;
}

export const InnerLink = ({ text, to }: Props) => (
  <StyledNavLink to={to}>
    <Typography variant={TypographyTypes.linksMedium} as="span">
      {text}
    </Typography>
  </StyledNavLink>
);
