import styled from 'styled-components';

export const Wrapper = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  color: '#616161',

  gap: '16px',
  hr: {
    border: '1px solid #d6d3d3',
    width: '100%',
  },
}));
