import styled, { css } from 'styled-components';

interface StyledWrappperProps {
  isActive?: boolean;
}

export const StyledWrapper = styled.div<StyledWrappperProps>`
  display: flex;
  align-items: center;
`;
