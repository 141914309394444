export const API_URLS_PATHS = (BASE_URL: string) => ({
  PATHS: `${BASE_URL}`,
  GET_STORY_LOCATIONS: (storyId?: string) => `${BASE_URL}/${storyId}/locations`,
  PATH_DETAILS: (storyId: string) => `${BASE_URL}/${storyId}`,
  PATH_DETAILS_DECKS: (pathId: string) => `${BASE_URL}/${pathId}/decks`,
});

export const API_URLS_PATH_DECKS = (BASE_URL: string) => ({
  CREATE_LOCATION: `${BASE_URL}`,
});
