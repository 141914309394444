import { LogoutIcon } from 'components/atoms/icons/LogoutIcon';
import { SettingsIcon } from 'components/atoms/icons/SettingsIcon';
import { SupportHeartIcon } from 'components/atoms/icons/SupportHeartIcon';
import { useMenu } from 'components/molecules/Menu';
import { MenuItem } from 'components/molecules/MenuItem';
import { MenuAvatarButton } from 'components/organisms/Header/components/MenuAvatarButton';
import { useAuth } from 'modules/authentication/states/useAuth';
import { useInterface } from 'modules/global/state/useInterface';
import { useLanguage } from 'modules/language/useLanguage';
import { StyledWrapperMunuSubHeadInner } from './Styles';

export const MainBar = () => {
  const { Menu, setCurrentMenuPage, handleClose, handleClick } = useMenu();
  const { languageState } = useLanguage();
  const { user } = useAuth();
  const { handleLogout } = useAuth();
  const { updateResourceModal } = useInterface();

  return (
    <>
      <MenuAvatarButton
        onClick={(e) => {
          handleClick(e);
          setCurrentMenuPage(undefined);
        }}
        displayName={user?.displayName || ''}
        avatarProperties={{ color: 'red' }}
      />

      <Menu
        position="right"
        MenuPages={[
          {
            type: 'LANGUAGE',
            title: languageState.navigation.appLanguage,
            content: (
              <>
                {languageState.languages.map((item: any) => (
                  <MenuItem
                    key={item.iso}
                    // onClick={() => updateLanguage(item.iso)}
                    content={item.name}
                    isActive={false}
                  />
                ))}
              </>
            ),
          },
        ]}
      >
        {user && (
          <>
            <MenuItem
              isSubHead
              content={
                <StyledWrapperMunuSubHeadInner>
                  {user?.displayName}
                  {/* {user.currentSpace && (
                    <ButtonIcon
                      Icon={<ArrowIcon direction="right" />}
                      onClick={() => {
                        navigate(`/`);
                      }}
                    />
                  )} */}
                </StyledWrapperMunuSubHeadInner>
              }
              separator="bottom"
            />

            <MenuItem
              content="Ustawienia"
              Icon={<SettingsIcon />}
              onClick={() => handleClose()}
              linkProps={{
                to: '/settings',
                match: ['settings'],
              }}
            />
            {/* {user.currentSpace && (
              <MenuItem
                content="Wróć do przestrzeni osobistej"
                Icon={<ArrowIcon direction="left" />}
                onClick={() => {
                  navigate(`/`);
                }}
              />
            )} */}
            <MenuItem
              content="Wyloguj się"
              Icon={<LogoutIcon />}
              onClick={() => {
                handleClose();
                handleLogout();
              }}
            />
          </>
        )}
        {/* <MenuItem
          content="Utwórz przestrzeń"
          Icon={<PlusIcon />}
          onClick={(e) => {
            e.stopPropagation();
            updateResourceModal({ isOpen: true, slajdType: ResourceTypeForm.SPACE });
            handleClose();
          }}
        /> */}
        {/* <MenuItem
          isSubHead
          content={
            <StyledWrapperMunuSubHeadInner>
              <div>{user?.currentSpace?.name}</div>
            </StyledWrapperMunuSubHeadInner>
          }
          separator="bottom"
        />

        <MenuItem
          content="Utwórz przestrzeń"
          Icon={<PlusIcon />}
          onClick={(e) => {
            e.stopPropagation();
            updateResourceModal({ isOpen: true, slajdType: FormsCreateChoicerTypes.SPACE, name: '' });
            handleClose();
          }}
        />
        {user?.currentSpace && (
          <MenuItem
            content="Zaproś"
            Icon={<ShareIcon />}
            onClick={() => {
              updateShareModal({ isOpen: true, space: user?.currentSpace, name: user?.currentSpace?.name });
              handleClose();
            }}
          />
        )} */}

        <MenuItem
          Icon={<SupportHeartIcon size="16" />}
          content="Wesprzyj"
          onClick={() => window.open('https://patronite.pl/mr-wordkito', '_blank')}
        />
      </Menu>
    </>
  );
};
