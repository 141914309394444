import { LanguageAppType } from 'modules/language/models/languageApp';
import { Language } from 'shared-types-wordkito';

export const EN: LanguageAppType = {
  languages: [
    { name: 'Polish', iso: Language.pl },
    { name: 'English', iso: Language.en },
    { name: 'Espaniol', iso: Language.es },
  ],
  aside: {
    title: 'Kit',
  },
  navigation: {
    loginButton: 'Login',
    appLanguage: 'Language app',
    play: {
      title: 'Play',
    },
    add: {
      title: 'Create',
    },
    bases: {
      title: 'Decks',
    },
  },
};

export default EN;
