import { CircularProgress, circularProgressClasses, CircularProgressProps } from '@mui/material';
import { theme } from 'modules/global/theme/theme';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { Typography, TypographyTypes } from '../Typography';

interface Props extends CircularProgressProps {
  label?: ReactNode;
  size?: number;
  isFilled?: boolean;
}

const StyledWrapper = styled('div')<{ size?: number }>(({ size = 40 }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: size + 'px',
  width: size + 'px',
}));
const StyledWrapperLabel = styled('div')(({}) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

export const CircularLoader = (props: Props) => {
  return (
    <StyledWrapper size={props.size || 40}>
      {props.variant === 'determinate' && (
        <CircularProgress
          variant="determinate"
          sx={{
            color: 'lightGrey',
            borderRadius: '50%',
          }}
          size={props.size || 40}
          thickness={3}
          {...props}
          value={100}
        />
      )}
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: theme.colors.accentColor.default,
          animationDuration: '550ms',
          position: 'absolute',
          borderRadius: '50%',
          ...(props.isFilled && {
            backgroundColor: theme.colors.acceptColor.default,
          }),

          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
            color: props.isFilled ? theme.colors.acceptColor.default : theme.colors.accentColor.default,
          },
        }}
        size={props.size || 40}
        thickness={3}
        {...props}
      />
      <StyledWrapperLabel>
        {props.label && (
          <Typography
            as="label"
            color={props.isFilled ? 'white' : 'black'}
            variant={TypographyTypes.bodySmallRegular}
          >
            {props.label}
          </Typography>
        )}
      </StyledWrapperLabel>
    </StyledWrapper>
  );
};
