import { Icon } from '.';

export const GoogleIcon = () => (
  <Icon>
    <path
      className="Icon"
      d="M12.4314 11.4537V14.6379H17.1958C16.5728 16.5671 14.8797 17.9479 12.4314 17.9479C9.51594 17.9479 7.15256 15.6971 7.15256 12.9212C7.15256 10.1454 9.51594 7.89456 12.4314 7.89456C13.7422 7.89456 14.9392 8.35206 15.8623 9.10539L18.3246 6.76039C16.7697 5.41122 14.7012 4.58789 12.4314 4.58789C7.59794 4.58789 3.67969 8.31872 3.67969 12.9212C3.67969 17.5237 7.59794 21.2546 12.4314 21.2546C19.7779 21.2546 21.3993 14.7129 20.6792 11.4646L12.4314 11.4537Z"
    />
  </Icon>
);
