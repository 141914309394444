import { IUserState } from 'modules/user/states/useUser/models/userState';
import { ICurrentUser, Language } from 'shared-types-wordkito';
import { create } from 'zustand';

export const useUserStore = (initialUser?: ICurrentUser) =>
  create<IUserState>((set) => ({
    anonymousUser: undefined,
    user: initialUser,
    permissions: [],
    login: (payload) =>
      set(() => ({
        user: {
          id: payload.id,
          displayName: payload.displayName,
          language: Language.pl,
          ipUserId: '',
          ki: payload.ki,
        },
        permissions: payload.permissions || [],
      })),
    logout: () =>
      set(() => ({
        user: undefined,
        anonymousUser: undefined,
      })),
    updateDisplayName: (payload) =>
      set((state) => ({
        user: state.user ? { ...state.user, displayName: payload } : undefined,
      })),

    updateCurrentSpace: (payload) =>
      set((state) => ({
        user: state.user ? { ...state.user, currentSpace: payload } : undefined,
      })),

    updateBalance: (payload) => {
      set((state) => ({
        user: {
          ...state.user,
          ki: state.user?.ki ? state.user?.ki - payload.down.ki + payload.up.ki : 0,
        },
      }));
    },
    updatePermissions: (payload) => {
      if (payload.add.length > 0) {
        set((state) => ({
          permissions: [...state.permissions, ...payload.add],
        }));
      }
      if (payload.remove.length > 0) {
        set((state) => ({
          permissions: state.permissions.filter(
            (permission) =>
              !payload.remove.find(
                (p) =>
                  (p.deckId === permission.deckId || p.spaceId === permission.spaceId) &&
                  p.permissionName === permission.permissionName,
              ),
          ),
        }));
      }
    },
  }));
