import { theme } from 'modules/global/theme/theme';
import styled from 'styled-components';

export const StyledWrapper = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 auto',
  gap: '24px',
  borderRadius: '14px',
  backgroundColor: 'white',
  width: '100%',
  padding: '20px',
  [theme.media.up('laptop')]: {
    padding: '58px 129px',
    maxWidth: '622px',
  },

  [theme.media.up('extraLarge')]: {
    marginTop: '120px',
  },
}));

export const StyledWrapperForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const WrapperInputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;
export const WrapperLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
`;
export const WrapperSeparator = styled.div`
  margin-top: 48px;
  margin-bottom: 32px;
  width: 100%;
`;
