import { AutoLayout } from 'components/atoms/AutoLayout';
import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { Button } from 'components/atoms/buttons/Button';
import { ButtonIcon } from 'components/atoms/buttons/ButtonIcon';
import { Icon } from 'components/atoms/icons';
import PlusIcon from 'components/atoms/icons/PlusIcon';
import { ShareIcon } from 'components/atoms/icons/ShareIcon';
import { useCheckPermission } from 'modules/authorization/hooks/useCheckPermission';
import { useConnectDeckToDeckMutation } from 'modules/deck/interactions/mutations/path.mutations';
import { useGetDecksForPaths, useGetPath } from 'modules/deck/interactions/queries/path.queries';
import { PathPermissions } from 'modules/deck/models/permissions';
import { useAppParams } from 'modules/global/helpers/hooks/helpers';
import { useInterface } from 'modules/global/state/useInterface';
import { ResourceTypeForm } from 'modules/global/state/useInterface/types/actions';
import { PointsCounter } from 'modules/path/components/PointsCounter';
import { backgroundColorByStatus } from 'modules/path/helpers/colorsByStatus';
import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import ReactFlow, {
  Connection,
  Controls,
  Edge,
  MiniMap,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { PermissionName } from 'shared-types-wordkito';
import DeckNode from '../../modules/path/components/DeckNode';
import { getLayoutedElements } from '../../modules/path/helpers/getLayoytElements';
import { StyledTopPanel } from './Styles';

const nodeTypes = {
  deckNode: DeckNode,
};

const PathDetailsView = () => {
  const appParams = useAppParams();
  const { updateResourceModal, updateShareModal } = useInterface();
  const getPath = useGetPath(appParams.pathId || '');
  const getDecksForPath = useGetDecksForPaths(appParams.pathId || '');
  const connectDeckToDeckMutation = useConnectDeckToDeckMutation(appParams.pathId || '');
  const { fitView, getNode } = useReactFlow();
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const { checkPermission } = useCheckPermission({ path: getPath.data });

  const currentStoryId = appParams.searchParams.storyId;

  const canCreatePath = checkPermission(PermissionName.PathCreate);
  const canDeletePath = checkPermission(PermissionName.PathDelete);

  const pathPermissions: PathPermissions = {
    canCreateDeckCard: checkPermission(PermissionName.DeckCardCreate),
    canDeletePath: checkPermission(PermissionName.PathDelete),
    canCreateDeck: checkPermission(PermissionName.DeckCreate),
    canEditPath: checkPermission(PermissionName.PathEdit),
    canInvite: checkPermission(PermissionName.PathInvite),
    canInviteLink: checkPermission(PermissionName.PathInvite),
    canPlay: checkPermission(PermissionName.GamePlay),
    canReadPath: checkPermission(PermissionName.PathRead),
  };

  useLayoutEffect(() => {
    if (getDecksForPath.data && getPath.data && pathPermissions) {
      getLayoutedElements(getDecksForPath.data, getPath.data, pathPermissions).then(({ nodes, edges }) => {
        setNodes(nodes || []);
        setEdges(edges || []);
      });
    }
  }, [getDecksForPath.data, getPath.data]);
  useEffect(() => {
    const currentNode = getNode(currentStoryId || '');
    if (currentNode) {
    }
  }, [nodes, currentStoryId, fitView]);

  const onConnect = useCallback(
    (params: any) => {
      connectDeckToDeckMutation.mutate({
        nextDeckId: params.source,
        prevDeckId: params.target,
      });
    },
    [getDecksForPath.data],
  );

  const onEdgeUpdate = useCallback((oldEdge: Edge, newConnection: Connection) => {
    connectDeckToDeckMutation.mutate({
      nextDeckId: newConnection.source || '',
      prevDeckId: newConnection.target || '',
    });
    // setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const { maxPathPoints, currentUserGainedPoints } = useMemo(() => {
    return getDecksForPath.data
      ? getDecksForPath.data?.reduce(
          (acc, deck) => {
            return {
              maxPathPoints: acc.maxPathPoints + (deck.maxQuizPoints || 0),
              currentUserGainedPoints: acc.currentUserGainedPoints + (deck.currentUserGainedPoints || 0),
            };
          },
          { maxPathPoints: 0, currentUserGainedPoints: 0 },
        )
      : { maxPathPoints: 0, currentUserGainedPoints: 0 };
  }, [getDecksForPath.data]);

  return (
    <AutoLayout column>
      <StyledTopPanel>
        <AutoLayout
          maxWidth="800px"
          fullWidth
          alignVerticalInside="bottom"
          padding="10px 0px"
          justifyHorizontal="space-between"
          style={{
            margin: '0 auto',
          }}
        >
          <AutoLayout column gap={16}>
            <AutoLayout gap={16} alignVerticalInside="center">
              <AutoLayout gap={8} alignVerticalInside="center">
                <Icon name="path" size="30" />
                <Typography variant={TypographyTypes.headerH5}>{getPath.data?.name}</Typography>
              </AutoLayout>

              <AutoLayout gap={6}>
                {pathPermissions.canInvite && (
                  <ButtonIcon
                    Icon={<ShareIcon />}
                    onClick={() => {
                      updateShareModal({ isOpen: true, path: getPath.data });
                    }}
                  />
                )}
                {canDeletePath && <ButtonIcon Icon={<Icon name="delete" />} />}
              </AutoLayout>
            </AutoLayout>
            <PointsCounter
              currentPoints={currentUserGainedPoints}
              maxPoints={maxPathPoints}
              status={maxPathPoints === currentUserGainedPoints ? 'FINISHED' : 'DISABLED'}
            />
          </AutoLayout>
          {canCreatePath && (
            <Button
              leftIcon={<PlusIcon />}
              variant="outline"
              text={'Dodaj nowe zagadnienie'}
              onClick={() => {
                updateResourceModal({
                  isOpen: true,
                  slajdType: ResourceTypeForm.DECK,
                  addToPath: getPath.data?.id,
                });
              }}
            />
          )}
        </AutoLayout>
      </StyledTopPanel>
      <div
        style={{
          width: '100vw',
          height: '80vh',
        }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          fitView
          fitViewOptions={{
            duration: 800,
            padding: 30,
            nodes: [{ id: currentStoryId || '' }],
          }}
          proOptions={{
            hideAttribution: true,
          }}
          onEdgeUpdate={onEdgeUpdate}
          onEdgeUpdateEnd={(_, edge, type) => {
            console.log('onEdgeUpdateEnd', edge, type);
          }}
          onEdgesChange={(edges) => {
            console.log('onEdgesChange', edges);
          }}
          onConnect={onConnect}
          attributionPosition="bottom-left"
          defaultViewport={{
            zoom: 0.2,
            x: 0,
            y: 0,
          }}
        >
          <MiniMap
            nodeColor={(node) => {
              const status = node?.data?.status;

              return backgroundColorByStatus(status);
            }}
            nodeBorderRadius={5}
            zoomable
            pannable
          />
          <Controls />
        </ReactFlow>
      </div>
    </AutoLayout>
  );
};

export default PathDetailsView;
