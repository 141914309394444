import { Modal } from 'components/molecules/Modal';
import { ModalFormHeader } from 'components/molecules/Modal/components/ModalFormHeader';
import { ModalMainTemplate } from 'components/molecules/Modal/components/ModalMainTemplate';
import { FormMarket } from 'components/organisms/forms/FormMarket';
import { BottomPanel } from 'components/organisms/forms/FormMarket/BottomPanel';
import { useAuth } from 'modules/authentication/states/useAuth';
import { UpdateModalMarketPayload } from 'modules/global/state/useInterface/types/payloads';

import { useForm } from 'react-hook-form';

export interface Props {
  modalState: UpdateModalMarketPayload;
  handleClose: () => void;
}

export const ModalMarket = ({ handleClose, modalState }: Props) => {
  const { user } = useAuth();
  const form = useForm({
    mode: 'all',
    criteriaMode: 'all',

    defaultValues: {
      price: 0,
    },
  });

  const isExposeOnMarket = false;
  const currentTransaction = modalState.deck?.transactions?.find(
    (transaction) => transaction.buyerId === user?.id,
  );

  return (
    <Modal isOpen={modalState.isOpen} onClose={() => handleClose()}>
      <ModalMainTemplate>
        <ModalFormHeader
          textHeader={isExposeOnMarket ? 'Wystawiono na market' : 'Wystaw na market'}
          textSubHeader={modalState.deck?.name}
        />
        {modalState.deck && <FormMarket form={form} deck={modalState.deck} />}
        {modalState.deck?.id ? (
          <BottomPanel form={form} initialCurrentPrice={0} deckId={modalState.deck?.id} />
        ) : undefined}
      </ModalMainTemplate>
    </Modal>
  );
};
