import { theme } from 'modules/global/theme/theme';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledNavItemOff = styled(NavLink)<{ isActive?: boolean }>(({ isActive }) => ({
  textDecoration: 'none',
  border: '1px solid black',
  display: 'flex',
  color: 'black',
  borderRadius: '4px',
  padding: '2px',
  alignItems: 'center',
  justifyContent: 'center',
  ':hover': {
    cursor: 'pointer',
    color: theme.colors.accentColor.hover,
  },
  ...(isActive && {
    color: theme.colors.accentColor.default,
  }),
}));
