import { axiosPost } from 'modules/global/helpers/fetchApiMethods';

import { API_URLS } from 'modules/global/urls';
import { CreateDeckCardResponseDto, CreateGenerateDeckCardDto } from 'shared-types-wordkito';

export const generateContent = async (data: CreateGenerateDeckCardDto) => {
  const response = await axiosPost<{}, CreateDeckCardResponseDto>(
    API_URLS.GENERATE_CONTENT(data.targetDeckCardId),
    data,
  );

  return response.data;
};

export const generateQuizOption = async (data: {
  deckCardId: string;
  correctOptions: string[];
  incorrectOptions: string[];
  question: string;
}) => {
  const response = await axiosPost<{}, CreateDeckCardResponseDto>(
    API_URLS.GENERATE_QUIZ_OPTION(data.deckCardId),
    data,
  );

  return response.data;
};
