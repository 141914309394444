import { Button } from 'components/atoms/buttons/Button';
import { useCreateProduct } from 'modules/market/interactions/mutations';
import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

export interface Props {
  deckId: string;
  initialCurrentPrice?: number;
  form: UseFormReturn<{ price: number }, any>;
}

export const BottomPanel = ({ form, deckId, initialCurrentPrice }: Props) => {
  const createProductMutation = useCreateProduct();
  const price = form.watch('price');
  const [currentPrice, setCurrenPrice] = useState(initialCurrentPrice);

  const buttonText = () => {
    if (currentPrice == price && price) {
      return `Wystawiono`;
    }
    if (currentPrice && price && currentPrice !== price) {
      return (
        <p>
          Wystaw za <del>{currentPrice}</del> {price} ki
        </p>
      );
    }
    if (currentPrice && !price) {
      return (
        <p>
          Wystaw za <del>{currentPrice}</del> 0 ki
        </p>
      );
    }

    if (!currentPrice && (price || 0) >= 1) {
      return `Wystaw za ${price} ki`;
    }

    return 'Wystaw za darmo';
  };

  return (
    <Button
      text={buttonText()}
      isLoading={createProductMutation.isLoading}
      disabled={createProductMutation.isLoading || currentPrice == price || !form.formState.isValid}
      onClick={() => {
        createProductMutation.mutate(
          {
            deckId,
            price: price ? price : 0,
          },
          {
            onSuccess: (data) => {
              // setCurrenPrice(data.price);
            },
          },
        );
      }}
    />
  );
};
