interface Props {
  color?: string;
  size?: number;
}

export const LogoIcon = ({ color = '#4041DE', size = 39 }: Props) => (
  <>
    <svg width={39} height={size - 17} viewBox={`0 0 ${size} 22`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.35053 0.895508H6.29359C5.53041 0.896706 4.77496 1.04844 4.07051 1.34202C3.36606 1.6356 2.72643 2.06526 2.18827 2.6064C1.65011 3.14754 1.22399 3.78952 0.934294 4.49558C0.644601 5.20164 0.497035 5.95791 0.500045 6.72109V21.9463H6.27986V6.72109C6.31715 5.58092 6.61292 4.46412 7.14485 3.45496C7.67678 2.44579 8.43098 1.57063 9.35053 0.895508V0.895508Z"
        fill={color}
      />
      <path
        d="M18.2324 0.895508H13.8391C13.0766 0.897305 12.3218 1.04951 11.6182 1.34342C10.9145 1.63733 10.2757 2.06716 9.7384 2.60829C9.20109 3.14943 8.7758 3.79124 8.4869 4.49698C8.19799 5.20272 8.05114 5.95851 8.05475 6.72109V21.9463H15.1846V6.72109C15.2175 5.5825 15.509 4.46632 16.0371 3.45703C16.5651 2.44775 17.3158 1.57181 18.2324 0.895508V0.895508Z"
        fill={color}
      />
      <path
        d="M31.3964 0.895508H23.0218C22.7708 0.896012 22.5201 0.912828 22.2713 0.945847C19.4111 1.31195 16.9766 3.76482 16.9766 6.72109V21.9463H35.7941C37.4233 21.9463 38.5399 20.6238 38.5399 18.9946V0.895508H31.3964Z"
        fill={color}
      />
    </svg>
  </>
);
