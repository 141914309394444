import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'modules/global/helpers/fetchApiMethods';
import { API_URLS } from 'modules/global/urls';
import { CreateMemberDto, IMember, UpdateMemberDto } from 'shared-types-wordkito';

export const getMembers = async () => {
  const url = API_URLS.MEMBERS;

  const response = await axiosGet<IMember[]>(url);

  return response.data;
};

export const createMember = async (body: CreateMemberDto) => {
  const url = API_URLS.MEMBERS;

  const response = await axiosPost<CreateMemberDto, IMember>(url, body);

  return response.data;
};

export const updateMember = async ({ memberId, body }: { memberId: string; body: UpdateMemberDto }) => {
  const url = API_URLS.MEMBER_DETAILS(memberId);

  const response = await axiosPatch<UpdateMemberDto, IMember>(url, body);

  return response.data;
};

export const deleteMember = async (memberId: string) => {
  const response = await axiosDelete<undefined>(API_URLS.MEMBER_DETAILS(memberId));

  return response.data;
};
