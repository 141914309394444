import { CustomSvgIconProps, Icon } from '.';

const PlusIcon = ({ ...rest }: CustomSvgIconProps) => (
  <Icon {...rest}>
    <>
      <path
        className="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.92969 12.9202C4.92969 12.1772 5.53204 11.5748 6.27509 11.5748L18.2444 11.5748C18.9875 11.5748 19.5898 12.1772 19.5898 12.9202C19.5898 13.6633 18.9875 14.2656 18.2444 14.2656L6.27509 14.2656C5.53205 14.2656 4.92969 13.6633 4.92969 12.9202Z"
      />
      <path
        className="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1155 20.1856C11.3725 20.1856 10.7701 19.5833 10.7701 18.8402L10.7701 7.00068C10.7701 6.25763 11.3725 5.65527 12.1155 5.65527C12.8586 5.65527 13.4609 6.25763 13.4609 7.00067L13.4609 18.8402C13.4609 19.5833 12.8586 20.1856 12.1155 20.1856Z"
      />
    </>
  </Icon>
);

export default PlusIcon;
