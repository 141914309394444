import { CircularLoader } from 'components/atoms/CircularLoader';
import { MenuPage, MenuPages, MenuTab } from 'components/molecules/TabList';
import { MenuTabItem } from 'components/molecules/TabList/MenuTabItem';
import { IResourceScopes } from 'modules/authorization/models/scope';
import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { IMember, PermissionTemplate } from 'shared-types-wordkito';
import { StyledWrapper, StyledWrapperForms } from './Styled';
import { CodeForm } from './components/CodeForm';
import { InviteForm } from './components/InviteForm';
import { LinkForm } from './components/LinkForm';

interface Props {
  resource: IResourceScopes;
  members: IMember[];
  form: UseFormReturn<{
    permissionTemplate: PermissionTemplate | undefined;
    memberId: string;
  }>;
}

enum FormSharePagesTypes {
  INVITE = 'INVITE',
  LINK = 'LINK',
  CODE = 'CODE',
}

export const FormShare = ({ resource, form, members }: Props) => {
  const [pageType, setPageType] = useState(FormSharePagesTypes.INVITE);

  return (
    <StyledWrapper>
      <MenuTab>
        <MenuTabItem
          isActive={pageType === FormSharePagesTypes.INVITE}
          text="Zaproś"
          onClick={() => setPageType(FormSharePagesTypes.INVITE)}
        />
        {resource.deck && (
          <MenuTabItem
            isActive={pageType === FormSharePagesTypes.CODE}
            text="Kod na strone"
            onClick={() => setPageType(FormSharePagesTypes.CODE)}
          />
        )}
      </MenuTab>

      <StyledWrapperForms>
        {false ? (
          <CircularLoader size={25} />
        ) : (
          <MenuPages value={pageType}>
            <MenuPage value={FormSharePagesTypes.INVITE}>
              <InviteForm
                resource={{
                  deckId: resource.deck?.id,
                  spaceId: resource.space?.id,
                  userId: resource.user?.id,
                }}
                form={form}
                members={members}
              />
            </MenuPage>
            <MenuPage value={FormSharePagesTypes.LINK}>
              <LinkForm
                resource={{
                  deckId: resource.deck?.id,
                  spaceId: resource.space?.id,
                  userId: resource.user?.id,
                }}
              />
            </MenuPage>
            {resource.deck?.id ? (
              <MenuPage value={FormSharePagesTypes.CODE}>
                <CodeForm deckId={resource.deck?.id || ''} />
              </MenuPage>
            ) : (
              <></>
            )}
          </MenuPages>
        )}
      </StyledWrapperForms>
    </StyledWrapper>
  );
};
