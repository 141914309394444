import { URL_PARAM } from 'modules/global/urls';

export const API_URLS_AUTHORIZATION = (BASE_URL: string) => ({
  GET_SYSTEM_MEMBERS: BASE_URL + `/admin/members`,
});

export const API_URLS_MEMBERS = (BASE_URL: string) => ({
  CREATE_MEMBER: BASE_URL,
  MEMBERS: BASE_URL,
  MEMBER_DETAILS: (roleMemberId: string) => BASE_URL + URL_PARAM(roleMemberId),
});
