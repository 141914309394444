import React, { ReactNode } from 'react';
import { StyledWrapper } from './Styles';

export interface HeaderElementProps {
  primary?: boolean | undefined;
  secondary?: boolean | undefined;
  third?: boolean | undefined;
  children: ReactNode;
}

export const HeaderElement: React.FC<HeaderElementProps> = ({ children, primary, secondary, third }) => (
  <StyledWrapper primary={primary} secondary={secondary} third={third}>
    {children}
  </StyledWrapper>
);

export default HeaderElement;
