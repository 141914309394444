import { theme } from 'modules/global/theme/theme';
import styled, { css } from 'styled-components';

interface StyledTabPageProps {
  primary?: boolean;
  isActive: boolean | undefined;
}

export const Wrapper = styled.button<StyledTabPageProps>`
  background-color: #f4f3f3;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: ${theme.fonts.weights.semiBold600};
  font-family: ${theme.fonts.names.inter};
  color: #616161;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 4px 4px 0 0;
  display: flex;
  gap: 4px;

  ${({ isActive }) =>
    isActive &&
    css`
      background: #f4f5ff;
      border-right: none;
      box-shadow: inset 0px -2px 0px ${theme.colors.accentColor.default};
      color: ${theme.colors.accentColor.default};
    `}
`;
