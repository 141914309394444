import styled from 'styled-components';

export const StyledWrapper = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  height: '100%',
  width: '100%',
}));

export const StyledWrapperInputs = styled('div')(() => ({
  display: 'flex',
  flexBasis: '200',
  gap: '30px',
  '> :first-child': {
    flexBasis: '500%',
  },
}));
