import { ActionCodeSettings, GoogleAuthProvider, sendSignInLinkToEmail, signInWithPopup } from '@firebase/auth';
import { firebaseAuth } from 'modules/authentication/config';
import { APP_ROUTES } from 'modules/global/routes';
import { useUserStore } from 'modules/user/states/useUser';
import { LoginActionPayload } from 'modules/user/states/useUser/models/payloads';
import { useCallback, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext, AuthProviders } from './context';

function AuthProvider({ children, initialUser }: { children: React.ReactNode; initialUser?: LoginActionPayload }) {
  const {
    user,
    permissions,
    updateBalance,
    updateCurrentSpace,
    updateDisplayName,
    updatePermissions,
    anonymousUser,
    login,
    logout,
  } = useUserStore(initialUser)();
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const redirectUrl = params.get('redirectUrl');

  useEffect(() => {
    if (!initialUser) return;
    login(initialUser);

    if (redirectUrl) {
      navigate(redirectUrl, { replace: true });
    } else if (location.pathname.includes('login')) {
      navigate('/', { replace: true });
    }
  }, [initialUser]);

  const handleLogin = async ({
    provider,
    email,
    redirectUrl,
  }: {
    provider: AuthProviders;
    email?: string;
    redirectUrl?: string;
  }) => {
    if (provider === AuthProviders.GOOGLE) {
      const googleProvider = new GoogleAuthProvider();
      await signInWithPopup(firebaseAuth, googleProvider);
    }

    if (provider === AuthProviders.EMAIL && email) {
      const actionCodeSettings: ActionCodeSettings = {
        url: process.env.REACT_APP_URL + `/${APP_ROUTES.CONFIRM_EMAIL}` + `?redirectUrl=${redirectUrl || ''}`,
        handleCodeInApp: true,
      };
      sendSignInLinkToEmail(firebaseAuth, email, actionCodeSettings)
        .then(() => localStorage.setItem('emailForSignIn', email))
        .catch((e) => console.log(e.message));
    }
  };

  const handleLogout = useCallback(async () => {
    await firebaseAuth.signOut();
    await logout();
  }, [logout]);

  const value = {
    handleLogin,
    handleLogout,
    user,
    permissions,
    anonymousUser,
    login,
    logout,
    updateDisplayName,
    updateCurrentSpace,
    updateBalance,
    updatePermissions,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('Context mus be used within a <AuthProvider/>');
  }

  return context;
}

export { AuthProvider, useAuth };
