import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DeckQueryKeys } from 'modules/deck/constants/queryKeys';
import { useInterface } from 'modules/global/state/useInterface';
import { PeriodStatsTypes } from 'modules/global/state/useInterface/types/interfaceState';

import { IDeck } from 'shared-types-wordkito';

import { useAuth } from 'modules/authentication/states/useAuth';
import { createDeck, deleteBase, getDeck, getDecks, getDeckStats, updateDeck } from '../methods/deck';

export const useGetDeck = (deckId?: string) => {
  const result = useQuery(
    DeckQueryKeys.deck(deckId),
    () => {
      return getDeck(deckId as string);
    },
    {
      enabled: !!deckId,
    },
  );

  return result;
};

export const useGetDeckStats = (deckId?: string) => {
  const { interfaceState } = useInterface();
  const result = useQuery(
    DeckQueryKeys.deckGameStats(deckId),
    () => {
      return getDeckStats(deckId as string);
    },
    {
      enabled: !!deckId && interfaceState.stats.period !== PeriodStatsTypes.NO_STATS,
    },
  );

  return result;
};

export const useGetUserDecks = (userId?: string) => {
  const result = useQuery(DeckQueryKeys.currentUserDecks(undefined, userId), () => getDecks(userId as string), {
    enabled: !!userId,
  });

  return result;
};

export const useDeleteDeck = () => {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const result = useMutation(deleteBase, {
    onSuccess: (res, variables) => {
      queryClient.setQueriesData<IDeck[]>(DeckQueryKeys.currentUserDecks(undefined, user?.id), (prev) => {
        return prev?.filter((item) => item.id !== variables);
      });
    },
  });

  return result;
};

export const useCreateDeck = (pathId: string) => {
  const queryClient = useQueryClient();
  const result = useMutation(createDeck, {
    onSuccess: (res) => {
      queryClient.setQueryData<IDeck[]>(DeckQueryKeys.pathDecks(pathId), (prev) => {
        return [...(prev || []), res];
      });
    },
  });

  return result;
};

export const useUpdateDeck = (pathId: string) => {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const result = useMutation(updateDeck, {
    onSuccess: (res, variables) => {
      const deckToUpdate = {
        name: res.name,
        languageFrom: res.languageFrom,
      };
      queryClient.setQueriesData<IDeck[]>(DeckQueryKeys.pathDecks(pathId), (prev) => {
        return prev?.map((item) => {
          if (item.id === res.id) {
            return { ...item, ...res };
          }

          return item;
        });
      });
      queryClient.setQueryData<IDeck>(DeckQueryKeys.deck(res.id), (prev) => {
        return prev ? { ...prev, ...res } : undefined;
      });
    },
  });

  return result;
};
