import React, { LabelHTMLAttributes, ReactNode } from 'react';
import { StyledLabel } from './Styles';

interface Props extends LabelHTMLAttributes<HTMLLabelElement> {
  text: ReactNode;
  required?: boolean;
}

export const Label: React.FC<Props> = ({ text, required, htmlFor }) => (
  <StyledLabel required={required} htmlFor={htmlFor}>
    {text}
  </StyledLabel>
);

export default Label;
