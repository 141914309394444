import { InputText } from 'components/atoms/inputs/texts/InputText';
import { Modal } from 'components/molecules/Modal';
import { ModalBottomPanel } from 'components/molecules/Modal/components/ModalBottomPanel';
import { ModalFormHeader } from 'components/molecules/Modal/components/ModalFormHeader';
import { ModalMain } from 'components/molecules/Modal/components/ModalMain';
import { ModalMainTemplate } from 'components/molecules/Modal/components/ModalMainTemplate';
import { PanelBottom } from 'components/organisms/forms/FormCard/PanelBottom';
import { useGenerateDeckCard } from 'modules/deck/interactions/queries/deckCardGenerate';
import { UpdateModalGenerateCardPayload } from 'modules/global/state/useInterface/types/payloads';
import { useForm } from 'react-hook-form';
import { CreateGenerateDeckCardDto, DeckCardTypes } from 'shared-types-wordkito';

export interface Props {
  modalState: UpdateModalGenerateCardPayload;
  handleClose: () => void;
}

export const ModalGenerateCard = ({ modalState, handleClose }: Props) => {
  const generateDeckCard = useGenerateDeckCard(modalState.deck?.id);

  const defaultValues = {
    contextToGenerate: '',
  };

  const form = useForm<{
    contextToGenerate: string;
  }>({
    defaultValues,
  });

  const handleCreateDeckCard = (data: typeof defaultValues) => {
    const dataToSend: CreateGenerateDeckCardDto = {
      targetDeckCardId: modalState.targetDeckCardId || '',
      type: modalState.type || DeckCardTypes.QUIZ,
      quizType: 'CHOICE',
      contextToGenerate: data.contextToGenerate,
    };
    generateDeckCard.mutateAsync(dataToSend, {
      onSuccess: () => {
        handleClose();
      },
    });
  };

  return (
    <Modal onClose={() => handleClose()} isOpen={modalState.isOpen}>
      <ModalMainTemplate>
        <ModalFormHeader textHeader={'Wygeneruj ' + (modalState.type === 'CONTENT' ? 'treść' : 'quiz')} />

        <ModalMain>
          {/*Opcje na przyszłosć: Wybierz poprzednią treść: boolean , typ quizu: TRUE | FALSE | wybór | luka |   */}
          <InputText
            multiline
            minRows={3}
            control={form.control}
            name="contextToGenerate"
            label="O czym ma być quiz?"
          />
        </ModalMain>
        <ModalBottomPanel>
          <PanelBottom
            isLoading={generateDeckCard.isLoading}
            handleSubmit={form.handleSubmit(handleCreateDeckCard)}
          />
        </ModalBottomPanel>
      </ModalMainTemplate>
    </Modal>
  );
};
