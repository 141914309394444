import { ViewTemplate } from 'components/template/ViewTemplate';
import { useGameSocket } from 'modules/game/interactions/sockets/useGameSocket';
import { APP_ROUTES } from 'modules/global/routes';
import { useCurrentUserSocket } from 'modules/user/interactions/sockets/useCurrentUserSocket';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router';
import ConfirmEmailPage from 'views/ConfirmEmailView';
import LoginPage from 'views/LoginView';
import MarketPlaceView from 'views/MarketPlaceView';
import MembersView from 'views/MembersView';
import PathDetailsView from 'views/PathDetailsView';
import UserSettings from 'views/SettingViews/UserSettings';
import { PublicRoute } from './PublicRoute';
import { RequireAuthRoute } from './RequireAuthRoute';

const DeckDetails = lazy(() => import('views/DeckDetailsView'));
const UserProfile = lazy(() => import('views/UserProfile'));
const ProtectRoute = lazy(() => import('apps/App/AppRoutes/ProtectRoute'));
const PathsView = lazy(() => import('views/PathsView'));

const AppRoutes = () => {
  useCurrentUserSocket({
    nameSpace: '/currentUser',
  });

  useGameSocket({
    // gameId: gameId,
  });

  return (
    <Routes>
      <Route>
        <Route path="" element={<ViewTemplate />}>
          <Route
            index
            element={
              <Suspense fallback={false}>
                <MarketPlaceView />
              </Suspense>
            }
          />
          <Route
            path={APP_ROUTES.MEMBERS}
            element={
              <Suspense fallback={false}>
                <RequireAuthRoute>
                  <MembersView />
                </RequireAuthRoute>
              </Suspense>
            }
          />
        </Route>
        <Route path={APP_ROUTES.USERS} element={<ViewTemplate />}>
          <Route
            path={APP_ROUTES.USER_DETAILS}
            element={
              <Suspense fallback={false}>
                <RequireAuthRoute>
                  <UserProfile />
                </RequireAuthRoute>
              </Suspense>
            }
          />
        </Route>
        <Route path={APP_ROUTES.PATHS} element={<ViewTemplate main />}>
          <Route
            path={APP_ROUTES.PATHS_DETAILS}
            element={
              <Suspense fallback={false}>
                <PathDetailsView />
              </Suspense>
            }
          />
        </Route>
        <Route element={<ViewTemplate />} path={APP_ROUTES.STORIES}>
          <Route
            index
            element={
              // <RequireAuthRoute>
              <Suspense fallback={false}>
                <PathsView />
              </Suspense>
              // </RequireAuthRoute>
            }
          />
          <Route
            path={APP_ROUTES.STORIES_DETAILS}
            // loader={() => {
            //   return <div>dsadsa</div>;
            // }}
            element={
              // <RequireAuthRoute>
              <div>dsds</div>
              // <Suspense fallback={false}>
              //   <StoriesView />
              // </Suspense>
              // </RequireAuthRoute>
            }
          />
        </Route>
        <Route path={APP_ROUTES.DECKS} element={<ViewTemplate />}>
          <Route path={APP_ROUTES.DECKS_DETAILS}>
            <Route
              index
              element={
                <Suspense fallback={false}>
                  {/* <ProtectRoute
                    permissionToCheck={PermissionName.DeckRead}
                    options={{
                      throwError: false,
                      checkIfPublic: true,
                    }}
                  > */}
                  <DeckDetails />
                  {/* </ProtectRoute> */}
                </Suspense>
              }
            />
          </Route>
        </Route>

        <Route
          path={APP_ROUTES.PROFILE}
          element={
            <RequireAuthRoute>
              <Suspense fallback={false}>
                <UserProfile />
              </Suspense>
            </RequireAuthRoute>
          }
        />
        <Route path={APP_ROUTES.SETTINGS}>
          <Route
            index
            element={
              <RequireAuthRoute>
                <Suspense fallback={false}>
                  <UserSettings />
                </Suspense>
              </RequireAuthRoute>
            }
          />
        </Route>
        <Route path={APP_ROUTES.LOGIN}>
          <Route
            index
            element={
              <PublicRoute>
                <Suspense fallback={false}>
                  <LoginPage />
                </Suspense>
              </PublicRoute>
            }
          />
        </Route>
        <Route path={APP_ROUTES.CONFIRM_EMAIL}>
          <Route
            index
            element={
              <Suspense fallback={false}>
                <ConfirmEmailPage />
              </Suspense>
            }
          />
        </Route>

        <Route path="*" element={<h1>404</h1>} />
        <Route path={APP_ROUTES.ABOUT_US} element={<h1>O nas jest w trakcie tworzenia</h1>} />
        <Route path={APP_ROUTES.TERMS_OF_USE} element={<h1>Regulamin jest w trakcie tworzenia</h1>} />
        <Route path={APP_ROUTES.PRIVACY_POLICY} element={<h1>Polityka prywatności jest w trakcie tworzenia</h1>} />
        <Route path={APP_ROUTES.FAQ} element={<h1>FAQ jest w trakcie tworzenia</h1>} />
        <Route path={APP_ROUTES.CONTACT} element={<h1>Contact jest w trakcie tworzenia</h1>} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
