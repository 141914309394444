export type PlaceTypes = 'GLOBAL' | 'BASE' | 'CARD' | 'BASE_DETAILS' | 'BASES';
export enum ResourceTypeForm {
  'NONE' = 'NONE',
  'DECK' = 'DECK',
  'SPACE' = 'SPACE',
  'PATH' = 'PATH',
}

export interface IPlaceTypes {
  BASE: 'BASE';
  BASES: 'BASES';
  BASEDETAILS: 'BASE_DETAILS';
  KIT: 'KIT';
  MODAL: 'MODAL';
}

export enum PlaceTypesEnum {
  BASE = 'BASE',
  BASES = 'BASES',
  BASEDETAILS = 'BASE_DETAILS',
  KIT = 'KIT',
  MODAL = 'MODAL',
}
