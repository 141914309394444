import { ResourceTypeChoicer } from 'components/atoms/ResourceTypeChoicer';
import { Modal } from 'components/molecules/Modal';
import { FormDeck } from 'components/organisms/forms/FormDeck';
import { FormPath } from 'components/organisms/forms/FormPath';
import { FormSpace } from 'components/organisms/forms/FormSpace';
import { useInterface } from 'modules/global/state/useInterface';
import { ResourceTypeForm } from 'modules/global/state/useInterface/types/actions';

export interface Props {}

export const ModalResource = ({}: Props) => {
  const { interfaceState, updateResourceModal } = useInterface();

  const handleClose = () => {
    updateResourceModal({
      isOpen: false,
      slajdType: undefined,
      path: undefined,
      space: undefined,
      deck: undefined,
    });
  };

  const handleChoiceResource = (slajdType?: ResourceTypeForm) => {
    updateResourceModal({
      ...interfaceState.modalResource,
      slajdType,
    });
  };

  return (
    <Modal onClose={() => handleClose()} isOpen={interfaceState.modalResource.isOpen}>
      {!interfaceState.modalResource.slajdType && (
        <ResourceTypeChoicer handleChoiceFormBaseOrCard={handleChoiceResource} />
      )}

      {interfaceState.modalResource.slajdType === ResourceTypeForm.DECK && (
        <FormDeck
          handleClose={handleClose}
          deck={interfaceState.modalResource.deck}
          sourceDeckId={interfaceState.modalResource.sourceDeckId}
          handleBack={handleClose}
          pathToAdd={interfaceState.modalResource.addToPath}
        />
      )}
      {interfaceState.modalResource.slajdType === ResourceTypeForm.PATH && (
        <FormPath
          handleClose={handleClose}
          path={interfaceState.modalResource.path}
          initialName={interfaceState.modalResource?.initialData?.name}
          initialDifficultyLevel={interfaceState.modalResource?.initialData?.difficultyLevel || 'beginner'}
        />
      )}

      {interfaceState.modalResource.slajdType === ResourceTypeForm.SPACE && (
        <FormSpace handleClose={handleClose} handleBack={() => null} />
      )}
    </Modal>
  );
};
