import { ButtonChoicer } from 'components/atoms/buttons/ButtonChoicer';
import { CardsIcon } from 'components/atoms/icons/CardsIcon';
import { SpaceIcon } from 'components/atoms/icons/SpaceIcon';
import { ResourceTypeForm } from 'modules/global/state/useInterface/types/actions';

import { StyledWrapper } from './Styles';

interface Props {
  handleChoiceFormBaseOrCard: (value: ResourceTypeForm) => void;
}

export const ResourceTypeChoicer = ({ handleChoiceFormBaseOrCard }: Props): JSX.Element => (
  <StyledWrapper>
    <ButtonChoicer
      text="Przestrzeń"
      description="Miejsce dla zespołów i projektów"
      onClick={() => handleChoiceFormBaseOrCard(ResourceTypeForm.SPACE)}
      icon={<SpaceIcon />}
    />
    <ButtonChoicer
      text="Talia"
      description="Miejsce na Twoje karty"
      onClick={() => handleChoiceFormBaseOrCard(ResourceTypeForm.DECK)}
      icon={<CardsIcon />}
    />
  </StyledWrapper>
);
