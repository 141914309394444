import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { theme } from 'modules/global/theme/theme';
import React, { cloneElement } from 'react';
import { StyledButton, StyledWrapperIconTitle } from './Styles';

export interface Props {
  text: string;
  description: string;
  onClick: (e: any) => void;
  icon?: React.ReactElement;
}

export const ButtonChoicer = ({ onClick, text, icon, description }: Props) => {
  const modifiedIcon = icon
    ? cloneElement(icon, { color: theme.colors.accentColor.default, size: '32' })
    : undefined;

  return (
    <StyledButton onClick={onClick}>
      <StyledWrapperIconTitle>
        {modifiedIcon}
        <Typography variant={TypographyTypes.titleLargeSemiBold}>{text}</Typography>
      </StyledWrapperIconTitle>
      <Typography variant={TypographyTypes.bodySmallRegular}>{description}</Typography>
    </StyledButton>
  );
};
