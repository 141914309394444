import styled from 'styled-components';

export const StyledWrapper = styled('div')(({}) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '16px',
}));

export const StyledWrapperEndAdorment = styled('div')(({}) => ({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '5px',
}));
