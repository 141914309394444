import { ButtonIcon } from 'components/atoms/buttons/ButtonIcon';
import { Icon } from 'components/atoms/icons';
import { AcceptIcon } from 'components/atoms/icons/AcceptIcon';
import { useDeleteImage } from 'modules/file/interactions/mutations';
import { useGetImages } from 'modules/file/interactions/queries';
import { IFile } from 'shared-types-wordkito';
import { StyledImageList, StyledImageListItemBar, StyledWrapperButtons, StyledWrapperImageItem } from './Styled';

interface Props {
  handleChooseImage: (image: IFile) => void;
}

export const ImageListForm = ({ handleChooseImage }: Props) => {
  const deleteImageMutation = useDeleteImage();
  const getImagesQuery = useGetImages();

  return (
    <StyledImageList>
      <>
        {getImagesQuery.data?.map((item) => (
          <StyledWrapperImageItem key={item.id}>
            <img src={`${item.url}`} srcSet={`${item.url}`} alt={''} loading="lazy" />
            <StyledImageListItemBar>
              <StyledWrapperButtons>
                <ButtonIcon
                  color="white"
                  Icon={<Icon name="delete" />}
                  onClick={() => deleteImageMutation.mutate(item.id)}
                />
                <ButtonIcon
                  color="white"
                  Icon={<AcceptIcon isCorrect />}
                  onClick={() => handleChooseImage(item)}
                />
              </StyledWrapperButtons>
            </StyledImageListItemBar>
          </StyledWrapperImageItem>
        ))}
      </>
    </StyledImageList>
  );
};
