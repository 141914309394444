import { URL_PARAM } from 'modules/global/urls';

export const API_URLS_USER = (BASE_URL: string) => ({
  GET_CHECK_EMAIL_EXISTS: (email: string) => `${BASE_URL}/check-user-email-exist/?email=${email}`,
  USER_DECKS: (userId: string) => BASE_URL + URL_PARAM(userId) + URL_PARAM('decks'),
});

export const API_URLS_CURRENT_USER = (BASE_URL: string) => ({
  CURRENT_USER: BASE_URL,
  CURRENT_USER_DECKS: BASE_URL + URL_PARAM('my-decks'),
  CURRENT_USER_SHARED_DECKS: BASE_URL + URL_PARAM('shared-decks'),
  CURRENT_USER_BOUGHT_DECKS: `${BASE_URL}/bought-decks`,
  CURRENT_USER_LOGIN: `${BASE_URL}/login`, // ZAMIENIC NA CURRENT_USER
  CURRENT_USER__SPACES: BASE_URL + URL_PARAM('spaces'),
  GET_SHARED_SPACES: `${BASE_URL}/get-shared-spaces`,
  CURRENT_USER_DISPLAY_NAME: BASE_URL + URL_PARAM('displayName'),
  PATCH_CURRENT_SPACE: `${BASE_URL}/change-current-space`, // ZAMIENIC NA   CURRENT_USER__SPACES
  CURRENT_USER_LANGUAGE: `${BASE_URL}/updateLanguage`,
  CURRENT_USER_LANGUAGES_TO_LEARN: `${BASE_URL}/updateLanguagesToLearn`,
});
