import { Button } from 'components/atoms/buttons/Button';

interface Props {
  handleSubmit: () => void;
  isLoading: boolean;
}

export const PanelBottom = ({ isLoading, handleSubmit }: Props) => {
  return (
    <>
      <Button
        onClick={handleSubmit}
        type="button"
        disabled={isLoading}
        text={isLoading ? 'Zatwierdzam' : 'Zatwierdź'}
      />
    </>
  );
};
