import styled from 'styled-components';
import { ButtonIcon } from '../buttons/ButtonIcon';

export const StyledWrapper = styled('div')(({}) => ({
  position: 'relative',
  height: '68px',
  minWidth: '68px',
  border: '1px solid grey',
  borderRadius: '8px',
  overflow: 'hidden',
  backgroundColor: 'white',
  [`:hover ${StyledImage}`]: {
    opacity: 0.5,
    transition: 'opacity 0.2s ease-in-out',
  },
  [`:hover ${StyledButton}`]: {
    opacity: 1,
    transition: 'opacity 0.2s ease-in-out',
  },
}));
export const StyledImage = styled('img')(({}) => ({
  height: '68px',
  width: '68px',
  border: 'none',
  outline: 'none',
}));

export const StyledButton = styled(ButtonIcon)(({}) => ({
  opacity: 0,
  position: 'absolute',
  top: '50%',
  left: '50%',
  translate: '-50% -50%',
  zIndex: 1,
}));
