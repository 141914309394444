import { API_URLS_AUTHENTICATION } from 'modules/authentication/constants/urls';
import { API_URLS_AUTHORIZATION, API_URLS_MEMBERS } from 'modules/authorization/constants/urls';
import { API_URLS_CARDS, API_URLS_USER_CARDS } from 'modules/card/constants/urls';
import { API_URLS_DECK, API_URLS_DECK_CARD, API_URLS_PATH } from 'modules/deck/constants/urls';
import { FILE_URLS } from 'modules/file/urls';
import { API_URLS_GAME } from 'modules/game/constants/urls';
import { API_URLS_MARKET, API_URLS_TRANSACTIONS } from 'modules/market/constants/urls';
import { API_URLS_PATHS, API_URLS_PATH_DECKS } from 'modules/path/interactions/urls';
import { API_URLS_SPACE } from 'modules/space/constants/urls';
import { API_URLS_CURRENT_USER, API_URLS_USER } from 'modules/user/constants/urls';
import { API_URLS_WIDGETS } from 'modules/widgets/urls';

export const BASE_URL = process.env.REACT_APP_API_URL || undefined;
export const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_API_URL;

export const URL_PARAM = (param?: string | number) => `/${param}`;
export const URL_QUERY_STRING = (queryStringType?: string, queryStringValue?: string) =>
  !queryStringType || !queryStringValue ? '' : `?${queryStringType}=${queryStringValue}`;

export const API_URLS = {
  //
  ...API_URLS_AUTHORIZATION(BASE_URL + '/authorization'),
  ...API_URLS_MEMBERS(BASE_URL + '/members'),
  // ...API_URLS_MEMBERS_RESOURCE(BASE_URL + '/memberResources'),
  //
  ...API_URLS_AUTHENTICATION(BASE_URL + '/authentication'),
  ...API_URLS_USER(BASE_URL + '/users'),
  ...API_URLS_CURRENT_USER(BASE_URL + '/currentUser'),
  //
  ...API_URLS_DECK(BASE_URL + `/decks`),
  ...API_URLS_PATH(BASE_URL + `/paths`),
  ...API_URLS_DECK_CARD(BASE_URL + '/deckCards'),
  //
  ...API_URLS_CARDS(BASE_URL + '/cards'),
  ...API_URLS_USER_CARDS(BASE_URL + `/userCards`),
  ...API_URLS_GAME(BASE_URL + '/games'),
  ...API_URLS_SPACE(BASE_URL + '/spaces'),
  ...API_URLS_PATHS(BASE_URL + '/paths'),
  ...API_URLS_WIDGETS(BASE_URL + '/widgets'),
  ...API_URLS_PATH_DECKS(BASE_URL + '/locations'),
  //
  ...FILE_URLS(BASE_URL + '/files'),
  //
  ...API_URLS_TRANSACTIONS(BASE_URL + '/transactions'),
  ...API_URLS_MARKET(BASE_URL + '/market'),

  //
};
