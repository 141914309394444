import { FormDeckCardDefaultValues } from 'modules/card/models/formCard';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { DeckCardTypes, IDeckCard } from 'shared-types-wordkito';
import { Content } from './Main/Content';
import { Quiz } from './Main/Quiz';

interface Props {
  deckCardId: string | undefined;
  card?: IDeckCard;
  deckId: string;
  form: UseFormReturn<FormDeckCardDefaultValues>;
  fieldArray: UseFieldArrayReturn<FormDeckCardDefaultValues, 'options', 'id'>;
  cardType: DeckCardTypes;
}

export const FormCard = (props: Props) => {
  if (props.cardType === DeckCardTypes.QUIZ) {
    return <Quiz {...props} />;
  }

  if (props.cardType === DeckCardTypes.CONTENT) {
    return <Content {...props} />;
  }

  return null;
};
