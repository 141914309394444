import { PopperUnstyledProps, SelectUnstyled, SelectUnstyledOwnProps, SelectUnstyledProps } from '@mui/base';
import { HelperText } from 'components/atoms/texts/HelperText';
import { Label } from 'components/atoms/texts/Label';
import { theme } from 'modules/global/theme/theme';
import { cloneElement, ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Popper } from './Popper';
import { StyledInputSelect, StyledOption, StyledWrapper, WrapperOptions } from './Styles';

export interface Props extends SelectUnstyledProps<any> {
  label?: string;
  variant?: 'ghost' | 'normal' | 'compact';
  defaultValue?: string | number | Array<string | number>;
  name: string;
  withArrow?: boolean;
  fullWidth?: boolean;
  menuPlacement?: Pick<PopperUnstyledProps, 'placement'>['placement'];
  helperText?: string;
  options: Array<{
    Icon?: any;
    value: string | number | undefined;
    text: string;
    disabled?: boolean;
    Component?: ReactNode;
  }>;
  control: Control<any>;
}

export const Select = ({
  options,
  control,
  helperText,
  defaultValue,
  label,
  menuPlacement,
  name,
  withArrow = true,
  variant = 'normal',
  onChange,
}: Props) => {
  const components: SelectUnstyledOwnProps<any>['slots'] = {
    root: StyledInputSelect,
    popper: Popper,
    listbox: WrapperOptions,
  };

  return (
    <StyledWrapper>
      {label && <Label text={label} />}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <SelectUnstyled
              slots={components}
              slotProps={{
                popper: {
                  // ...menuPlacement,
                },
                root: {
                  ...field,
                  ...{
                    variant: variant,
                    withArrow: withArrow,
                  },
                },
              }}
              onChange={(e, value) => {
                field.onChange(value);
                onChange && onChange(e, value);
              }}
              name={field.name}
              defaultValue={defaultValue}
              ref={field.ref}
              value={field.value}
              onBlur={field.onBlur}
            >
              {options.map((option) => {
                const OptionIcon =
                  option?.Icon &&
                  cloneElement(option.Icon, {
                    size: '25',
                    colorHover: theme.colors.accentColor.default,
                  });

                return (
                  <StyledOption key={option.value} value={option.value}>
                    <>
                      {OptionIcon}
                      {option.text}
                    </>
                  </StyledOption>
                );
              })}
            </SelectUnstyled>
          );
        }}
      />

      <HelperText text={helperText} />
    </StyledWrapper>
  );
};

Select.displayName = 'Select';
