import styled from 'styled-components';

export const StyledWrapper = styled('div')(({}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#F8F8F8',
  borderRadius: 8,
  padding: '4px 16px',
}));
