import { axiosDelete, axiosGet, axiosPost } from 'modules/global/helpers/fetchApiMethods';
import { API_URLS } from 'modules/global/urls';
import { IFile } from 'shared-types-wordkito';

export const createImage = async (data: FormData) => {
  const response = await axiosPost<FormData, IFile>(API_URLS.CREATE_IMAGE, data, {
    'Content-Type': 'multipart/form-data',
  });

  return response.data;
};

export const getImages = async () => {
  const response = await axiosGet<IFile[]>(API_URLS.GET_IMAGES);

  return response.data;
};

export const deleteImage = async (imageId: string) => {
  const response = await axiosDelete<IFile>(API_URLS.DELETE_IMAGE(imageId));

  return response.data;
};
