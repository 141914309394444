import { ArrowIcon } from 'components/atoms/icons/ArrowIcon';
import React, { ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledNavItem, StyledWrapper, WrapperNestedItems } from './Styles';

interface Props {
  Component?: JSX.Element;
  content?: ReactNode;
  Icon?: JSX.Element;
  isActive?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  separator?: 'top' | 'bottom';
  isSubHead?: boolean;
  linkProps?: {
    match?: string[];
    to?: string;
    stateFrom?: {
      text: string;
      link: string;
    };
  };
  expandedMenuProps?: {
    ExpandedItems?: JSX.Element;
  };
  nestedPageProps?: {
    isProvideToNestedPage?: boolean;
  };
}

export const MenuItem: React.FC<Props> = ({
  content: text,
  separator,
  Icon,
  isActive,
  Component,
  onClick,

  isSubHead,
  linkProps,
  expandedMenuProps,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isMatch =
    linkProps?.match && !!linkProps.match.find((item) => location.pathname.replace('/', '') === item);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (onClick) {
      onClick(e);
    }

    if (linkProps?.to) {
      navigate(linkProps.to, { state: { from: linkProps.stateFrom } });
    }

    setIsOpen((prev) => !prev);
  };

  const IconCloned = Icon && React.cloneElement(Icon, { size: '25' });

  return (
    <StyledWrapper separator={separator} isOpen={isOpen && !!expandedMenuProps?.ExpandedItems}>
      <StyledNavItem isSubHead={isSubHead} isActive={isMatch || isActive} onClick={(e) => handleClick(e)}>
        {(text || IconCloned) && IconCloned}
        {text || Component}
        {!!expandedMenuProps?.ExpandedItems && <ArrowIcon direction={isOpen ? 'up' : 'down'} />}
      </StyledNavItem>
      <WrapperNestedItems>{isOpen && expandedMenuProps?.ExpandedItems}</WrapperNestedItems>
    </StyledWrapper>
  );
};
