import { useTheme } from 'modules/global/theme/useTheme';
import { useLayoutEffect } from 'react';
import AppRoutes from './AppRoutes';

const App = () => {
  const { setInitialTheme } = useTheme();

  useLayoutEffect(() => {
    setInitialTheme();
  }, []);

  return <AppRoutes />;
};

export default App;
