import styled from 'styled-components';

export const StyledWrapper = styled('div')<{ isAbsolute?: boolean }>(({ isAbsolute }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '14px',
  padding: '24px',
  width: '100%',

  height: '100%',
  overflow: 'auto',
  ...(isAbsolute && {
    paddingBottom: '60px',
  }),
}));
