import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IFile } from 'shared-types-wordkito';
import { createImage, deleteImage } from '../methods';

export const useCreateImage = () => {
  const queryClient = useQueryClient();

  return useMutation(createImage, {
    onSuccess: (res) => {
      queryClient.setQueriesData<IFile[]>(['images'], (prev) => {
        return [res, ...(prev || [])];
      });
    },
  });
};

export const useDeleteImage = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteImage, {
    onSuccess: (res) => {
      queryClient.setQueriesData<IFile[]>(['images'], (prev) => {
        return prev?.filter((item) => item.id !== res.id);
      });
    },
  });
};
