import { CustomSvgIconProps, Icon } from '.';

export const LogoutIcon = (props: CustomSvgIconProps) => (
  <Icon {...props}>
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2539 5.94164C11.2539 6.38402 10.8933 6.7365 10.4559 6.80312C9.99971 6.87263 9.55198 7.00168 9.12317 7.18836C8.44627 7.48304 7.82862 7.91609 7.30656 8.46479C6.78441 9.01357 6.36828 9.66717 6.08372 10.3892C5.79915 11.1113 5.65224 11.8865 5.65224 12.6701C5.65224 13.4538 5.79915 14.229 6.08372 14.9511C6.36828 15.6731 6.78441 16.3267 7.30656 16.8755C7.82862 17.4242 8.44627 17.8572 9.12316 18.1519C9.55198 18.3386 9.99971 18.4677 10.4559 18.5372C10.8933 18.6038 11.2539 18.9563 11.2539 19.3986C11.2539 19.841 10.8941 20.2044 10.4547 20.1532C9.77819 20.0742 9.11488 19.8953 8.48484 19.621C7.6083 19.2394 6.81447 18.6812 6.14759 17.9803C5.48079 17.2795 4.9538 16.4496 4.59491 15.539C4.23603 14.6284 4.05176 13.6537 4.05176 12.6701C4.05176 11.6866 4.23603 10.7119 4.59491 9.80131C4.9538 8.89066 5.48079 8.06077 6.14759 7.35995C6.81447 6.65905 7.6083 6.10086 8.48484 5.71927C9.11488 5.44498 9.77819 5.26607 10.4547 5.18712C10.8941 5.13583 11.2539 5.49925 11.2539 5.94164Z"
      />
      <path d="M10.9249 13.3213L18.2384 13.3213L15.0432 16.5165C14.7879 16.7718 14.7879 17.1908 15.0432 17.4462C15.1038 17.5069 15.1757 17.5551 15.2549 17.5879C15.3341 17.6208 15.4191 17.6377 15.5048 17.6377C15.5906 17.6377 15.6755 17.6208 15.7547 17.5879C15.8339 17.5551 15.9058 17.5069 15.9664 17.4462L20.2811 13.1315C20.5365 12.8761 20.5365 12.4636 20.2811 12.2083L15.9664 7.89353C15.7111 7.63818 15.2986 7.63818 15.0432 7.89353C14.7879 8.14888 14.7879 8.56137 15.0432 8.81671L18.2384 12.0118L10.9249 12.0118C10.5648 12.0118 10.2702 12.3065 10.2702 12.6666C10.2702 13.0267 10.5648 13.3213 10.9249 13.3213Z" />
    </>
  </Icon>
);
