import { ClickAwayListener, MenuUnstyled } from '@mui/base';
import { ArrowIcon } from 'components/atoms/icons/ArrowIcon';
import { ReactNode, useCallback, useState } from 'react';
import { BackButton, Popper, StyledListbox, StyledWrapperMenuPageWithHeader } from './Styles';

export interface MenuProps {
  anchorMenuGap?: string;
  position?: 'left' | 'right';
  variant?: 'normal' | 'compact';
  children?: ReactNode;
  MenuPages?: { type: string; title: string; content: ReactNode }[];
}

export const useMenu = () => {
  const [currentMenuPage, setCurrentMenuPage] = useState<undefined | string>(undefined);
  const [isOpened, setIsOpened] = useState<HTMLElement | null>(null);

  const handleClose = () => {
    setIsOpened(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpened((prevState) => (prevState ? null : event.currentTarget));
  };

  const Menu = useCallback(
    ({ children, MenuPages }: MenuProps) => {
      const pageToDisplay: { type: string; title: string; content: ReactNode } | undefined =
        MenuPages && MenuPages?.find((item) => item.type === currentMenuPage);

      return (
        <ClickAwayListener
          onClickAway={(e) => {
            e.stopPropagation();
            handleClose();
          }}
        >
          <MenuUnstyled
            slots={{
              root: Popper,
              listbox: StyledListbox,
            }}
            slotProps={{
              root: {
                popperOptions: {
                  placement: 'bottom-end',
                },
              },
            }}
            open={!!isOpened}
            anchorEl={isOpened}
          >
            {pageToDisplay ? (
              <StyledWrapperMenuPageWithHeader>
                <BackButton
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentMenuPage(undefined);
                  }}
                >
                  <ArrowIcon direction="left" />
                  {pageToDisplay.title}
                </BackButton>
                {pageToDisplay.content}
              </StyledWrapperMenuPageWithHeader>
            ) : (
              children
            )}
          </MenuUnstyled>
        </ClickAwayListener>
      );
    },
    [isOpened, currentMenuPage],
  );

  return {
    anchorEl: isOpened,
    handleClick,
    Menu,
    setCurrentMenuPage,
    handleClose,
  };
};
