import { AutoLayout } from 'components/atoms/AutoLayout';
import { Avatar } from 'components/atoms/Avatar';
import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { theme } from 'modules/global/theme/theme';
import { NavLink } from 'react-router-dom';

interface Props {
  displayName: string;
  id: string;
  avatar: string;
}
export const UserLink = ({ id, displayName }: Props) => {
  return (
    <NavLink to={`/users/${id}`} style={{ textDecoration: 'none', color: theme.colors.grey.default }}>
      <AutoLayout gap={4} alignVerticalInside="center" hover={{}}>
        <Avatar
          avatarProperties={{
            color: 'red',
          }}
        />
        <Typography
          variant={TypographyTypes.bodySmallLight}
          hover={{
            color: theme.colors.accentColor.default,
          }}
        >
          {displayName}
        </Typography>
      </AutoLayout>
    </NavLink>
  );
};
