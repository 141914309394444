import { Typography } from 'components/atoms/Typography';
import { theme } from 'modules/global/theme/theme';
import styled from 'styled-components';

export const StyledWrapper = styled('footer')(() => ({
  display: 'flex',
  width: '100%',
  padding: '20px',
  alignItems: 'center',
  justifyContent: 'center',
  borderTop: '1px solid #e0e0e0',
  transition: '0.3s',
}));

export const StyledWrapperInner = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '600px',
  width: '100%',
  margin: '0px auto',
  alignItems: 'left',
  justifyContent: 'space-between',
  gap: '20px',
  [theme.media.up('tablet')]: {
    flexDirection: 'row',
    gap: '80px',
  },
}));

export const StyledWrapperSection = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

export const StyledHeaderSection = styled(Typography)(({}) => ({
  fontSize: '16px',
}));

export const StyledWrapperSocials = styled('div')(({}) => ({
  display: 'flex',
  gap: '8px',
}));
