import { Icon } from '.';

interface Props {
  color?: string;
  size?: string;
}
export const ShareIcon = (props: Props) => (
  <Icon {...props}>
    <path d="M18.0622 16.3985C17.4013 16.3985 16.81 16.6594 16.3578 17.0681L10.1575 13.4593C10.2009 13.2592 10.2357 13.0592 10.2357 12.8505C10.2357 12.6418 10.2009 12.4418 10.1575 12.2418L16.2882 8.66772C16.7578 9.10252 17.3752 9.3721 18.0622 9.3721C19.5057 9.3721 20.671 8.20682 20.671 6.76327C20.671 5.31972 19.5057 4.15445 18.0622 4.15445C16.6186 4.15445 15.4534 5.31972 15.4534 6.76327C15.4534 6.97198 15.4882 7.17199 15.5316 7.372L9.40089 10.9461C8.9313 10.5113 8.31388 10.2417 7.62689 10.2417C6.18334 10.2417 5.01807 11.407 5.01807 12.8505C5.01807 14.2941 6.18334 15.4594 7.62689 15.4594C8.31388 15.4594 8.9313 15.1898 9.40089 14.755L15.5925 18.3725C15.549 18.5552 15.5229 18.7465 15.5229 18.9378C15.5229 20.3379 16.6621 21.477 18.0622 21.477C19.4623 21.477 20.6014 20.3379 20.6014 18.9378C20.6014 17.5377 19.4623 16.3985 18.0622 16.3985ZM18.0622 5.89367C18.5405 5.89367 18.9318 6.28499 18.9318 6.76327C18.9318 7.24156 18.5405 7.63288 18.0622 7.63288C17.5839 7.63288 17.1926 7.24156 17.1926 6.76327C17.1926 6.28499 17.5839 5.89367 18.0622 5.89367ZM7.62689 13.7201C7.14861 13.7201 6.75728 13.3288 6.75728 12.8505C6.75728 12.3722 7.14861 11.9809 7.62689 11.9809C8.10518 11.9809 8.4965 12.3722 8.4965 12.8505C8.4965 13.3288 8.10518 13.7201 7.62689 13.7201ZM18.0622 19.8248C17.5839 19.8248 17.1926 19.4335 17.1926 18.9552C17.1926 18.4769 17.5839 18.0856 18.0622 18.0856C18.5405 18.0856 18.9318 18.4769 18.9318 18.9552C18.9318 19.4335 18.5405 19.8248 18.0622 19.8248Z" />
  </Icon>
);
