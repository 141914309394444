import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { ButtonIcon } from 'components/atoms/buttons/ButtonIcon';
import { EditIcon } from 'components/atoms/icons/EditIcon';
import PlusIcon from 'components/atoms/icons/PlusIcon';
import { APP_ROUTES } from 'modules/global/routes';
import { useInterface } from 'modules/global/state/useInterface';
import { ResourceTypeForm } from 'modules/global/state/useInterface/types/actions';
import { theme } from 'modules/global/theme/theme';
import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { Handle, NodeProps, Position } from 'reactflow';
import { backgroundColorByStatus } from '../helpers/colorsByStatus';
import { NodeDeck } from '../types';
import { PointsCounter } from './PointsCounter';

export type DeckNodeProps = NodeProps<NodeDeck['data']>;
const DeckNode = memo(function DeckNode({ data, isConnectable, ...rest }: DeckNodeProps) {
  const { status, parentDeck, deck, path, permissions } = data;
  const { updateResourceModal } = useInterface();

  const leftHandleStyle = {
    background: backgroundColorByStatus(status),
    width: '20px',
    height: '20px',
    translate: '-50%',
    borderRadius: theme.borders.small,
  };
  const rightHandleStyle = {
    background: backgroundColorByStatus(status),
    width: '10px',
    height: '10px',
    borderRadius: theme.borders.small,
  };
  const nodeStyle: any = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 5,
    width: 200,
    minHeight: 90,
    padding: 10,
    paddingRight: 30,
    borderRadius: theme.borders.small,
    outline: `1.5px solid ${backgroundColorByStatus(status)}`,
  };

  return (
    <div>
      <Handle
        type="target"
        position={Position.Left}
        style={leftHandleStyle}
        isConnectable={permissions.canCreateDeck}
      />

      <div style={nodeStyle}>
        <NavLink
          style={{ color: backgroundColorByStatus(status), textDecoration: 'none' }}
          to={APP_ROUTES.DECKS + '/' + deck?.id}
        >
          <Typography
            color={backgroundColorByStatus(status)}
            as={'h5'}
            variant={TypographyTypes.titleSmallSemiBold}
          >
            {deck?.name}
          </Typography>
        </NavLink>
        <PointsCounter
          currentPoints={data.deck?.currentUserGainedPoints || 0}
          maxPoints={data.deck?.maxQuizPoints || 0}
          status={status}
        />
        {permissions.canEditPath ? (
          <div
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              translate: '0px -90%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ButtonIcon
              iconSize="40px"
              Icon={<EditIcon />}
              onClick={(e) => {
                e.stopPropagation();
                console.log('click');
                updateResourceModal({
                  isOpen: true,
                  deck: deck,
                  sourceDeckId: deck?.sourceDeckId,
                  addToPath: deck?.pathId,
                  slajdType: ResourceTypeForm.DECK,
                });
              }}
            />
            <ButtonIcon
              iconSize="40px"
              Icon={<PlusIcon />}
              onClick={(e) => {
                e.stopPropagation();
                updateResourceModal({
                  isOpen: true,
                  deck: undefined,
                  sourceDeckId: deck?.id,
                  addToPath: deck?.pathId,
                  slajdType: ResourceTypeForm.DECK,
                });
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <Handle
        id="a"
        type="source"
        position={Position.Right}
        style={rightHandleStyle}
        isConnectable={permissions.canCreateDeck}
      />
    </div>
  );
});

export default DeckNode;
