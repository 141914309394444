import { FormDeckCardDefaultValues } from 'modules/card/models/formCard';
import { FieldTextTypes, IDeckCard, Language, ProgrammingLanguage } from 'shared-types-wordkito';

export const formCardDefaultValuesSwitcher = (
  card?: IDeckCard,
  deckCardOptions?: IDeckCard[],
): FormDeckCardDefaultValues => {
  return {
    mainContent: card?.mainContent && {
      id: card.id,
      isCorrect: card?.isCorrect,
      imageUrl: card?.mainContent?.image?.url || undefined,
      field: {
        id: card?.mainContent?.id || undefined,
        text: card?.mainContent?.text || '',
        textType: card?.mainContent?.textType || FieldTextTypes.TEXT,
        imageId: card?.mainContent?.imageId || undefined,
        language: card?.mainContent?.language || Language.pl,
        programmingLanguageType: card?.mainContent?.programmingLanguage || ProgrammingLanguage.js,
      },
    },
    options:
      deckCardOptions?.map((option) => ({
        id: option.id,
        field: {
          id: option.mainContent?.id || undefined,
          text: option.mainContent?.text || '',
          textType: option.mainContent?.textType || FieldTextTypes.TEXT,
          imageId: option.mainContent?.imageId || undefined,
          language: option.mainContent?.language || Language.pl,
          programmingLanguageType: option.mainContent?.programmingLanguage || ProgrammingLanguage.js,
        },
        isCorrect: option.isCorrect,
        imageUrl: option.mainContent?.image?.url || undefined,
      })) || [],
  };
};
