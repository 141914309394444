import { ImageOption } from 'components/atoms/ImageOption';
import { Select } from 'components/atoms/inputs/selects/Select';
import { InputCode } from 'components/atoms/inputs/texts/InputCode';
import { InputText } from 'components/atoms/inputs/texts/InputText';
import { ReactNode } from 'react';
import { Control } from 'react-hook-form';
import { FieldTextTypes, ProgrammingLanguage } from 'shared-types-wordkito';
import { StyledWrapper, StyledWrapperEndAdorment } from './Styled';

interface Props {
  control: Control;
  label: ReactNode;
  textName: string;
  helperText?: string;
  error?: boolean;
  programmingLanguageName: string;
  image?: {
    id?: string;
    url?: string;
  };
  programmingLanugageType: ProgrammingLanguage;
  endAdornment?: ReactNode;
  textType: FieldTextTypes;
  onDeleteImage?: () => void;
}

export const Field = ({
  textName,
  programmingLanugageType,
  programmingLanguageName,
  helperText,
  error,
  control,
  image,
  label,
  textType,
  onDeleteImage,
}: Props) => {
  const codeLanguages = Object.values(ProgrammingLanguage);
  const endAdornment = (
    <>
      <StyledWrapperEndAdorment>
        {textType === FieldTextTypes.CODE && (
          <Select
            name={programmingLanguageName}
            defaultValue={programmingLanugageType || ProgrammingLanguage.js}
            control={control}
            variant="ghost"
            withArrow={false}
            options={
              codeLanguages.map((language) => ({
                value: language,
                text: language,
              })) || []
            }
          />
        )}
        {image?.url && <ImageOption url={image?.url} onDelete={onDeleteImage} />}
      </StyledWrapperEndAdorment>
    </>
  );

  return (
    <StyledWrapper>
      {textType === FieldTextTypes.CODE && (
        <InputCode
          label={label}
          language={programmingLanugageType}
          required
          rows={3}
          error={error}
          minHeight={3}
          placeholder="Zacznij pisać kod..."
          name={textName}
          control={control}
          helperText={helperText}
          endAdornment={endAdornment}
        />
      )}
      {textType === FieldTextTypes.TEXT && (
        <InputText
          label={label}
          required
          fullWidth
          error={error}
          helperText={helperText}
          multiline
          minRows={3}
          name={textName}
          control={control}
          endAdornment={endAdornment}
        />
      )}
    </StyledWrapper>
  );
};
