import { ButtonIcon } from 'components/atoms/buttons/ButtonIcon';
import { ImageAddIcon } from 'components/atoms/icons/ImageAddIcon';
import { Select } from 'components/atoms/inputs/selects/Select';
import { FormDeckCardDefaultValues } from 'modules/card/models/formCard';
import { useInterface } from 'modules/global/state/useInterface';
import { UseFormReturn } from 'react-hook-form';
import { FieldTextTypes, ProgrammingLanguage } from 'shared-types-wordkito';
import { Field } from '../Quiz/Option';

import { StyledQuestionActions, StyledQuestionLabel, StyledWrapper } from './Styled';

interface Props {
  form: UseFormReturn<FormDeckCardDefaultValues>;
}

export const Content = ({ form }: Props) => {
  const { updateModalImage } = useInterface();
  const content = form.watch('mainContent');

  return (
    <StyledWrapper>
      <Field
        control={form.control}
        textName={'mainContent.field.text'}
        textType={content?.field?.textType || FieldTextTypes.TEXT}
        error={!!form.formState.errors.mainContent}
        programmingLanugageType={content?.field?.programmingLanguageType || ProgrammingLanguage.js}
        programmingLanguageName="mainContent.field.programmingLanguageType"
        image={{
          id: content?.field.imageId || undefined,
          url: content?.imageUrl || undefined,
        }}
        onDeleteImage={() => {
          form.setValue('mainContent.imageUrl', undefined);
          form.setValue('mainContent.field.imageId', undefined);
        }}
        label={
          <StyledQuestionLabel>
            <div>Treść</div>
            <StyledQuestionActions>
              <Select
                name="mainContent.field.textType"
                defaultValue={content?.field?.textType || FieldTextTypes.TEXT}
                control={form.control}
                variant="ghost"
                options={[
                  { text: 'Zwykły tekst', value: FieldTextTypes.TEXT },
                  {
                    text: 'Kod ',
                    value: FieldTextTypes.CODE,
                  },
                ]}
              />
              <ButtonIcon
                Icon={<ImageAddIcon size="32" />}
                tooltipProps={{
                  content: 'Dodaj obrazek do pytania',
                }}
                onClick={() =>
                  updateModalImage({
                    isOpen: true,
                    image: '',
                    setImage: (image) => {
                      form.setValue(`mainContent.imageUrl`, image?.url);
                      form.setValue(`mainContent.field.imageId`, image?.id);
                    },
                  })
                }
              />
            </StyledQuestionActions>
          </StyledQuestionLabel>
        }
      />
    </StyledWrapper>
  );
};
