import styled, { css } from 'styled-components';

export interface StyledWrapperProps {
  primary?: boolean | undefined;
  secondary?: boolean | undefined;
  third?: boolean | undefined;
}

export const StyledWrapper = styled.div<StyledWrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${({ primary }) =>
    primary &&
    css`
      /* padding: 0 40px; */
    `}

  ${({ secondary }) =>
    secondary &&
    css`
      display: flex;
      flex-direction: row;
      gap: 48px;
    `}
    
  ${({ third }) =>
    third &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;
