import { ReactNode } from 'react';
import { DeckCardTypes } from 'shared-types-wordkito';
import { StyledModal } from './Styles';
import { BackDrop } from './components/Backdrop';

export interface Props {
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
  baseOrCardType?: DeckCardTypes;
}

export const Modal = ({ isOpen, onClose, children }: Props) => (
  <StyledModal slots={{ backdrop: BackDrop }} onClose={onClose} open={isOpen}>
    <div
      style={{
        maxWidth: '600px',
        width: '100%',
      }}
    >
      {children}
    </div>
  </StyledModal>
);
