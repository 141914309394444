import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'modules/global/helpers/fetchApiMethods';

import { API_URLS } from 'modules/global/urls';
import {
  CreateDeckCardDto,
  CreateDeckCardResponseDto,
  CreateDeckDto,
  DeckGameStatsResponseDto,
  IDeck,
  IPath,
} from 'shared-types-wordkito';

export const createDeckCard = async ({ deckId, body }: { deckId?: string; body: CreateDeckCardDto }) => {
  const response = await axiosPost<CreateDeckCardDto, CreateDeckCardResponseDto>(
    API_URLS.CREATE_DECK_CARD(deckId),
    body,
  );

  return response.data;
};

export const createDeck = async (data: CreateDeckDto) => {
  const response = await axiosPost<CreateDeckDto, IDeck>(API_URLS.CREATE_DECK, data);

  return response.data;
};

export const updateDeck = async ({ baseId, data }: { baseId: string; data: CreateDeckDto }) => {
  const response = await axiosPatch<CreateDeckDto, IDeck>(API_URLS.UPDATE_BASE(baseId), data);

  return response.data;
};

export const getDeck = async (baseUniqueUrl: string) => {
  const response = await axiosGet<IDeck>(API_URLS.GET_DECK(baseUniqueUrl));

  return response.data;
};

export const getDeckStats = async (deckId: string) => {
  const response = await axiosGet<DeckGameStatsResponseDto[]>(API_URLS.GET_DECK_STATS(deckId));

  return response.data;
};

export enum MenuPagesTypes {
  MY_DECKS = 'MY_DECKS',
  SHARED_DECKS = 'SHARED_DECKS',
  MARKET_DECKS = 'MARKET_DECKS',
}

export const searchDecks = async (pageParam: string, textParam: string) => {
  const response = await axiosGet<IDeck[]>(API_URLS.SEARCH_STORIES(pageParam, textParam));

  return response.data;
};

export const searchPaths = async (pageParam: string, textParam: string) => {
  const response = await axiosGet<IPath[]>(API_URLS.SEARCH_PATHS(pageParam, textParam));

  return response.data;
};

export const getDecks = async (userId: string, type?: MenuPagesTypes) => {
  const url = () => {
    if (userId) return API_URLS.USER_DECKS(userId);
    if (type == MenuPagesTypes.MY_DECKS) return API_URLS.CURRENT_USER_DECKS;
    if (type == MenuPagesTypes.SHARED_DECKS) return API_URLS.CURRENT_USER_SHARED_DECKS;
    if (type == MenuPagesTypes.MARKET_DECKS) return API_URLS.CURRENT_USER_BOUGHT_DECKS;

    return '';
  };
  const response = await axiosGet<IDeck[]>(url());

  return response.data;
};

export const deleteBase = async (baseId: string) => {
  const response = await axiosDelete<string>(API_URLS.DELETE_BASE(baseId));

  return response.data;
};
