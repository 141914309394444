import { ButtonIcon } from 'components/atoms/buttons/ButtonIcon';
import { CloseIcon } from 'components/atoms/icons/CloseIcon';

import { StyledWrapper } from './Styled';

interface Props {
  onClose: () => void;
}
export const PanelTop = ({ onClose }: Props) => (
  <StyledWrapper>
    <ButtonIcon onClick={onClose} Icon={<CloseIcon />} />
  </StyledWrapper>
);
