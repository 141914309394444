import { AutoLayout } from 'components/atoms/AutoLayout';
import 'reactflow/dist/style.css';
import { IDeck, IPath } from 'shared-types-wordkito';
import { Deck } from '../Deck';

interface Props {
  decks: IDeck[];
  paths: IPath[];
}

export const DeckList = ({ decks, paths }: Props) => {
  return (
    <AutoLayout
      column
      gap={20}
      fullWidth
      css={
        {
          // height: '600px',
        }
      }
    >
      {decks.map((deck) => {
        return <Deck key={deck.id} deck={deck} paths={paths} />;
      })}
    </AutoLayout>
  );
};
