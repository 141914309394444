import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'modules/global/helpers/fetchApiMethods';
import { API_URLS } from 'modules/global/urls';
import { CreatePathDto, IPath } from 'shared-types-wordkito';

export const createPath = async (body: CreatePathDto) => {
  const response = await axiosPost<CreatePathDto, IPath>(API_URLS.PATHS, body);

  return response.data;
};

export const getPaths = async () => {
  const response = await axiosGet<IPath[]>(API_URLS.PATHS);

  return response.data;
};

export const deletePath = async (storyId: string) => {
  const response = await axiosDelete(API_URLS.PATH_DETAILS(storyId));

  return response.data;
};

export const updatePath = async ({
  pathId,
  body,
}: {
  body: { name: string; isPublic: boolean };
  pathId: string;
}) => {
  const response = await axiosPatch<{ name: string; isPublic: boolean }, IPath>(
    API_URLS.PATH_DETAILS(pathId),
    body,
  );

  return response.data;
};
