export const APP_ROUTES = {
  DECKS: '/decks',
  DECKS_DETAILS: ':deckUniqueUrl',
  STORIES: '/stories',
  STORIES_DETAILS: ':storyId',
  PATHS: '/paths',
  PATHS_DETAILS: ':pathId',
  GAMES: '/games',
  GAMES_DETAILS: ':gameId',
  GAME_PLAY: 'play',
  USERS: '/users',
  USER_DETAILS: ':userId',
  ADMIN: '/admin',
  WORDKITOBASES: '/wordkitobases',
  FAVORITEBASES: '/favoritebases',
  SHARED_BASES: '/sharedbases',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  MARKET: '/market',
  LOGIN: '/login',
  CONFIRM_EMAIL: 'confirm-email',
  MAPS: '/maps',
  MAP_DETAILS: ':mapId',
  MEMBERS: '/members',
  MEMBERS_DETAILS: ':memberId',
  WIDGETS: '/widgets',
  FAQ: '/faq',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_USE: '/terms-of-use',
  ABOUT_US: '/about-us',
  CONTACT: '/contact',
};
