import { axiosDelete, axiosPatch, axiosPost } from 'modules/global/helpers/fetchApiMethods';
import { API_URLS } from 'modules/global/urls';
import { IDeck, ITransaction } from 'shared-types-wordkito';

export const createTransaction = async (data: Pick<ITransaction, 'productId'>) => {
  const response = await axiosPost<Pick<ITransaction, 'productId'>, ITransaction>(
    API_URLS.CRAETE_TRANSACTION(),
    data,
  );

  return response.data;
};

export const deleteTransaction = async (data: string) => {
  const response = await axiosDelete<ITransaction>(API_URLS.DELETE_TRANSACTION(data));

  return response.data;
};

export const exposeOnMarket = async ({ deckId, price }: { deckId: string; price: number }) => {
  const response = await axiosPatch<{ price?: number }, IDeck>(API_URLS.EXPOSE_ON_MARKET(deckId), { price });

  return response.data;
};
