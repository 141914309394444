import { Children, ReactNode } from 'react';
import MenuTabItem from './MenuTabItem';
import { StyledWrapper } from './Styles';

interface Props {
  children: ReactNode;
}

const MenuTab = ({ children }: Props) => <StyledWrapper>{children}</StyledWrapper>;

interface MenuPageProps {
  children: ReactNode;
  value: number | string;
}

const MenuPage = ({ children, value }: MenuPageProps): JSX.Element => (
  <>{children}</>
);
interface MenuPagesProps {
  children: JSX.Element | JSX.Element[];
  value: number | string;
}

const MenuPages = ({ children, value }: MenuPagesProps) => (
  <>
    {Children.map(children, (child) => {
      if (value === child?.props?.value) return child;
    })}
  </>
);

export { MenuTab, MenuPages, MenuPage, MenuTabItem };
