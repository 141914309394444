import { theme } from 'modules/global/theme/theme';
import styled from 'styled-components';

export const StyledWrapper = styled('div')(({}) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  borderRadius: '4px',
  width: '100%',
  maxHeight: '100vh',
  overflowY: 'auto',

  [theme.media.up('laptop')]: {
    width: '100%',
    maxWidth: '600px',
  },
}));
