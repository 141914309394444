import { Tooltip } from 'components/atoms/Tooltip';
import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { ButtonHTMLAttributes, ReactNode, cloneElement } from 'react';
import { StyledBadge, StyledButton, Wrapper } from './Styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  Icon: JSX.Element;
  iconSize?: string;
  variant?: 'primary' | 'secondary';
  badgeCountValue?: string;
  badgeBackgroundColor?: string;
  tooltipProps?: {
    content: ReactNode;
  };
}

export const ButtonIcon = ({
  Icon,
  iconSize = '24',
  variant = 'primary',
  tooltipProps,
  badgeBackgroundColor,
  badgeCountValue,
  disabled,
  ...rest
}: Props) => {
  const ClonedIcon = cloneElement(
    Icon,
    {
      size: iconSize,
      ...Icon.props,
    },
    null,
  );

  return (
    <Tooltip title={tooltipProps?.content}>
      <Wrapper>
        {badgeCountValue ? (
          <StyledBadge backgroundColor={badgeBackgroundColor}>
            <Typography as="span" variant={TypographyTypes.bodySmallRegular} color={'white'}>
              {badgeCountValue}
            </Typography>
          </StyledBadge>
        ) : null}

        <StyledButton
          {...rest}
          onClick={(e) => {
            e.stopPropagation();
            rest.onClick && rest.onClick(e);
          }}
          disabled={disabled}
          variant={variant}
        >
          {ClonedIcon}
        </StyledButton>
      </Wrapper>
    </Tooltip>
  );
};
