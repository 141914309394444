import { Button } from 'components/atoms/buttons/Button';
import { ButtonIcon } from 'components/atoms/buttons/ButtonIcon';
import { Icon } from 'components/atoms/icons';
import { EditIcon } from 'components/atoms/icons/EditIcon';
import { MoreIcon } from 'components/atoms/icons/MoreIcon';
import { useMenu } from 'components/molecules/Menu';
import { MenuItem } from 'components/molecules/MenuItem';
import { useDeleteDeck } from 'modules/deck/interactions/queries/deck';
import { DeckPermissions } from 'modules/deck/models/permissions';
import { useInterface } from 'modules/global/state/useInterface';
import { ResourceTypeForm } from 'modules/global/state/useInterface/types/actions';
import { IDeck } from 'shared-types-wordkito';
import { StyledWrapper } from './Styles';

interface Props {
  deck: IDeck;
  permissions: DeckPermissions;
}

const Buttons = ({ permissions, deck }: Props) => {
  const deleteBase = useDeleteDeck();
  const { Menu, setCurrentMenuPage, handleClose, handleClick } = useMenu();
  const { updateResourceModal, updateShareModal } = useInterface();

  return (
    <>
      <StyledWrapper>
        {/* {permissions.canInvite && (
          <ButtonIcon
            tooltipProps={{
              content: 'Udostępnij',
            }}
            Icon={<ShareIcon />}
            onClick={(e) => {
              updateShareModal({ isOpen: true, deck: deck, name: deck.name });
            }}
          />
        )} */}
        {(permissions.canDelete || permissions.canEdit) && (
          <>
            <ButtonIcon
              tooltipProps={{
                content: 'Więcej opcji',
              }}
              Icon={<MoreIcon />}
              onClick={handleClick}
            />
            <Menu
              position="right"
              anchorMenuGap="45px"
              MenuPages={[
                {
                  type: 'DELETE',
                  title: 'Delete',
                  content: (
                    <Button
                      text="usuń"
                      onClick={(e) => {
                        deleteBase.mutate(deck.id);
                      }}
                    >
                      Usuwaj
                    </Button>
                  ),
                },
              ]}
            >
              {permissions.canEdit && (
                <MenuItem
                  Icon={<EditIcon />}
                  content="Edytuj"
                  onClick={(e) => {
                    updateResourceModal({
                      isOpen: true,
                      deck: deck,
                      slajdType: ResourceTypeForm.DECK,
                    });

                    handleClose();
                  }}
                />
              )}

              {permissions.canDelete && (
                <MenuItem
                  Icon={<Icon name="delete" />}
                  content="Usun"
                  onClick={() => setCurrentMenuPage('DELETE')}
                />
              )}
            </Menu>
          </>
        )}
      </StyledWrapper>
    </>
  );
};

export default Buttons;
