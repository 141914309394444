import { Footer } from 'components/organisms/Footer';
import { Header } from 'components/organisms/Header';
import { ModalCard } from 'components/organisms/modals/ModalCard';
import { ModalGenerateCard } from 'components/organisms/modals/ModalGenerateCard';
import { ModalImage } from 'components/organisms/modals/ModalImage';
import { ModalMarket } from 'components/organisms/modals/ModalMarket';

import { ModalMemberResource } from 'components/organisms/modals/ModalMemberResource';
import { ModalResource } from 'components/organisms/modals/ModalResource';
import { useInterface } from 'modules/global/state/useInterface';
import { Outlet } from 'react-router';

interface Props {
  backgroundImage?: string;
  headPageText?: string;
  main?: boolean;
}

export const ViewTemplate = ({ backgroundImage, headPageText, main }: Props) => {
  const {
    interfaceState,
    updateShareModal,
    updateModalImage,
    updateCardModal,
    updateModalMarket,
    updateGenerateCardModal,
  } = useInterface();

  return (
    <>
      {interfaceState.modalResource.isOpen && <ModalResource />}
      {interfaceState.modalCard.isOpen && (
        <ModalCard
          modalCardState={interfaceState.modalCard}
          handleClose={() => {
            updateCardModal({ isOpen: false });
          }}
        />
      )}

      {interfaceState.modalImage.isOpen && (
        <ModalImage
          modalImageState={interfaceState.modalImage}
          handleClose={() => updateModalImage({ isOpen: false })}
        />
      )}
      {interfaceState.modalShare.isOpen && (
        <ModalMemberResource
          modalShareState={interfaceState.modalShare}
          handleClose={() => updateShareModal({ isOpen: false })}
        />
      )}

      {interfaceState.modalMarket.isOpen && (
        <ModalMarket
          modalState={interfaceState.modalMarket}
          handleClose={() => updateModalMarket({ isOpen: false })}
        />
      )}
      {interfaceState.modalGenerateCard.isOpen && (
        <ModalGenerateCard
          modalState={interfaceState.modalGenerateCard}
          handleClose={() => {
            updateGenerateCardModal({ isOpen: false });
          }}
        />
      )}
      <Header />
      <main
        style={
          main
            ? {
                marginTop: '54px',
              }
            : {
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '600px',
                padding: '100px 20px',
                justifyContent: 'center',
                margin: '0 auto',
                width: '100%',
              }
        }
      >
        <Outlet />
      </main>
      <Footer />
    </>
  );
};
