import styled from 'styled-components';

export const StyledWrapper = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '20px',
}));

export const StyledWrapperImageForm = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '8px',
}));

export const StyledWrapperOptions = styled('div')(({}) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676';
  }

  if (props.isDragReject) {
    return '#ff1744';
  }

  if (props.isFocused) {
    return '#2196f3';
  }

  return '#eeeeee';
};

export const StyledWrapperDropDown = styled('div')((props) => ({
  display: 'flex',
  gap: '30px',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '20px',
  borderWidth: '2px',
  borderRadius: '2px',
  borderColor: getColor(props),
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border 0.24s ease-in-out',
}));
