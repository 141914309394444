import { useQuery } from '@tanstack/react-query';
import { DeckQueryKeys } from 'modules/deck/constants/queryKeys';
import { axiosGet } from 'modules/global/helpers/fetchApiMethods';
import { API_URLS } from 'modules/global/urls';
import { useReactFlow } from 'reactflow';
import { IDeck, IPath } from 'shared-types-wordkito';

export const useGetPath = (pathId?: string) => {
  const get = async () => {
    const response = await axiosGet<IPath>(API_URLS.PATH_DETAILS(pathId as string));

    return response.data;
  };
  const result = useQuery(DeckQueryKeys.paths(pathId), get, {
    enabled: !!pathId,
  });

  return result;
};

export const useGetPaths = (userId?: string, spaceId?: string) => {
  const get = async () => {
    const response = await axiosGet<IPath[]>(API_URLS.PATHS);

    return response.data;
  };
  const result = useQuery(DeckQueryKeys.paths(userId as string), get, {});

  return result;
};

export const useGetDecksForPaths = (pathId: string) => {
  const { addNodes, addEdges, setNodes, setEdges } = useReactFlow();
  const get = async () => {
    const response = await axiosGet<IDeck[]>(API_URLS.PATH_DETAILS_DECKS(pathId));

    return response.data;
  };
  const result = useQuery(DeckQueryKeys.pathDecks(pathId), get, {
    async onSuccess(data) {
      // setNodes(nodes || []);
      // setEdges(edges || []);
    },
  });

  return result;
};
