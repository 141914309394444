import { ButtonIcon } from 'components/atoms/buttons/ButtonIcon';
import { Icon } from 'components/atoms/icons';
import { ImageAddIcon } from 'components/atoms/icons/ImageAddIcon';
import { Select } from 'components/atoms/inputs/selects/Select';
import { FormDeckCardDefaultValues } from 'modules/card/models/formCard';
import { UseFormReturn } from 'react-hook-form';
import { FieldTextTypes } from 'shared-types-wordkito';
import { StyledWrapper, StyledWrapperControls, StyledWrapperTextTypeButtons } from './Styled';

interface Props {
  optionKey: number;
  form: UseFormReturn<FormDeckCardDefaultValues>;
  currentOptionNumber: number;
  textType: FieldTextTypes;
  optionTextTypeName: string;
  handleRemoveOption: () => void;
  handleOpenImageForm: () => void;
}

export const OptionLabel = ({
  optionKey,
  form,
  currentOptionNumber,
  textType,
  optionTextTypeName,
  handleRemoveOption,
  handleOpenImageForm,
}: Props) => {
  return (
    <StyledWrapper>
      <StyledWrapperControls>
        <span>Odpowiedź: {currentOptionNumber + 1} </span>
      </StyledWrapperControls>
      <StyledWrapperTextTypeButtons>
        <Select
          name={optionTextTypeName}
          defaultValue={textType || FieldTextTypes.TEXT}
          control={form.control}
          variant="ghost"
          options={[
            { text: 'Zwykły tekst', value: FieldTextTypes.TEXT },
            {
              text: 'Kod ',
              value: FieldTextTypes.CODE,
            },
          ]}
        />
        <ButtonIcon Icon={<ImageAddIcon size="32" />} onClick={handleOpenImageForm} />
        <ButtonIcon Icon={<Icon name="delete" />} onClick={handleRemoveOption} />
      </StyledWrapperTextTypeButtons>
    </StyledWrapper>
  );
};
