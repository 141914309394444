import { axiosDelete, axiosGet, axiosPatch } from 'modules/global/helpers/fetchApiMethods';

import { API_URLS } from 'modules/global/urls';
import {
  CreateDeckCardDto,
  CreateDeckCardResponseDto,
  IDeckCard,
  UpdateDeckCardPositionDto,
} from 'shared-types-wordkito';

export const updateDeckCard = async (deckCardId: string, body: CreateDeckCardDto) => {
  const response = await axiosPatch<CreateDeckCardDto, CreateDeckCardResponseDto>(
    API_URLS.UPDATE_DECK_CARD(deckCardId),
    body,
  );

  return response.data;
};

export const updateDeckCardPosition = async (deckCardId: string, body: UpdateDeckCardPositionDto) => {
  const response = await axiosPatch<UpdateDeckCardPositionDto, IDeckCard[]>(
    API_URLS.UPDATE_DECK_CARD_POSITION(deckCardId),
    body,
  );

  return response.data;
};

export const getDeckCards = async (deckId: string) => {
  const response = await axiosGet<IDeckCard[]>(API_URLS.GET_DECK_CARDS(deckId));

  return response.data;
};

export const deleteDeckCard = async ({ deckCardId }: { deckCardId: string }) => {
  const response = await axiosDelete<CreateDeckCardResponseDto>(API_URLS.DELETE_DECK_CARD(deckCardId));

  return response.data;
};

export const updateBaseCardStatus = async ({ baseCardId }: { baseId: string; baseCardId: string }) => {
  const response = await axiosPatch<{}, IDeckCard>(API_URLS.UPDATE_DECK_CARD_STATUS(baseCardId), {});

  return response.data;
};
