import { useMutation, useQuery } from '@tanstack/react-query';
import { changeDisplayNameAction, checkEmailExistsAction, deleteUser } from './methods';

export const useDeleteUser = () => useMutation(deleteUser);

export const useCheckEmailExists = () => useMutation((email: string) => checkEmailExistsAction(email));

export const useUpdateDisplayName = () =>
  useMutation((body: { displayName: string }) => changeDisplayNameAction(body));

export const useGetUserProfile = (userId?: string) =>
  useQuery(['users', userId], () => fetch(`/api/users/${userId}`).then((res) => res.json()), {
    enabled: !!userId,
  });
