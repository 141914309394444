import * as firebase from '@firebase/app';
import { getAuth } from '@firebase/auth';
import 'firebaseui/dist/firebaseui.css';

const config: firebase.FirebaseOptions = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
};

export const firebaseApp = firebase.initializeApp(config);

export const firebaseAuth = getAuth(firebaseApp);
