import { useAccessToken } from 'modules/authentication/interactions/hooks';
import { GameClientToServerEvents, GameServerToClientEvents } from 'shared-types-wordkito';
import { useSocket } from 'socket.io-react-hook';

interface Props {
  initialDeckId?: string;

  options?: {
    onSocketOpen?: (event: Event) => void;
    onSocketClose?: () => void;
    onSocketError?: () => void;
  };
}

export const useGameSocket = ({ initialDeckId }: Props) => {
  const query = { ['initialDeckId']: initialDeckId };
  const accessToken = useAccessToken();
  const { socket } = useSocket<GameServerToClientEvents, GameClientToServerEvents>(
    process.env.REACT_APP_SOCKET_API_URL + '/game',
    {
      path: process.env.NODE_ENV === 'development' ? '' : '/api/socket.io',
      autoConnect: true,
      query,
      transports: ['websocket'],
      forceNew: true,
      withCredentials: true,
      enabled: !!accessToken,
      auth: {
        token: `Barear ${accessToken}`,
        initialDeckId: initialDeckId,
      },
    },
  );

  return { socket };
};
