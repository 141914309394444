import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { getAccessToken } from 'modules/authentication/helpers/getAccessToken';

const BAREAR_TOKEN_NAME = 'Barear';
export const customFetch = async (input: RequestInfo, init?: RequestInit | undefined): Promise<Response> => {
  const accessToken = await getAccessToken();
  const headers = {
    Authorization: `${BAREAR_TOKEN_NAME} ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const response = await fetch(input, {
    ...init,
    headers,
    credentials: 'include',
  });

  return response;
};

export const axiosGet = async <T>(
  url: string,
  axiosSettings?: Record<string, unknown>,
): Promise<AxiosResponse<T>> => {
  const accessToken = await getAccessToken();
  const headers = {
    Authorization: accessToken ? `${BAREAR_TOKEN_NAME} ${accessToken}` : '',
    'Content-Type': 'application/json',
  };

  return axios.get<T>(url, { headers, ...axiosSettings, withCredentials: true });
};

export const axiosPost = async <T, K>(
  url: string,
  payload: T,
  additionalHeaders?: AxiosRequestHeaders,
): Promise<AxiosResponse<K>> => {
  const accessToken = await getAccessToken();
  // const csrfToken = await getCsrfToken();
  const headers = {
    'Content-Type': 'application/json',
    // 'X-CSRFToken': csrfToken || '',
    Authorization: `${BAREAR_TOKEN_NAME} ${accessToken}`,

    ...additionalHeaders,
  };

  return axios.post<K>(url, payload, { headers });
};

export const axiosDelete = async <T>(
  url: string,
  axiosSettings?: Record<string, unknown>,
): Promise<AxiosResponse<T>> => {
  const accessToken = await getAccessToken();
  const headers = {
    Authorization: `${BAREAR_TOKEN_NAME} ${accessToken}`,
    'Content-Type': 'application/json',
  };

  return axios.delete<T>(url, { headers, ...axiosSettings, withCredentials: true });
};

export const axiosPatch = async <T, K>(url: string, payload: T): Promise<AxiosResponse<K>> => {
  const accessToken = await getAccessToken();
  // const csrfToken = await getCsrfToken();
  const headers = {
    'Content-Type': 'application/json',
    // 'X-CSRFToken': csrfToken || '',
    Authorization: `${BAREAR_TOKEN_NAME} ${accessToken}`,
  };

  return axios.patch<K>(url, payload, { headers });
};

export const fetchPut = async <T>(url: string, body: T, init?: RequestInit | undefined): Promise<Response> => {
  const accessToken = await getAccessToken();
  // const csrfToken = await getCsrfToken();
  const headers = {
    'Content-Type': 'application/json',
    // 'X-CSRFToken': csrfToken || '',
    Authorization: `${BAREAR_TOKEN_NAME} ${accessToken}`,
  };
  const response = await fetch(url, {
    ...init,
    method: 'PUT',
    body: JSON.stringify(body),
    headers,
    credentials: 'include',
  });

  return response;
};

export const axiosPut = async <T, K>(url: string, payload: T): Promise<AxiosResponse<K>> => {
  const accessToken = await getAccessToken();
  // const csrfToken = await getCsrfToken();
  const headers = {
    'Content-Type': 'application/json',
    // 'X-CSRFToken': csrfToken || '',
    Authorization: `${BAREAR_TOKEN_NAME} ${accessToken}`,
  };

  return axios.put<K>(url, payload, { headers });
};
