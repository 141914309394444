import { ReactNode, useContext } from 'react';
import { create } from 'zustand';
import { IInterfaceState, InterfaceContext, initialState } from './context';

const useInterfaceStore = create<IInterfaceState>((set) => ({
  interfaceState: initialState,

  updateResourceModal: (payload) =>
    set((state) => ({
      interfaceState: {
        ...state.interfaceState,
        modalResource: payload,
      },
    })),
  updateCardModal: (payload) =>
    set((state) => ({
      interfaceState: {
        ...state.interfaceState,
        modalCard: payload,
      },
    })),

  updateKitPanel: (payload) => {
    localStorage.setItem('kitType', JSON.stringify(payload.kitType));
    localStorage.setItem('showKit', JSON.stringify(payload.isOpen));
    set((state) => ({
      interfaceState: {
        ...state.interfaceState,
        kit: payload,
      },
    }));
  },

  updateShareModal: (payload) =>
    set((state) => ({
      interfaceState: {
        ...state.interfaceState,
        modalShare: payload,
      },
    })),

  updateModalImage: (payload) =>
    set((state) => ({
      interfaceState: {
        ...state.interfaceState,
        modalImage: payload,
      },
    })),

  updateModalStory: (payload) =>
    set((state) => ({
      interfaceState: {
        ...state.interfaceState,
        modalStory: payload,
      },
    })),

  updateModalLocation: (payload) =>
    set((state) => ({
      interfaceState: {
        ...state.interfaceState,
        modalLocation: payload,
      },
    })),
  updateModalMarket: (payload) =>
    set((state) => ({
      interfaceState: {
        ...state.interfaceState,
        modalMarket: payload,
      },
    })),
  updateModalDecision: (payload) =>
    set((state) => ({
      interfaceState: {
        ...state.interfaceState,
        modalDecision: payload,
      },
    })),
  updateStats: (payload) => {
    localStorage.setItem('statsPeriod', JSON.stringify(payload.period));
    set((state) => ({
      interfaceState: {
        ...state.interfaceState,
        stats: payload,
      },
    }));
  },
  updateGenerateCardModal: (payload) =>
    set((state) => ({
      interfaceState: {
        ...state.interfaceState,
        modalGenerateCard: payload,
      },
    })),
}));

const InterfaceProvider = ({ children }: { children: ReactNode }) => {
  const interfaceStore = useInterfaceStore();

  return <InterfaceContext.Provider value={interfaceStore}>{children}</InterfaceContext.Provider>;
};

function useInterface() {
  const context = useContext(InterfaceContext);

  if (!context) {
    throw new Error('Context mus be used within a <AuthProvider/>');
  }

  return context;
}

export { InterfaceProvider, useInterface };
