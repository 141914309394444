import { CustomSvgIconProps, Icon } from '.';

interface Props extends CustomSvgIconProps {
  isCorrect?: boolean;
  isChecked?: boolean;
  showAnswer?: boolean;
}

export const AcceptIcon = (props: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        color: props.color || 'currentColor',
        borderRadius: '4px',
        width: props.size || '24px',
        height: props.size || '24px',
      }}
    >
      <Icon {...props}>
        {props.showAnswer ? (
          <>
            {props.isCorrect ? (
              <path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
            ) : (
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M18.3035 7.36552C18.2234 7.28519 18.1282 7.22145 18.0233 7.17796C17.9185 7.13447 17.8061 7.11209 17.6927 7.11209C17.5792 7.11209 17.4668 7.13447 17.362 7.17796C17.2572 7.22145 17.1619 7.28519 17.0818 7.36552L12.8447 11.594L8.60752 7.35686C8.52737 7.27652 8.43215 7.21279 8.32733 7.1693C8.2225 7.12581 8.11013 7.10342 7.99665 7.10342C7.88316 7.10342 7.77079 7.12581 7.66597 7.1693C7.56114 7.21279 7.46593 7.27652 7.38577 7.35686C7.04784 7.69479 7.04784 8.24068 7.38577 8.57861L11.6229 12.8157L7.38577 17.0529C7.04784 17.3908 7.04784 17.9367 7.38577 18.2746C7.7237 18.6125 8.26959 18.6125 8.60752 18.2746L12.8447 14.0375L17.0818 18.2746C17.4197 18.6125 17.9656 18.6125 18.3035 18.2746C18.6415 17.9367 18.6415 17.3908 18.3035 17.0529L14.0664 12.8157L18.3035 8.57861C18.6328 8.24934 18.6328 7.69479 18.3035 7.36552Z"
              />
            )}
          </>
        ) : (
          <></>
        )}
      </Icon>
    </div>
  );
};
