import {} from 'shared-types-wordkito';
import {
  UpdateCardPayload,
  UpdateModalChangeSpacesPayload,
  UpdateModalDecisionPayload,
  UpdateModalGenerateCardPayload,
  UpdateModalImagePayload,
  UpdateModalLocationPayload,
  UpdateModalMarketPayload,
  UpdateModalStoryPayload,
  UpdateResourcePayload,
  UpdateShareModalPayload,
  UpdateStatsPayload,
} from './payloads';

export enum KitTypes {
  'SPACES' = 'SPACES',
  'FRIENDS' = 'FRIENDS',
  'DECK_STATS' = 'DECK_STATS',
  'PATHS' = 'PATHS',
  'MENU_ADMIN' = 'MENU_ADMIN',
  'MENU_SETTINGS' = 'MENU_SETTINGS',
  'MISSIONS' = 'MISSIONS',
}

export enum PeriodStatsTypes {
  'NO_STATS' = 'NO_STATS',
  'Monthly' = 'Monthly',
  'Weekly' = 'Weekly',
  'Daily' = 'Daily',
  'All' = 'All',
}

export type InterfaceStateType = {
  stats: UpdateStatsPayload;
  modalCard: UpdateCardPayload;
  modalResource: UpdateResourcePayload;
  modalShare: UpdateShareModalPayload;
  modalChangeSpaces: UpdateModalChangeSpacesPayload;
  modalImage: UpdateModalImagePayload;
  modalStory: UpdateModalStoryPayload;
  modalLocation: UpdateModalLocationPayload;
  modalMarket: UpdateModalMarketPayload;
  modalDecision: UpdateModalDecisionPayload;
  modalGenerateCard: UpdateModalGenerateCardPayload;
};
