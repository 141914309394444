import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { Button } from 'components/atoms/buttons/Button';
import { useAuth } from 'modules/authentication/states/useAuth';
import { APP_ROUTES } from 'modules/global/routes';
import { useLanguage } from 'modules/language/useLanguage';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledWrapper } from './Styles';

interface Props {
  children: JSX.Element;
  isRequired?: boolean;
}

export const RequireAuthRoute = ({ children, isRequired = true }: Props) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { languageState } = useLanguage();

  if (!user && isRequired) {
    return (
      <StyledWrapper>
        <Typography variant={TypographyTypes.headerH3}>Zaloguj się aby zobaczyć tą stronę</Typography>
        <Typography variant={TypographyTypes.bodyLargeRegular}>
          lub przejdź do innych stron nie wymagających logowania
        </Typography>
        <Button
          onClick={() =>
            navigate(APP_ROUTES.LOGIN + `?redirectUrl=${encodeURIComponent(location.pathname)}`, {
              replace: true,
            })
          }
          text={languageState.navigation.loginButton}
        />
      </StyledWrapper>
    );
  }

  return children;
};
