import { Button } from 'components/atoms/buttons/Button';
import { Wrapper } from './Styles';

export interface Props {
  handleBack: () => void;
  isLoading: boolean;
  isEnabled: boolean;
  isEditMode: boolean;
}

export const ButtonsAction = ({ handleBack, isEnabled, isLoading, isEditMode }: Props) => {
  const updatingButtonText = isEditMode ? 'Aktualizowanie' : 'Zaktualizuj';
  const creatingButtonText = isEditMode ? 'Tworzenie...' : 'Stwórz';

  return (
    <Wrapper>
      <Button text="Powrót" variant="outline" isLoading={isLoading} onClick={() => handleBack()} type="button" />
      <Button
        disabled={!isEnabled}
        isLoading={isLoading}
        type="submit"
        text={isEditMode ? updatingButtonText : creatingButtonText}
      />
    </Wrapper>
  );
};
