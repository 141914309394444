import { ThemeModel } from './models';

export const theme: ThemeModel = {
  fonts: {
    names: {
      inter: 'inter',
      second: 'MontserratAlternates',
    },
    sizes: {
      56: '56px',
      48: '48px',
      40: '40px',
      32: '32px',
      24: '24px',
      20: '20px',
      18: '18px',
      16: '16px',
      14: '14px',
    },
    weights: {
      light: 100,
      regular400: 400,
      medium500: 500,
      semiBold600: 600,
      bold700: 700,
      extraBold800: 800,
    },
  },
  media: {
    up: (key: keyof typeof theme.mediaEnpoints) => `@media (min-width: ${theme.mediaEnpoints[key]})`,
    down: (key: keyof typeof theme.mediaEnpoints) => `@media (max-width: ${theme.mediaEnpoints[key]})`,
  },
  mediaEnpoints: {
    mobile: '320px', // 320px 480px: Mobile devices
    tablet: '480px', // 481px 768px: iPads, Tablets
    laptop: '769px', // 769px 1024px: Small screens, laptops
    desktop: '1025px', // 1025px1200px: Desktops, large screens
    extraLarge: '1201px', // 1201px and more Extra large screens, TV
  },
  borders: {
    small: '4px',
    medium: '8px',
    large: '12px',
  },
  buttons: {
    default: 'var(--colors-primary600)',
    hover: 'var(--colors-primary500)',
    clicked: 'var(--colors-primary400)',
    disabled: 'var(--colors-gray300)',
  },
  colors: {
    accentColor: {
      background: `rgba(97,175,254,.1)`,
      default: 'var(--colors-primary600)',
      hover: 'var(--colors-primary500)',
      primary400: '#5C5EFF',
    },
    errorColor: {
      default: '#FFA28B',
    },
    warningColor: {
      default: '#ED8C1B',
    },
    acceptColor: {
      default: '#31B24E',
    },

    white: {
      default: 'white',
    },
    fontColors: {
      first: 'var(--colors-font-first)',
      second: '#7F7F7F',
      third: '#7F7F7F',
      onAccent: 'var(--colors-font-on-accent)',
    },
    grey: { default: 'grey', light: '#F8F8F8', dark: 'darkgrey' },

    backgroundAppColor: 'var(--colors-background)',
    backgroundBoxColor: 'var(--colors-background-box)',
    texts: {
      label: 'lightColors.text.light',
    },
  },
};
