import { createContext } from 'react';
import EN from '../constants/translates/en';
import { LanguageAppType } from '../models/languageApp';

export const Context = createContext<{
  languageState: LanguageAppType;
  languageDispatch: React.Dispatch<{ type: 'INIT'; payload: LanguageAppType }>;
}>({
  languageState: EN,
  languageDispatch: () => null,
});
