import { useQuery } from '@tanstack/react-query';
import { getMembers } from '../methods/member';

export const useGetMembers = () => {
  return useQuery(
    [
      'members',
      {
        // spaceId: user.,
      },
    ],
    () => getMembers(),
  );
};
