import { Select } from 'components/atoms/inputs/selects/Select';
import { InputText } from 'components/atoms/inputs/texts/InputText';
import { UseFormReturn } from 'react-hook-form';
import { CreateDeckDto, Language } from 'shared-types-wordkito';
import { StyledWrapper } from './Styled';

interface Props {
  languages: { iso: Language; name: string }[];
  form: UseFormReturn<CreateDeckDto>;
  languageFrom: Language;
}

export const Main = ({ form, languages, languageFrom }: Props) => (
  <StyledWrapper>
    <InputText
      name="name"
      defaultValue={form.getValues('name')}
      label="Tytuł"
      placeholder="O czym jest ta opowieść ?"
      required
      error={!!form.formState.errors?.name}
      helperText={form.formState.errors?.name?.message || 'Im dokładniejsza nazwa tym lepsze generowanie treści.'}
      control={form.control}
    />
    <InputText
      multiline
      name="contextToPrompt"
      defaultValue={form.getValues('contextToPrompt')}
      label="Context do generowania"
      placeholder="Słowa kluczowe, spis treśći, itp."
      error={!!form.formState.errors?.name}
      helperText={'Wrzuć wszystko co chcesz aby było w opowieści.'}
      control={form.control}
    />

    <Select
      name="languageFrom"
      label="Język"
      defaultValue={languageFrom}
      options={languages.map((item) => ({
        text: item.name,
        value: item.iso,
      }))}
      control={form.control}
    />
  </StyledWrapper>
);
