import { URL_PARAM } from 'modules/global/urls';

export const API_URLS_DECK_CARD = (BASE_URL: string) => ({
  DECK_CARD_DECISION: (deckCardId: string): string => BASE_URL + URL_PARAM(deckCardId) + `/decisions`,
  DELETE_DECK_CARD: (deckCardId: string): string => BASE_URL + URL_PARAM(deckCardId),
  UPDATE_DECK_CARD: (deckCardId: string): string => BASE_URL + URL_PARAM(deckCardId),
  UPDATE_DECK_CARD_POSITION: (deckCardId: string): string => BASE_URL + URL_PARAM(deckCardId) + `/position`,
  UPDATE_DECK_CARD_STATUS: (baseCardId: string) => BASE_URL + URL_PARAM(baseCardId) + `/approve-base-card/`,
  GET_TRANSLATE_TEXT: (baseId: string) => `${BASE_URL}${URL_PARAM(baseId)}/translate-text/`,
  UPDATE_DECK_CARD_CATEGORY: (deckCardId: string): string =>
    BASE_URL + URL_PARAM(deckCardId) + `/update-base-card-category/`,
  //.....................................
  GENERATE_CONTENT: (deckCardId: string) => `${BASE_URL}${URL_PARAM(deckCardId)}/generate`,
  GENERATE_QUIZ_OPTION: (deckCardId: string) => `${BASE_URL}${URL_PARAM(deckCardId)}/generate-quiz-option`,
});

export const API_URLS_DECK = (BASE_URL: string) => ({
  UPDATE_BASE: (deckId: string) => `${BASE_URL}${URL_PARAM(deckId)}`,
  DELETE_BASE: (deckId: string) => `${BASE_URL}${URL_PARAM(deckId)}`,
  EXPOSE_ON_MARKET: (deckId: string) => `${BASE_URL}${URL_PARAM(deckId)}/expose-on-market`,

  //
  GET_DECK: (deckId: string) => `${BASE_URL}${URL_PARAM(deckId)}/`,
  GET_DECK_CARDS: (deckId: string) => `${BASE_URL}/${deckId}/deckCards/`,
  GET_DECK_STATS: (deckId: string) => `${BASE_URL}${URL_PARAM(deckId)}/stats/`,
  GET_DECK_MEMBERS: (deckId: string) => `${BASE_URL}${URL_PARAM(deckId)}/roleMembers`,
  GET_DECK_GAMES: (deckId: string) => BASE_URL + URL_PARAM(deckId) + `/games`,
  //.....................................
  CREATE_DECK: `${BASE_URL}`,
  CREATE_DECK_CARD: (deckId?: string) => BASE_URL + URL_PARAM(deckId) + `/deckCards`,
  CREATE_DECK_CARD_DECISION: (deckId?: string) => BASE_URL + URL_PARAM(deckId) + `/deckCards` + `/decisions`,
  CREATE_DECK_ROLE_MEMBERS: (deckId?: string) => `${BASE_URL}${URL_PARAM(deckId)}/roleMembers/`,
  CREATE_DECK_GAME: (deckId: string) => BASE_URL + URL_PARAM(deckId) + `/games`,
});

export const API_URLS_PATH = (BASE_URL: string) => ({
  PATH: (pathId: string) => BASE_URL + URL_PARAM(pathId),
  PATHS: BASE_URL,
  PATH_MEMBERS: (pathId: string) => BASE_URL + URL_PARAM(pathId) + `/members`,
});
