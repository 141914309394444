import CodeEditor, { TextareaCodeEditorProps } from '@uiw/react-textarea-code-editor';
import { HelperText } from 'components/atoms/texts/HelperText';
import { Label } from 'components/atoms/texts/Label';
import { theme } from 'modules/global/theme/theme';
import { InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';
import { InputAdornment, StyledInputRoot } from '../InputText/Styles';

import { StyledError, StyledWrapper } from './Styles';

export type Props = TextareaCodeEditorProps & {
  Icon?: ReactElement;
  helperText?: string;
  fullWidth?: boolean;
  label?: ReactNode;
  error?: boolean;
  variant?: 'primary' | 'search';
  control?: Control<any, any>;
  name: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  endAdornment?: ReactNode;
};

export const InputCode = ({
  name,
  label,
  error,
  required,
  fullWidth,
  helperText,
  control,
  language,
  endAdornment,
  placeholder,
}: Props) => (
  <StyledWrapper fullWidth={fullWidth}>
    {label && <Label required={required} text={label} htmlFor={name} />}

    <StyledInputRoot>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <CodeEditor
            language={language}
            placeholder={placeholder}
            data-color-mode="light"
            padding={0}
            rows={4}
            minHeight={44}
            style={{
              fontSize: '14px',
              backgroundColor: '#F5F5F5',
              fontFamily: theme.fonts.names.inter,
              border: 'none',
              outline: 'none',
              width: '100%',
              borderRadius: theme.borders.medium,
            }}
            {...field}
          />
        )}
      />
      <InputAdornment>{endAdornment}</InputAdornment>
    </StyledInputRoot>

    <StyledError>
      <HelperText text={helperText} isError={error} isWarning={false} />
    </StyledError>
  </StyledWrapper>
);
