import styled from 'styled-components';

export const StyledWrapper = styled('div')<{ isAbsolute?: boolean }>(({ isAbsolute }) => ({
  display: 'flex',
  alignSelf: 'flex-end',
  width: '100%',
  justifyContent: 'end',
  gap: '8px',
  padding: '16px 24px',
  borderRadius: '0px 0px 4px 4px',
  backgroundColor: '#F8F8F8',
  boxShadow: '0px -1px 6px rgba(0, 0, 0, 0.1)',

  ...(isAbsolute && {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
  }),
}));
