import { useForm } from 'react-hook-form';

import { ModalBottomPanel } from 'components/molecules/Modal/components/ModalBottomPanel';
import { ModalMain } from 'components/molecules/Modal/components/ModalMain';
import { useCreateSpace } from 'modules/space/interactions/mutations';
import { FormSpaceDefaultValues } from 'modules/space/models/types/formSpace.types';
import { ISpace } from 'shared-types-wordkito';
import { ModalFormHeader } from '../../../molecules/Modal/components/ModalFormHeader';
import { ButtonsAction } from './components/ButtonsAction';
import { Main } from './components/Main';
import { StyledWrapper } from './Styles';

export interface Props {
  space?: ISpace;
  handleBack?: () => void;
  handleClose: () => void;
}

export const FormSpace = ({ handleBack, space, handleClose }: Props) => {
  const createSpace = useCreateSpace();

  // const updateBase = useUpdateBase();

  const permissions = {
    // canCreateWordkitoBase: checkPermission(PermissionName.BaseCreate),
    // canUpdateLanguage: !!base && checkPermission(PermissionName.BaseEdit, { base }),
  };
  const defaultValues = {
    name: space?.name || '',
  };

  const methods = useForm<FormSpaceDefaultValues>({ defaultValues });

  const onSubmit = async (data: FormSpaceDefaultValues) => {
    if (!space) {
      await createSpace.mutateAsync(data);
    } else {
    }

    await handleClose();
  };

  return (
    <StyledWrapper onSubmit={methods.handleSubmit(onSubmit)}>
      <ModalFormHeader
        textHeader={space ? 'Zaktualizuj przestrzeń' : 'Utwórz przestrzeń'}
        textSubHeader={space?.name}
      />
      <ModalMain>
        <Main methods={methods} />
      </ModalMain>

      <ModalBottomPanel>
        <ButtonsAction isEditMode={!!space} isCreating={createSpace.isLoading} isUpdating={false} />
      </ModalBottomPanel>
    </StyledWrapper>
  );
};
