import { useParams, useSearchParams } from 'react-router-dom';

export const useAppParams = () => {
  const { deckUniqueUrl, teamUniqueUrl, spaceUniqueUrl, gameId, storyId, mapId, userId, pathId } = useParams();

  const [searchParams] = useSearchParams();

  return {
    deckUniqueUrl,
    teamUniqueUrl,
    spaceUniqueUrl,
    gameId,
    storyId,
    mapId,
    userId,
    pathId,
    searchParams: {
      storyId: searchParams.get('storyId'),
    },
  };
};
