import styled from 'styled-components';

export const StyledWrapper = styled('div')<{ isWarning?: boolean; isError?: boolean }>(
  ({ isWarning, isError }) => ({
    display: 'flex',
    alignItems: 'center',

    ...(isWarning && {
      color: 'yellow',
    }),
    ...(isError && {
      color: 'red',
    }),
  }),
);
