import { AutoLayout } from 'components/atoms/AutoLayout';
import { ContentTypeIcon } from 'components/atoms/icons/ContentTypeIcon';
import { ForkIcon } from 'components/atoms/icons/ForkIcon';
import { QuizTypeIcon } from 'components/atoms/icons/QuizTypeIcon';
import { DeckInfoElement } from './DeckInfoElement';

interface Props {
  deckQuizCardsCount: number;
  deckContentCardsCount: number;
  deckDecisionsCardsCount: number;
}

export const DeckInfoElements = ({
  deckContentCardsCount,
  deckQuizCardsCount,
  deckDecisionsCardsCount,
}: Props) => {
  return (
    <AutoLayout gap={6}>
      <DeckInfoElement
        tooltipText={'Ilość treści'}
        icon={<ContentTypeIcon size="18" />}
        number={deckContentCardsCount}
      />

      <DeckInfoElement
        tooltipText={'Ilość quizów'}
        icon={<QuizTypeIcon size="18" />}
        number={deckQuizCardsCount}
      />

      <DeckInfoElement
        tooltipText={'Ilość rozgałęzień'}
        icon={<ForkIcon size="18" />}
        number={deckDecisionsCardsCount}
      />
    </AutoLayout>
  );
};
