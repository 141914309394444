import { Button } from 'components/atoms/buttons/Button';
import React from 'react';
import { StyledWrapper } from './Styles';

export interface Props {
  isCreating: boolean;
  isUpdating: boolean;
  isEditMode: boolean;
}

export const ButtonsAction: React.FC<Props> = ({ isCreating, isUpdating, isEditMode }) => {
  const updatingButtonText = isUpdating ? 'Aktualizowanie' : 'Zaktualizuj';
  const creatingButtonText = isCreating ? 'Tworzenie...' : 'Stwórz';

  return (
    <StyledWrapper>
      <Button
        disabled={isCreating || isUpdating}
        type="submit"
        text={isEditMode ? updatingButtonText : creatingButtonText}
      />
    </StyledWrapper>
  );
};
