import { useAccessToken } from 'modules/authentication/interactions/hooks';
import { useAuth } from 'modules/authentication/states/useAuth';
import { useCallback, useEffect } from 'react';
import { CurrentUserSocketEventTypes } from 'shared-types-wordkito';
import { useSocket } from 'socket.io-react-hook';

interface Props {
  nameSpace: string;
  userId?: string;
  query?: {
    [key: string]: any;
  };
}

export const useCurrentUserSocket = ({ nameSpace, query }: Props) => {
  const accessToken = useAccessToken();
  const { updateBalance, updatePermissions } = useAuth();
  const { socket } = useSocket(process.env.REACT_APP_SOCKET_API_URL + nameSpace || '', {
    transports: ['websocket'],
    enabled: !!accessToken,
    auth: {
      token: `Barear ${accessToken}`,
    },
    path: process.env.NODE_ENV === 'development' ? '' : '/api/socket.io',
    query: {
      ...query,
    },
  });

  const sendSocket = useCallback(
    (eventType: string, message: {}) => {
      if (socket) {
        socket.emit(eventType, message);
      }
    },
    [socket],
  );

  const onSocket = useCallback(
    (eventType: string, callback: (...args: any[]) => void) => {
      if (!!socket) {
        socket.on(eventType, callback);
      }
    },
    [socket],
  );

  useEffect(() => {
    onSocket(
      CurrentUserSocketEventTypes.UPDATE_BALANCE,
      (message: {
        balance: {
          up: {
            ki: number;
            memoryPoints: number;
          };
          down: {
            ki: number;
            memoryPoints: number;
          };
        };
      }) => {
        updateBalance(message.balance);
      },
    );
    onSocket(CurrentUserSocketEventTypes.UPDATE_PERMISSIONS, (message) => {
      updatePermissions(message.permissions);
    });
  }, [onSocket]);

  return { socket, sendSocket, onSocket };
};
