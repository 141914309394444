import { AutoLayout } from 'components/atoms/AutoLayout';
import { Button } from 'components/atoms/buttons/Button';
import { ButtonIcon } from 'components/atoms/buttons/ButtonIcon';
import { Icon } from 'components/atoms/icons';
import { AddCardOptionIcon } from 'components/atoms/icons/AddCardOptionIcon';
import { ImageAddIcon } from 'components/atoms/icons/ImageAddIcon';
import { SwitchToggle } from 'components/atoms/inputs/SwitchToggle';
import { falseIcon, trueIcon } from 'components/atoms/inputs/SwitchToggle/switchIcons';
import { Select } from 'components/atoms/inputs/selects/Select';
import { FormDeckCardDefaultValues } from 'modules/card/models/formCard';
import { useCreateDeckCard, useDeleteDeckCard } from 'modules/deck/interactions/queries/deckCard';
import { useGenerateQuizOption } from 'modules/deck/interactions/queries/deckCardGenerate';
import { useInterface } from 'modules/global/state/useInterface';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { DeckCardTypes, FieldTextTypes, Language, ProgrammingLanguage } from 'shared-types-wordkito';
import { Field } from './Option';
import { OptionLabel } from './OptionLabel';
import {
  StyledQuestionActions,
  StyledQuestionLabel,
  StyledWrapper,
  StyledWrapperFields,
  StyledWrapperOption,
  StyledWrapperQuestion,
  StyledWrapperQuestionActions,
  StyledWrapperTextAndImage,
} from './Styled';

interface Props {
  form: UseFormReturn<FormDeckCardDefaultValues>;
  fieldArray: UseFieldArrayReturn<FormDeckCardDefaultValues, 'options', 'id'>;
  deckId: string;
}

export const Quiz = ({ form, fieldArray, deckId }: Props) => {
  const { updateModalImage } = useInterface();
  const deleteDeckCardMutation = useDeleteDeckCard(deckId);
  const createDeckCardMutation = useCreateDeckCard(deckId);
  const generateQuizOptionMutation = useGenerateQuizOption(deckId);

  const question = form.watch('mainContent');
  const handleCreateQuizOption = () => {
    createDeckCardMutation.mutate(
      {
        type: DeckCardTypes.CONTENT,
        deckId,
        isQuizOption: true,
        targetDeckCardId: question?.id,
        isCorrect: false,
        character: undefined,
        mainContent: {
          language: Language.pl,
          text: '',
          textType: FieldTextTypes.TEXT,
          programmingLanguageType: ProgrammingLanguage.js,
        },
      },
      {
        onSuccess: (res) => {
          const createdDeckCard = res.createdDeckCards[0];
          if (!createdDeckCard.mainContent) return;
          fieldArray.append({
            id: createdDeckCard.id,
            field: {
              language: createdDeckCard.mainContent.language,
              text: createdDeckCard.mainContent.text || '',
              textType: createdDeckCard.mainContent.textType,
              id: createdDeckCard.mainContent.id,
              programmingLanguageType: createdDeckCard.mainContent.programmingLanguage,
            },
            imageUrl: createdDeckCard.mainContent?.image?.url,
            isCorrect: createdDeckCard.isCorrect,
          });
        },
      },
    );
  };

  const handleGenerateQuizOption = () => {
    if (!question?.id) return;

    const correctOptions = fieldArray.fields.filter((field) => field.isCorrect).map((field) => field.field.text);
    const incorrectOptions = fieldArray.fields
      .filter((field) => !field.isCorrect)
      .map((field) => field.field.text);

    generateQuizOptionMutation.mutate(
      {
        correctOptions: correctOptions,
        incorrectOptions: incorrectOptions,
        question: question?.field.text,
        deckCardId: question?.id,
      },
      {
        onSuccess: (res) => {
          const createdDeckCard = res.createdDeckCards[0];
          if (!createdDeckCard.mainContent) return;
          fieldArray.append({
            id: createdDeckCard.id,
            field: {
              language: createdDeckCard.mainContent.language,
              text: createdDeckCard.mainContent.text || '',
              textType: createdDeckCard.mainContent.textType,
              id: createdDeckCard.mainContent.id,
              programmingLanguageType: createdDeckCard.mainContent.programmingLanguage,
            },
            imageUrl: createdDeckCard.mainContent?.image?.url,
            isCorrect: createdDeckCard.isCorrect,
          });
        },
      },
    );
  };

  return (
    <StyledWrapper>
      <StyledWrapperQuestion>
        <Field
          textName={'mainContent.field.text'}
          control={form.control}
          textType={question?.field?.textType || FieldTextTypes.TEXT}
          programmingLanugageType={question?.field?.programmingLanguageType || ProgrammingLanguage.js}
          programmingLanguageName="question.field.programmingLanguageType"
          image={{
            id: question?.field.imageId || undefined,
            url: question?.imageUrl || undefined,
          }}
          onDeleteImage={() => {
            form.setValue('mainContent.imageUrl', undefined);
            form.setValue('mainContent.field.imageId', undefined);
          }}
          label={
            <StyledQuestionLabel>
              <div>Pytanie</div>
              <StyledQuestionActions>
                <Select
                  name="mainContent.field.textType"
                  defaultValue={question?.field?.textType || FieldTextTypes.TEXT}
                  control={form.control}
                  variant="ghost"
                  options={[
                    { text: 'Zwykły tekst', value: FieldTextTypes.TEXT },
                    {
                      text: 'Kod ',
                      value: FieldTextTypes.CODE,
                    },
                  ]}
                />
                <ButtonIcon
                  Icon={<ImageAddIcon size="32" />}
                  tooltipProps={{
                    content: 'Dodaj obrazek do pytania',
                  }}
                  onClick={() =>
                    updateModalImage({
                      isOpen: true,
                      image: '',
                      setImage: (image) => {
                        form.setValue(`mainContent.imageUrl`, image?.url);
                        form.setValue(`mainContent.field.imageId`, image?.id);
                      },
                    })
                  }
                />
              </StyledQuestionActions>
            </StyledQuestionLabel>
          }
        />
        <StyledWrapperQuestionActions>
          {fieldArray.fields.length > 0 ? (
            <div></div>
          ) : (
            <SwitchToggle
              checkColor="#31B24E"
              uncheckColor="#F05E3D"
              checkIcon={trueIcon}
              unCheckIcon={falseIcon}
              name={`mainContent.isCorrect`}
              control={form.control}
              label={question?.isCorrect ? 'Prawda' : 'Fałsz'}
            />
          )}
          <AutoLayout gap={10}>
            <Button
              variant="transparent"
              text={'Dodaj odpowiedź'}
              disabled={createDeckCardMutation.isLoading || generateQuizOptionMutation.isLoading}
              isLoading={createDeckCardMutation.isLoading || generateQuizOptionMutation.isLoading}
              leftIcon={<AddCardOptionIcon />}
              onClick={() => {
                handleCreateQuizOption();
              }}
            />
            <Button
              variant="transparent"
              text={'generuj'}
              tooltipProps={{ content: 'Generuj fałszywą odpowiedź' }}
              disabled={createDeckCardMutation.isLoading || generateQuizOptionMutation.isLoading}
              isLoading={createDeckCardMutation.isLoading || generateQuizOptionMutation.isLoading}
              leftIcon={<Icon name="generate" />}
              onClick={() => {
                handleGenerateQuizOption();
              }}
            />
          </AutoLayout>
        </StyledWrapperQuestionActions>
      </StyledWrapperQuestion>
      <StyledWrapperFields>
        {fieldArray.fields.map((field, index) => {
          const option = form.watch(`options.${index}`);
          return (
            <StyledWrapperOption key={field.id}>
              <StyledWrapperTextAndImage>
                <Field
                  control={form.control}
                  textName={`options.${index}.field.text`}
                  textType={option.field.textType}
                  programmingLanguageName={`options.${index}.field.programmingLanguageType`}
                  programmingLanugageType={option.field.programmingLanguageType || ProgrammingLanguage.js}
                  image={{
                    id: option.field.imageId || '',
                    url: option.imageUrl || '',
                  }}
                  label={
                    <OptionLabel
                      optionKey={index}
                      currentOptionNumber={index}
                      textType={option.field.textType}
                      form={form}
                      optionTextTypeName={`options.${index}.field.textType`}
                      handleRemoveOption={() => {
                        // TUTAJ ZROBIĆ USUWANIE KARTY
                        if (option.id) {
                          deleteDeckCardMutation.mutate({
                            deckCardId: option.id,
                          });
                        }
                        fieldArray.remove(index);
                      }}
                      handleOpenImageForm={() =>
                        updateModalImage({
                          isOpen: true,
                          image: '',
                          setImage: (image) => {
                            form.setValue(`options.${index}.imageUrl`, image?.url);
                            form.setValue(`options.${index}.field.imageId`, image?.id);
                          },
                        })
                      }
                    />
                  }
                />
              </StyledWrapperTextAndImage>
              <SwitchToggle
                checkColor="#31B24E"
                uncheckColor="#F05E3D"
                checkIcon={trueIcon}
                unCheckIcon={falseIcon}
                name={`options.${index}.isCorrect`}
                control={form.control}
                label={option.isCorrect ? 'Prawda' : 'Fałsz'}
              />
            </StyledWrapperOption>
          );
        })}
      </StyledWrapperFields>
    </StyledWrapper>
  );
};
