import { MainBar } from '../MainBar';
import { StyledWrapper } from './Styles';

export const Navigation = () => {
  return (
    <StyledWrapper>
      <MainBar />
    </StyledWrapper>
  );
};

export default Navigation;
