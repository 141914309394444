import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DeckQueryKeys } from 'modules/deck/constants/queryKeys';
import { MenuPagesTypes } from 'modules/deck/interactions/methods/deck';
import { IDeck } from 'shared-types-wordkito';
import { createTransaction, deleteTransaction, exposeOnMarket } from './methods';

export const useCreateProduct = () => {
  const queryClient = useQueryClient();

  const handler = (prev: IDeck[], res: IDeck) => {
    return prev?.map((deck) => {
      if (deck.id !== res.id) return deck;

      const newItem: IDeck = {
        ...deck,
        transactionsCount: deck.transactionsCount || 0,
      };

      return newItem;
    });
  };

  const result = useMutation(exposeOnMarket, {
    onSuccess: (res) => {
      queryClient.setQueriesData<IDeck[]>(DeckQueryKeys.currentUserDecks(MenuPagesTypes.MY_DECKS), (prev) =>
        handler(prev || [], res),
      );
      queryClient.setQueriesData<IDeck[]>(DeckQueryKeys.currentUserDecks(MenuPagesTypes.MARKET_DECKS), (prev) =>
        handler(prev || [], res),
      );
      queryClient.setQueriesData<IDeck[]>(DeckQueryKeys.currentUserDecks(MenuPagesTypes.SHARED_DECKS), (prev) =>
        handler(prev || [], res),
      );
    },
  });

  return result;
};

export const useCreateTransaction = () => {
  const queryClient = useQueryClient();

  const result = useMutation(createTransaction, {
    onSuccess: (res) => {
      queryClient.setQueriesData<IDeck[]>(DeckQueryKeys.currentUserDecks(), (prev) => {
        return prev?.map((item) => {
          if (res.id !== item.id) return item;
          const newItem: IDeck = {
            ...item,
            transactionsCount: item.transactionsCount || 0 + 1,
            isBoughtByCurrentUser: true,
          };

          return newItem;
        });
      });
    },
  });

  return result;
};

export const useDeleteTransaction = () => {
  const queryClient = useQueryClient();
  const result = useMutation(deleteTransaction, {
    onSuccess: (res) => {
      queryClient.setQueriesData<IDeck[]>(DeckQueryKeys.currentUserDecks(), (prev) => {
        return prev?.map((item) => {
          const newItem: IDeck = {
            ...item,
          };

          return newItem;
        });
      });
    },
  });

  return result;
};
