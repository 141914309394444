import { IDeck } from 'shared-types-wordkito';

export const checkDeckNodeStatus = (deck: IDeck, parentDeck?: IDeck) => {
  const isParentDeckFinished = parentDeck?.maxQuizPoints === parentDeck?.currentUserGainedPoints;

  const currentDeckFinished = deck.maxQuizPoints === deck.currentUserGainedPoints;

  if (deck.maxQuizPoints === 0) return 'NONE';

  if (isParentDeckFinished && currentDeckFinished) {
    return 'FINISHED';
  }

  if (isParentDeckFinished) {
    return 'ACTIVE';
  }

  return 'DISABLED';
};
