import ELK, { ElkExtendedEdge } from 'elkjs/lib/elk.bundled.js';
import { PathPermissions } from 'modules/deck/models/permissions';
import { Position } from 'reactflow';
import { IDeck, IPath } from 'shared-types-wordkito';
import { EdgeDeck, ElkExtendedNode, NodeDeck } from '../types';
import { checkDeckNodeStatus } from './checkNodeStatus';
import { backgroundColorByStatus } from './colorsByStatus';
const elk = new ELK();

// Elk has a *huge* amount of options to configure. To see everything you can
// tweak check out:
//
// - https://www.eclipse.org/elk/reference/algorithms.html
// - https://www.eclipse.org/elk/reference/options.html

const calculateDeckNodeStatus = () => {};

const elkOptions = {
  'elk.algorithm': 'layered',
  'elk.direction': 'RIGHT',
  'elk.layered.spacing.nodeNodeBetweenLayers': '100',
  'elk.spacing.nodeNode': '80',
};
export const getLayoutedElements = async (
  initialDecks: IDeck[],
  initialPath: IPath,
  permissions: PathPermissions,
) => {
  const decksMap = new Map<string, IDeck>(
    initialDecks.filter((deck) => deck.sourceDeckId).map((deck) => [deck.id, deck]),
  );

  const extractedInitalNodes =
    initialDecks.map<NodeDeck>((initialDeck) => {
      const parentDeck = initialDeck.sourceDeckId ? decksMap.get(initialDeck.sourceDeckId as string) : undefined;
      return {
        id: initialDeck.id,
        isConnectable: false,
        type: 'deckNode',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        position: { x: 0, y: 0 },
        data: {
          parentDeck,
          deck: initialDeck,
          path: initialPath,
          permissions,
          status: checkDeckNodeStatus(initialDeck, parentDeck),
        },
      };
    }) || [];

  const extractedInitialEdges = initialDecks
    .filter((deck) => deck.sourceDeckId)
    .map<EdgeDeck>((deck) => {
      // znaleść source
      const parentDeck = decksMap.get(deck.sourceDeckId as string);
      const status = checkDeckNodeStatus(deck, parentDeck);
      const isAnimated = status === 'ACTIVE';

      return {
        id: `prev-${deck.id}-next-${deck.sourceDeckId}`,
        target: deck.id || '',
        source: deck.sourceDeckId || '',
        type: 'smoothstep',
        animated: isAnimated,
      };
    });

  const elkNodes = extractedInitalNodes.map<ElkExtendedNode>((node) => ({
    id: node.id,
    width: 200,
    height: 100,
    data: node.data,
    x: node.position.x,
    y: node.position.y,
  }));
  const elkEdges = extractedInitialEdges.map<ElkExtendedEdge>((edge) => ({
    id: edge.id,
    sources: [edge.source],
    targets: [edge.target],
  }));

  const graph: ElkExtendedNode = {
    id: 'root',
    layoutOptions: elkOptions,
    children: elkNodes,
    edges: elkEdges,
  };

  const layout = await elk.layout(graph).then((layoutedGraph: ElkExtendedNode) => {
    return {
      ...layoutedGraph,
      nodes: layoutedGraph.children?.map<ElkExtendedNode>((calculatedNode: ElkExtendedNode) => {
        return {
          ...calculatedNode,
          id: calculatedNode.id,
          data: calculatedNode.data,
          position: { x: calculatedNode.x, y: calculatedNode.y },
        };
      }),

      edges: layoutedGraph.edges,
    };
  });

  const reactFlowEdges = layout.edges?.map<EdgeDeck>((edge) => {
    const parentDeck = decksMap.get(edge.sources[0]);
    const childrenDeck = decksMap.get(edge.targets[0]);
    const status = checkDeckNodeStatus(childrenDeck as IDeck, parentDeck as IDeck);
    const isAnimated = status === 'ACTIVE';

    const color = backgroundColorByStatus(status);

    return {
      id: edge.id,
      source: edge.sources[0],
      target: edge.targets[0],
      type: 'smoothstep',
      style: {
        stroke: color,
      },
      animated: isAnimated,
    };
  });

  const reactFlowNodes = layout.nodes?.map<NodeDeck>((node) => {
    return {
      id: node.id,
      type: 'deckNode',

      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      isConnectable: false,
      draggable: false,
      data: { ...node.data } as NodeDeck['data'],
      position: { x: node.x || 0, y: node.y || 0 },
    };
  });

  return {
    nodes: reactFlowNodes,
    edges: reactFlowEdges,
  };
};
