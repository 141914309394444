import { theme } from 'modules/global/theme/theme';
import styled from 'styled-components';
import { Typography } from '../Typography';

export const StyledLogo = styled.div(() => ({
  a: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: theme.colors.fontColors.second,
  },
  ['svg']: {
    marginTop: '2px',
  },
}));

export const StyledTypography = styled(Typography)`
  font-family: ${theme.fonts.names.second};
  font-size: 20px;
`;

// a {
//   display: flex;
//   align-items: center;
//   text-decoration: none;
//   color: ${theme.colors.fontColors.second};
//   > :first-child {
//     margin-right: 5px;
//     margin-top: 5.5px;
//   }
// }
