import { Tooltip } from '@mui/material';
import { AcceptIcon } from 'components/atoms/icons/AcceptIcon';
import { theme } from 'modules/global/theme/theme';
import { backgroundColorByStatus } from '../helpers/colorsByStatus';
import { NodeDeckStatus } from '../types';

export type Props = {
  currentPoints: number;
  maxPoints: number;
  status: NodeDeckStatus;
};

export const PointsCounter = ({ currentPoints, maxPoints, status }: Props) => {
  const containerStyle = {
    display: 'flex',
    alignitems: 'center',
    width: '100%',
    height: '20px',
    fontSize: '12px',
    border: `1px solid ${backgroundColorByStatus(status)}`,
    borderRadius: theme.borders.small,
    color: backgroundColorByStatus(status),
    justifyContent: 'space-between',
  };

  const diffrence = maxPoints - currentPoints;

  const percantage = () => {
    if (maxPoints === 0) return 100;
    return (currentPoints / maxPoints) * 100;
  };
  if (maxPoints === 0) return null;

  return (
    <Tooltip
      title={`Zdobyto ${currentPoints} z ${maxPoints} punktów. Pozostało do zdybycia ${diffrence}`}
      placement="bottom"
    >
      <div style={containerStyle}>
        <div
          style={{
            backgroundColor: backgroundColorByStatus(status),
            height: '100%',
            width: `${percantage()}%`,
            transition: 'width 0.5s',
          }}
        />
        <div
          style={{
            borderLeft: `1px solid ${backgroundColorByStatus(status)}`,
            padding: '0 5px',
            height: '100%',
            backgroundColor: diffrence === 0 ? backgroundColorByStatus(status) : 'transparent',
          }}
        >
          {diffrence === 0 ? <AcceptIcon size="18" isCorrect showAnswer color={'white'} /> : diffrence.toFixed(0)}
        </div>
      </div>
    </Tooltip>
  );
};
