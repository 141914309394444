import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from 'modules/authentication/states/useAuth';
import { DeckQueryKeys } from 'modules/deck/constants/queryKeys';
import { axiosPatch } from 'modules/global/helpers/fetchApiMethods';
import { API_URLS } from 'modules/global/urls';
import { IDeck, IPath } from 'shared-types-wordkito';
import { createPath, deletePath, updatePath } from '../methods/path';

export const useDeletePath = () => {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const result = useMutation(deletePath, {
    onSuccess: (res, variables) => {
      queryClient.setQueryData<IPath[]>(DeckQueryKeys.paths(user?.id), (prev) => {
        return prev?.filter((item) => item.id !== variables);
      });
    },
  });

  return result;
};

export const useUpdatePath = () => {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const result = useMutation(updatePath, {
    onSuccess: (res) => {
      queryClient.setQueryData<IPath[]>(DeckQueryKeys.paths(user?.id), (prev) => {
        return prev?.map((item) => (item.id === res.id ? { ...item, ...res } : item));
      });
    },
  });

  return result;
};

export const useCreatePath = () => {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const result = useMutation(createPath, {
    onSuccess: (res) => {
      queryClient.setQueryData<IPath[]>(DeckQueryKeys.paths(user?.id), (prev) => {
        return [res, ...(prev || [])];
      });
    },
  });

  return result;
};

export const useAssignDeckToStory = () => {
  const queryClient = useQueryClient();
  const result = useMutation(createPath, {
    onSuccess: (res) => {
      queryClient.setQueryData<IPath[]>(['stories'], (prev) => {
        return [...(prev || []), res];
      });
    },
  });

  return result;
};

export const useConnectDeckToDeckMutation = (pathId: string) => {
  const queryClient = useQueryClient();

  const fetcher = async (body: { prevDeckId: string; nextDeckId: string }) => {
    const response = await axiosPatch<{ prevDeckId: string; nextDeckId: string }, IDeck>(
      API_URLS.PATH_DETAILS_DECKS(pathId),
      body,
    );

    return response.data;
  };

  const result = useMutation(fetcher, {
    onSuccess: (res, variables) => {
      queryClient.setQueryData<IDeck[]>(DeckQueryKeys.pathDecks(pathId), (prev) => {
        return (
          prev?.map((item) => (item.id === res.id ? { ...item, sourceDeckId: res.sourceDeckId } : item)) || []
        );
      });
    },
  });

  return result;
};
