import { AutoLayout } from 'components/atoms/AutoLayout';
import { Tooltip } from 'components/atoms/Tooltip';
import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { ReactNode } from 'react';

export const DeckInfoElement = ({
  icon,
  number,
  tooltipText,
}: {
  icon: ReactNode;
  number: number;
  tooltipText: string;
}) => {
  return (
    <Tooltip title={tooltipText}>
      <div>
        <AutoLayout gap={2} alignVerticalInside="center">
          {icon} <Typography variant={TypographyTypes.bodySmallRegular}>{number}</Typography>
        </AutoLayout>
      </div>
    </Tooltip>
  );
};
