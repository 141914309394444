import { AutoLayout } from 'components/atoms/AutoLayout';
import { Button } from 'components/atoms/buttons/Button';
import { Select } from 'components/atoms/inputs/selects/Select';
import { InputText } from 'components/atoms/inputs/texts/InputText';
import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { MemberList } from 'components/organisms/MemberList';
import { useAuth } from 'modules/authentication/states/useAuth';
import { useCreateMember } from 'modules/authorization/interactions/mutations/member.mutations';
import { useGetMembers } from 'modules/authorization/interactions/queries/members.query';
import { useForm } from 'react-hook-form';
import { PermissionTemplate } from 'shared-types-wordkito';

const MembersView = () => {
  const getMembers = useGetMembers();
  const createMember = useCreateMember();
  const { user } = useAuth();
  const form = useForm<{
    permissionTemplate: PermissionTemplate | undefined;
    email: string;
  }>({
    defaultValues: { permissionTemplate: PermissionTemplate.MEMBER, email: '' },
  });

  const members = getMembers.data;

  const permissionTemplates = [
    { text: 'Podróżnik', value: PermissionTemplate.MEMBER },
    { text: 'Twórca', value: PermissionTemplate.EDITOR },
    { text: 'Dowódca', value: PermissionTemplate.ADMIN },
  ];
  // pobrać ścieżki dla danego uzytkownika lub przestrzeni

  const handleInvite = () => {
    createMember.mutate(
      {
        email: form.getValues('email'),
        permissionTemplate: form.getValues('permissionTemplate'),
      },
      {
        onError: (error) => {
          form.setError('email', {
            message: error?.response?.data?.message || '',
            type: 'validate',
          });
        },
      },
    );
  };

  const currentUserMembers = members?.filter((member) => member.userOwnerId === user?.id);
  const membersWhereCurrentUserIsAssigned = members?.filter(
    (member) => member.userOwnerId !== user?.id && member.assignedUserId === user?.id,
  );
  return (
    <AutoLayout column gap={20}>
      <AutoLayout column gap={20}>
        <Typography variant={TypographyTypes.titleLargeSemiBold}>Podróżnicy których zaprosiłeś</Typography>
        <AutoLayout justifyHorizontal="space-between" gap={10}>
          <InputText
            type={'email'}
            placeholder="Wpisz email ..."
            name="email"
            control={form.control}
            helperText={form.formState.errors['email']?.message}
            error={!!form.formState.errors['email']?.message}
            fullWidth
            isEdited={true}
            endAdornment={
              <Select
                defaultValue={PermissionTemplate.MEMBER}
                options={permissionTemplates}
                control={form.control}
                name="permissionTemplate"
                variant="ghost"
              />
            }
          />

          <Button
            text="Zaproś"
            onClick={() => {
              handleInvite();
            }}
          />
        </AutoLayout>

        {/* Lista użytkowników powinna się wyświetlać w lewym panelu
        - powinni sie pobierać użytkownicy z danej przestrzeni 
        - powinna być możliwość usunięcia użytkownika z przestrzeni
        - powinna być możliwość zmiany uprawnień użytkownika w przestrzeni
        - powinna być możliwość dodania użytkownika do przestrzeni
        
        // Dla użytkownika powinny się pobierać 
      */}
        {/* Input z wpisywaniem maila  */}
        <MemberList currentUserId={user?.id || ''} members={currentUserMembers || []} />
      </AutoLayout>
      <AutoLayout column gap={10}>
        <Typography variant={TypographyTypes.titleLargeSemiBold}>Podróżnicy którzy cię zaprosili</Typography>

        {/* 
        Members assigned to 
      - pokazać kto cię zaprosił 
      - pokazać jakie masz uprawnienia
      - pokazać czy zaakceptowałeś zaproszenie
    */}
        <MemberList currentUserId={user?.id || ''} members={membersWhereCurrentUserIsAssigned || []} />
      </AutoLayout>
    </AutoLayout>
  );
};

export default MembersView;
