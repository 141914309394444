import { AutoLayout } from 'components/atoms/AutoLayout';
import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { ButtonIcon } from 'components/atoms/buttons/ButtonIcon';
import { EditIcon } from 'components/atoms/icons/EditIcon';
import PlusIcon from 'components/atoms/icons/PlusIcon';
import { UserLink } from 'components/molecules/UserLink';
import { useCheckPermission } from 'modules/authorization/hooks/useCheckPermission';
import { useInterface } from 'modules/global/state/useInterface';
import { ResourceTypeForm } from 'modules/global/state/useInterface/types/actions';
import { theme } from 'modules/global/theme/theme';
import { PointsCounter } from 'modules/path/components/PointsCounter';
import { useMemo } from 'react';
import { IPath, PermissionName } from 'shared-types-wordkito';

interface Props {
  path: IPath;
  paths: IPath[];
  isOpen: boolean;
  onOpen: (path: IPath) => void;
}

export const Path = ({ path, isOpen, onOpen, paths }: Props) => {
  const { updateResourceModal } = useInterface();
  const { checkPermission } = useCheckPermission({ path });
  const pathPermissions = {
    canCreateDeck: checkPermission(PermissionName.DeckCreate),
    canEditPath: checkPermission(PermissionName.PathEdit),
  };

  const points = useMemo(() => {
    let gainedPoints = 0;
    let maxPoints = 0;
    path.decks?.forEach((deck) => {
      if (deck.maxQuizPoints === 0) return;
      maxPoints += 1;
      if (gainedPoints === deck.currentUserGainedPoints) {
        gainedPoints += 1;
      }
    });

    return { gainedPoints, maxPoints };
  }, [path.decks]);

  return (
    <AutoLayout
      column
      position="relative"
      maxWidth="200px"
      fullWidth
      borderRadius={'4px'}
      boxShadow={['0px -1px 2px 0px rgba(0, 0, 0, 0.25)', '0px 1px 2px 0px rgba(0, 0, 0, 0.25)']}
    >
      <AutoLayout
        maxWidth="200px"
        style={{
          height: '100px',
          borderBottom: '1px solid #E0E0E0',
        }}
        fullWidth
      ></AutoLayout>
      <AutoLayout
        justifyHorizontal="space-between"
        fullWidth
        gap={32}
        padding={'10px'}
        background={isOpen ? theme.colors.accentColor.background : undefined}
      >
        <AutoLayout
          column
          gap={4}
          fullWidth
          css={{
            cursor: 'pointer',
          }}
        >
          {path.user && <UserLink id={path.userId} avatar="" displayName={path.user.displayName || ''} />}
          <Typography
            style={{
              wordWrap: 'break-word',
            }}
            hover={{
              color: theme.colors.accentColor.default,
            }}
            variant={TypographyTypes.titleSmallSemiBold}
            onClick={() => onOpen(path)}
          >
            {path.name}
          </Typography>

          <PointsCounter currentPoints={points.gainedPoints} maxPoints={points.maxPoints} status="ACTIVE" />
        </AutoLayout>

        <AutoLayout
          position="absolute"
          style={{
            top: '0px',
            right: '0px',
          }}
          gap={4}
        >
          {pathPermissions.canCreateDeck && (
            <ButtonIcon
              Icon={<PlusIcon />}
              onClick={() =>
                updateResourceModal({ addToPath: path.id, slajdType: ResourceTypeForm.DECK, isOpen: true })
              }
            />
          )}
          {pathPermissions.canEditPath && (
            <ButtonIcon
              Icon={<EditIcon />}
              onClick={() =>
                updateResourceModal({
                  addToPath: path.id,
                  path: path,
                  slajdType: ResourceTypeForm.PATH,
                  isOpen: true,
                })
              }
            />
          )}
        </AutoLayout>
      </AutoLayout>
    </AutoLayout>
  );
};
