import { useInfiniteQuery } from '@tanstack/react-query';
import { AutoLayout } from 'components/atoms/AutoLayout';
import { Typography, TypographyTypes } from 'components/atoms/Typography';
import { Button } from 'components/atoms/buttons/Button';
import { Icon } from 'components/atoms/icons';
import { Select } from 'components/atoms/inputs/selects/Select';
import { InputText } from 'components/atoms/inputs/texts/InputText';
import { MenuPage, MenuPages, MenuTab, MenuTabItem } from 'components/molecules/TabList';
import { DeckList } from 'components/organisms/DeckList';
import { PathList } from 'components/organisms/PathList';
import { useAuth } from 'modules/authentication/states/useAuth';
import { useCheckPermission } from 'modules/authorization/hooks/useCheckPermission';
import { searchDecks, searchPaths } from 'modules/deck/interactions/methods/deck';
import { useInterface } from 'modules/global/state/useInterface';
import { ResourceTypeForm } from 'modules/global/state/useInterface/types/actions';
import { MarketQueryKeys } from 'modules/market/constants/queryKeys';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DiffcultyLevelType, PermissionName } from 'shared-types-wordkito';
import { useDebounce } from 'use-debounce';

const MarketPlaceView = () => {
  const defaultValues = { search: '', sort: '-', difficultyLevel: 'begginer' as DiffcultyLevelType };
  const form = useForm({ defaultValues });
  const { updateResourceModal } = useInterface();
  const { user } = useAuth();
  const { checkPermission } = useCheckPermission({});
  // Pobrać nie przypisane do żadnej ścieżki decki dla danego uzytkownika lub przestrzeni

  const canCreatePath = checkPermission(PermissionName.PathCreate);
  const [menuTab, setMenuTab] = useState<'PATHS' | 'STORIES'>('PATHS');

  const [value] = useDebounce(form.watch('search'), 600);

  const pathsInifiniteQuery = useInfiniteQuery({
    queryKey: [MarketQueryKeys.marketPaths, { text: value }],
    queryFn: async ({ pageParam = 1 }) => searchPaths(pageParam, value),
    enabled: menuTab === 'PATHS',
  });
  const handleSubmit = (data: typeof defaultValues) => {
    updateResourceModal({
      isOpen: true,
      slajdType: ResourceTypeForm.PATH,
      initialData: {
        name: data.search,
        difficultyLevel: data.difficultyLevel,
      },
    });
  };

  const storiesInfiniteQuery = useInfiniteQuery({
    queryKey: [MarketQueryKeys.marketStories, { text: value }],
    queryFn: async ({ pageParam = 1 }) => searchDecks(pageParam, value),
    enabled: menuTab === 'STORIES',
  });

  const paths = useMemo(() => pathsInifiniteQuery.data?.pages.flat() || [], [pathsInifiniteQuery.data]);
  const decks = useMemo(() => storiesInfiniteQuery.data?.pages.flat() || [], [storiesInfiniteQuery.data]);

  return (
    <AutoLayout column fullWidth gap={40}>
      <AutoLayout column gap={12} fullWidth>
        <AutoLayout alignVerticalInside="center" gap={8}>
          <Icon size="40" name="marketplace" />
          <Typography variant={TypographyTypes.headerH3}>Marketplace</Typography>
        </AutoLayout>
        <Typography variant={TypographyTypes.titleSmallBold}>Nie wiesz jak się czegoś nauczyć ?</Typography>

        <AutoLayout as={'form'} column fullWidth gap={8} onSubmit={form.handleSubmit(handleSubmit)}>
          <AutoLayout fullWidth gap={8}>
            <InputText
              fullWidth
              label={'Znajdź gotową ścieżkę lub wygeneruj na start '}
              control={form.control}
              name={'search'}
              endAdornment={
                <Button
                  type="submit"
                  variant="transparent"
                  leftIcon={<Icon name="generate" />}
                  disabled={!user?.id || !form.watch('search').length}
                  text="generuj"
                />
              }
            />
            <Select
              variant="compact"
              defaultValue={'begginer'}
              control={form.control}
              name="difficultyLevel"
              label="Poziom trudności"
              options={[
                { text: 'Trudny', Icon: <Icon name="levelAdvanced" />, value: 'advanced' },
                { text: 'Średni', Icon: <Icon name="levelMedium" />, value: 'medium' },
                { text: 'Łatwy', Icon: <Icon name="levelBegginer" />, value: 'begginer' },
              ]}
            />
          </AutoLayout>
        </AutoLayout>
      </AutoLayout>

      <AutoLayout column gap={24}>
        <MenuTab>
          <MenuTabItem
            Icon={<Icon name="path" />}
            isActive={menuTab === 'PATHS'}
            text="Scieżki"
            onClick={() => setMenuTab('PATHS')}
          />
          <MenuTabItem
            isActive={menuTab === 'STORIES'}
            Icon={<Icon name="story" />}
            text="Zagadnienia"
            onClick={() => setMenuTab('STORIES')}
          />
        </MenuTab>

        <MenuPages value={menuTab}>
          <MenuPage value={'PATHS'}>
            <PathList paths={paths} />
          </MenuPage>
          <MenuPage value={'STORIES'}>
            <DeckList decks={decks} paths={[]} />
          </MenuPage>
        </MenuPages>
      </AutoLayout>
    </AutoLayout>
  );
};

export default MarketPlaceView;
