import { Tooltip, tooltipClasses } from '@mui/material';
import styled from 'styled-components';

export const StyledTooltip = styled(Tooltip)(({}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: 'white',
    fontSize: '18px',
    backgroundColor: 'black',

    padding: '20px',
  },
}));
