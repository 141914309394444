import { MenuPagesTypes } from '../interactions/methods/deck';

export const DeckQueryKeys = {
  currentUserDecks: (type?: MenuPagesTypes, userId?: string, spaceId?: string) => [
    'decks',
    type,
    {
      userId,
      spaceId,
    },
  ],
  deckCards: (deckId?: string) => ['deckCards', { deckId }],
  deck: (deckId?: string) => ['deck', { deckId }, undefined],
  deckGameStats: (deckId?: string) => ['decks', { deckId }, 'stats'],
  paths: (userId?: string, spaceId?: string) => ['paths', { userId, spaceId }],
  pathDecks: (pathId: string) => ['paths', { pathId }, 'decks'],
};
