import styled from 'styled-components';

export const StyledWrapper = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const StyledWrapperText = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '20px',
}));
