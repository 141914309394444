import { ReactNode, useContext, useLayoutEffect, useReducer } from 'react';
import PL from '../constants/translates/pl';
import { Context } from './context';
import Reducer from './reducer';

const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [languageState, languageDispatch] = useReducer(Reducer, PL);

  useLayoutEffect(() => {
    import(`modules/language/constants/translates/${'pl'}`).then((language) => {
      languageDispatch({ type: 'INIT', payload: language.default });
    });
  }, []);

  const value = { languageState, languageDispatch };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

function useLanguage() {
  const context = useContext(Context);

  if (!context) {
    throw new Error('Context mus be used within a <AuthProvider/>');
  }

  return context;
}

export { LanguageProvider, useLanguage };
