import { CustomSvgIconProps, Icon } from '.';

export const AddCardOptionIcon = (props: CustomSvgIconProps) => (
  <Icon {...props}>
    <>
      <mask id="mask0_2345_13114" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2345_13114)">
        <path d="M19 15.5H17C16.7167 15.5 16.4793 15.404 16.288 15.212C16.096 15.0207 16 14.7833 16 14.5C16 14.2167 16.096 13.979 16.288 13.787C16.4793 13.5957 16.7167 13.5 17 13.5H19V11.5C19 11.2167 19.096 10.979 19.288 10.787C19.4793 10.5957 19.7167 10.5 20 10.5C20.2833 10.5 20.5207 10.5957 20.712 10.787C20.904 10.979 21 11.2167 21 11.5V13.5H23C23.2833 13.5 23.5207 13.5957 23.712 13.787C23.904 13.979 24 14.2167 24 14.5C24 14.7833 23.904 15.0207 23.712 15.212C23.5207 15.404 23.2833 15.5 23 15.5H21V17.5C21 17.7833 20.904 18.0207 20.712 18.212C20.5207 18.404 20.2833 18.5 20 18.5C19.7167 18.5 19.4793 18.404 19.288 18.212C19.096 18.0207 19 17.7833 19 17.5V15.5ZM14 15.5H4C3.71667 15.5 3.47933 15.404 3.288 15.212C3.096 15.0207 3 14.7833 3 14.5V6.5C3 6.21667 3.096 5.979 3.288 5.787C3.47933 5.59567 3.71667 5.5 4 5.5H20C20.2833 5.5 20.5207 5.59567 20.712 5.787C20.904 5.979 21 6.21667 21 6.5V8.5H19V7.5H5V13.5H14V15.5Z" />
      </g>
    </>
  </Icon>
);
